/* tslint:disable */
/* eslint-disable */
/**
 * Fay API
 * Fay API
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const APIAppointmentStatus = {
    Scheduled: 'Scheduled',
    Occurred: 'Occurred',
    Canceled: 'Canceled',
    NoShow: 'No-show',
    ReScheduled: 'Re-scheduled'
} as const;

export type APIAppointmentStatus = typeof APIAppointmentStatus[keyof typeof APIAppointmentStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const APIAppointmentType = {
    InitialConsultation: 'Initial Consultation',
    FollowUp: 'Follow-up'
} as const;

export type APIAppointmentType = typeof APIAppointmentType[keyof typeof APIAppointmentType];


/**
 * 
 * @export
 * @enum {string}
 */

export const APIRecurrenceType = {
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    BiWeekly: 'Bi-weekly'
} as const;

export type APIRecurrenceType = typeof APIRecurrenceType[keyof typeof APIRecurrenceType];


/**
 * 
 * @export
 * @interface AccountSessionResponse
 */
export interface AccountSessionResponse {
    /**
     * 
     * @type {string}
     * @memberof AccountSessionResponse
     */
    'client_secret': string;
}
/**
 * 
 * @export
 * @interface AddPatientLabFeedbackPayload
 */
export interface AddPatientLabFeedbackPayload {
    /**
     * 
     * @type {boolean}
     * @memberof AddPatientLabFeedbackPayload
     */
    'is_feedback_positive': boolean;
}
/**
 * 
 * @export
 * @interface AddToCartPayload
 */
export interface AddToCartPayload {
    /**
     * 
     * @type {string}
     * @memberof AddToCartPayload
     */
    'product_id': string;
    /**
     * 
     * @type {string}
     * @memberof AddToCartPayload
     */
    'variant_id': string;
    /**
     * 
     * @type {number}
     * @memberof AddToCartPayload
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface AddressDetails
 */
export interface AddressDetails {
    /**
     * 
     * @type {string}
     * @memberof AddressDetails
     */
    'address_line1': string;
    /**
     * 
     * @type {string}
     * @memberof AddressDetails
     */
    'address_line2': string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDetails
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof AddressDetails
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof AddressDetails
     */
    'zip_code': string;
}
/**
 * 
 * @export
 * @interface AdminProviderDetailsResponse
 */
export interface AdminProviderDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof AdminProviderDetailsResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProviderDetailsResponse
     */
    'cognito_id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProviderDetailsResponse
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProviderDetailsResponse
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProviderDetailsResponse
     */
    'preferred_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProviderDetailsResponse
     */
    'full_name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProviderDetailsResponse
     */
    'full_legal_name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProviderDetailsResponse
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProviderDetailsResponse
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProviderDetailsResponse
     */
    'npi': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProviderDetailsResponse
     */
    'timezone': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminProviderDetailsResponse
     */
    'has_set_password': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminProviderDetailsResponse
     */
    'is_admin': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminProviderDetailsResponse
     */
    'is_churned': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminProviderDetailsResponse
     */
    'is_test': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AllowedSortParam = {
    Asc: 'asc',
    Desc: 'desc'
} as const;

export type AllowedSortParam = typeof AllowedSortParam[keyof typeof AllowedSortParam];


/**
 * 
 * @export
 * @interface Amount
 */
export interface Amount {
}
/**
 * 
 * @export
 * @interface AppReviewPayload
 */
export interface AppReviewPayload {
    /**
     * 
     * @type {number}
     * @memberof AppReviewPayload
     */
    'rating': number;
    /**
     * 
     * @type {string}
     * @memberof AppReviewPayload
     */
    'feedback': string | null;
    /**
     * 
     * @type {object}
     * @memberof AppReviewPayload
     */
    'metadata': object | null;
}
/**
 * 
 * @export
 * @interface AppReviewResponse
 */
export interface AppReviewResponse {
    /**
     * 
     * @type {number}
     * @memberof AppReviewResponse
     */
    'rating': number;
    /**
     * 
     * @type {string}
     * @memberof AppReviewResponse
     */
    'feedback': string | null;
}
/**
 * 
 * @export
 * @interface AppointmentAddToCalendarDetailsResponse
 */
export interface AppointmentAddToCalendarDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof AppointmentAddToCalendarDetailsResponse
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentAddToCalendarDetailsResponse
     */
    'end': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentAddToCalendarDetailsResponse
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentAddToCalendarDetailsResponse
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentAddToCalendarDetailsResponse
     */
    'join_url': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentAddToCalendarDetailsResponse
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentAddToCalendarDetailsResponse
     */
    'ics_data': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentAddToCalendarDetailsResponse
     */
    'add_to_google_calendar_url': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentAddToCalendarDetailsResponse
     */
    'add_to_outlook_url': string;
}
/**
 * 
 * @export
 * @interface AppointmentCanceledResponse
 */
export interface AppointmentCanceledResponse {
    /**
     * 
     * @type {string}
     * @memberof AppointmentCanceledResponse
     */
    'appointment_id': string;
}
/**
 * 
 * @export
 * @interface AppointmentDetailsResponse
 */
export interface AppointmentDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof AppointmentDetailsResponse
     */
    'appointment_id': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentDetailsResponse
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentDetailsResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {APIAppointmentStatus}
     * @memberof AppointmentDetailsResponse
     */
    'status': APIAppointmentStatus;
    /**
     * 
     * @type {APIAppointmentType}
     * @memberof AppointmentDetailsResponse
     */
    'appointment_type': APIAppointmentType;
    /**
     * 
     * @type {number}
     * @memberof AppointmentDetailsResponse
     */
    'duration_in_minutes': number;
    /**
     * 
     * @type {AppointmentLocationType}
     * @memberof AppointmentDetailsResponse
     */
    'location_type': AppointmentLocationType;
    /**
     * 
     * @type {string}
     * @memberof AppointmentDetailsResponse
     */
    'location': string | null;
}


/**
 * 
 * @export
 * @interface AppointmentInfo
 */
export interface AppointmentInfo {
    /**
     * 
     * @type {string}
     * @memberof AppointmentInfo
     */
    'selected_time': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentInfo
     */
    'end_time': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentInfo
     */
    'reason': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentInfo
     */
    'is_video_call': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentInfo
     */
    'is_in_person': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AppointmentInfo
     */
    'appointment_description': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AppointmentLocationType = {
    InPerson: 'in_person',
    Virtual: 'virtual',
    Unknown: 'unknown'
} as const;

export type AppointmentLocationType = typeof AppointmentLocationType[keyof typeof AppointmentLocationType];


/**
 * Response model for the Appointments API.
 * @export
 * @interface AppointmentResponse
 */
export interface AppointmentResponse {
    /**
     * 
     * @type {string}
     * @memberof AppointmentResponse
     */
    'appointment_id': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentResponse
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentResponse
     */
    'calendar_event_id': string;
    /**
     * 
     * @type {APIAppointmentStatus}
     * @memberof AppointmentResponse
     */
    'status': APIAppointmentStatus;
    /**
     * 
     * @type {APIAppointmentType}
     * @memberof AppointmentResponse
     */
    'appointment_type': APIAppointmentType;
    /**
     * 
     * @type {string}
     * @memberof AppointmentResponse
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentResponse
     */
    'end': string;
    /**
     * 
     * @type {number}
     * @memberof AppointmentResponse
     */
    'duration_in_minutes': number;
    /**
     * 
     * @type {RecurrenceType}
     * @memberof AppointmentResponse
     */
    'recurrence_type': RecurrenceType | null;
    /**
     * 
     * @type {string}
     * @memberof AppointmentResponse
     */
    'recurrence_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof AppointmentResponse
     */
    'scribe_generated_chart_notes_completion_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof AppointmentResponse
     */
    'scribe_generated_chart_notes': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentResponse
     */
    'scribe_generated_chart_notes_in_progress': boolean;
    /**
     * 
     * @type {string}
     * @memberof AppointmentResponse
     */
    'chart_notes': string | null;
    /**
     * 
     * @type {string}
     * @memberof AppointmentResponse
     */
    'chart_notes_last_updated_at': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentResponse
     */
    'is_fully_paid'?: boolean;
    /**
     * 
     * @type {AppointmentLocationType}
     * @memberof AppointmentResponse
     */
    'location_type': AppointmentLocationType;
    /**
     * 
     * @type {string}
     * @memberof AppointmentResponse
     */
    'location': string | null;
    /**
     * 
     * @type {RewardType}
     * @memberof AppointmentResponse
     */
    'reward_type': RewardType | null;
}


/**
 * 
 * @export
 * @interface AppointmentVideoCallResponse
 */
export interface AppointmentVideoCallResponse {
    /**
     * 
     * @type {string}
     * @memberof AppointmentVideoCallResponse
     */
    'appointment_id': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentVideoCallResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentVideoCallResponse
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentVideoCallResponse
     */
    'join_url': string;
}
/**
 * 
 * @export
 * @interface AvailabilitySlot
 */
export interface AvailabilitySlot {
    /**
     * 
     * @type {DateTimeWindow}
     * @memberof AvailabilitySlot
     */
    'window': DateTimeWindow;
    /**
     * 
     * @type {ProviderAvailabilityType}
     * @memberof AvailabilitySlot
     */
    'availability_type': ProviderAvailabilityType;
}


/**
 * 
 * @export
 * @interface AvailabilitySlotsResponse
 */
export interface AvailabilitySlotsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof AvailabilitySlotsResponse
     */
    'slots': Array<string>;
    /**
     * 
     * @type {Array<AvailabilitySlot>}
     * @memberof AvailabilitySlotsResponse
     */
    'available_slots': Array<AvailabilitySlot>;
}
/**
 * 
 * @export
 * @interface BMI
 */
export interface BMI {
    /**
     * 
     * @type {string}
     * @memberof BMI
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface BackgroundJobPayload
 */
export interface BackgroundJobPayload {
    /**
     * 
     * @type {string}
     * @memberof BackgroundJobPayload
     */
    'job_name': string;
}
/**
 * 
 * @export
 * @interface BaseAddress
 */
export interface BaseAddress {
    /**
     * 
     * @type {string}
     * @memberof BaseAddress
     */
    'line1': string;
    /**
     * 
     * @type {string}
     * @memberof BaseAddress
     */
    'line2': string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseAddress
     */
    'city': string;
    /**
     * 
     * @type {USState}
     * @memberof BaseAddress
     */
    'state': USState;
    /**
     * 
     * @type {string}
     * @memberof BaseAddress
     */
    'postal_code': string;
    /**
     * 
     * @type {string}
     * @memberof BaseAddress
     */
    'country': string;
}


/**
 * 
 * @export
 * @interface BeginPhoneLoginChallengePayload
 */
export interface BeginPhoneLoginChallengePayload {
    /**
     * 
     * @type {UserType}
     * @memberof BeginPhoneLoginChallengePayload
     */
    'user_type': UserType;
    /**
     * 
     * @type {string}
     * @memberof BeginPhoneLoginChallengePayload
     */
    'phone_number': string;
}


/**
 * 
 * @export
 * @interface BeginResetPasswordPayload
 */
export interface BeginResetPasswordPayload {
    /**
     * 
     * @type {string}
     * @memberof BeginResetPasswordPayload
     */
    'email': string;
    /**
     * 
     * @type {UserType}
     * @memberof BeginResetPasswordPayload
     */
    'user_type': UserType;
}


/**
 * 
 * @export
 * @interface BookAppointmentPayload
 */
export interface BookAppointmentPayload {
    /**
     * 
     * @type {PatientId1}
     * @memberof BookAppointmentPayload
     */
    'patient_id'?: PatientId1 | null;
    /**
     * 
     * @type {ProviderId1}
     * @memberof BookAppointmentPayload
     */
    'provider_id'?: ProviderId1 | null;
    /**
     * 
     * @type {string}
     * @memberof BookAppointmentPayload
     */
    'start': string;
    /**
     * 
     * @type {number}
     * @memberof BookAppointmentPayload
     */
    'duration_in_minutes': number;
    /**
     * 
     * @type {APIAppointmentType}
     * @memberof BookAppointmentPayload
     */
    'appointment_type': APIAppointmentType;
    /**
     * 
     * @type {APIRecurrenceType}
     * @memberof BookAppointmentPayload
     */
    'recurrence_type': APIRecurrenceType | null;
    /**
     * 
     * @type {AppointmentLocationType}
     * @memberof BookAppointmentPayload
     */
    'location_type'?: AppointmentLocationType;
}


/**
 * 
 * @export
 * @interface BookAppointmentResponse
 */
export interface BookAppointmentResponse {
    /**
     * 
     * @type {string}
     * @memberof BookAppointmentResponse
     */
    'appointment_id': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const BookingInfoAppointmentConfirmationStatus = {
    Cancelled: 'CANCELLED',
    Confirmed: 'CONFIRMED',
    Unconfirmed: 'UNCONFIRMED'
} as const;

export type BookingInfoAppointmentConfirmationStatus = typeof BookingInfoAppointmentConfirmationStatus[keyof typeof BookingInfoAppointmentConfirmationStatus];


/**
 * 
 * @export
 * @interface BookingInfoResponse
 */
export interface BookingInfoResponse {
    /**
     * 
     * @type {BookingInfoAppointmentConfirmationStatus}
     * @memberof BookingInfoResponse
     */
    'appointment_confirmation_status': BookingInfoAppointmentConfirmationStatus;
    /**
     * 
     * @type {boolean}
     * @memberof BookingInfoResponse
     */
    'is_patient_insurance_needed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BookingInfoResponse
     */
    'is_patient_address_needed': boolean;
    /**
     * 
     * @type {AppointmentInfo}
     * @memberof BookingInfoResponse
     */
    'appointment_info': AppointmentInfo;
    /**
     * 
     * @type {PatientInfo}
     * @memberof BookingInfoResponse
     */
    'patient_info': PatientInfo;
    /**
     * 
     * @type {ProviderInfo}
     * @memberof BookingInfoResponse
     */
    'provider_info': ProviderInfo;
}


/**
 * 
 * @export
 * @interface BusResubmissionPayload
 */
export interface BusResubmissionPayload {
    /**
     * 
     * @type {string}
     * @memberof BusResubmissionPayload
     */
    'command_or_event_name': string;
    /**
     * 
     * @type {object}
     * @memberof BusResubmissionPayload
     */
    'payload': object;
}
/**
 * 
 * @export
 * @interface BusResubmissionResponse
 */
export interface BusResubmissionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof BusResubmissionResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof BusResubmissionResponse
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface CalendarEventResponse
 */
export interface CalendarEventResponse {
    /**
     * 
     * @type {string}
     * @memberof CalendarEventResponse
     */
    'calendar_event_id': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventResponse
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventResponse
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventResponse
     */
    'end': string;
    /**
     * 
     * @type {APIRecurrenceType}
     * @memberof CalendarEventResponse
     */
    'recurrence_type': APIRecurrenceType | null;
    /**
     * 
     * @type {AppointmentDetailsResponse}
     * @memberof CalendarEventResponse
     */
    'appointment': AppointmentDetailsResponse | null;
    /**
     * 
     * @type {SyncedCalendarEventDetailsResponse}
     * @memberof CalendarEventResponse
     */
    'synced_calendar_event': SyncedCalendarEventDetailsResponse | null;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventResponse
     */
    'location': string | null;
    /**
     * 
     * @type {AppointmentLocationType}
     * @memberof CalendarEventResponse
     */
    'location_type': AppointmentLocationType;
}


/**
 * 
 * @export
 * @interface CalendarEventsResponse
 */
export interface CalendarEventsResponse {
    /**
     * 
     * @type {Array<CalendarEventResponse>}
     * @memberof CalendarEventsResponse
     */
    'events': Array<CalendarEventResponse>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CardBrand = {
    Visa: 'Visa',
    Mastercard: 'Mastercard',
    AmericanExpress: 'American Express',
    Discover: 'Discover',
    Jcb: 'JCB',
    DinersClub: 'Diners Club',
    Unknown: 'Unknown'
} as const;

export type CardBrand = typeof CardBrand[keyof typeof CardBrand];


/**
 * 
 * @export
 * @enum {string}
 */

export const CardSource = {
    CreditCard: 'Credit Card',
    HsaCard: 'HSA Card',
    FsaCard: 'FSA Card'
} as const;

export type CardSource = typeof CardSource[keyof typeof CardSource];


/**
 * 
 * @export
 * @interface CartDetailsResponse
 */
export interface CartDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof CartDetailsResponse
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof CartDetailsResponse
     */
    'total_price': string;
    /**
     * 
     * @type {Array<CartItemDetailsResponse>}
     * @memberof CartDetailsResponse
     */
    'items': Array<CartItemDetailsResponse>;
}
/**
 * 
 * @export
 * @interface CartItemDetailsResponse
 */
export interface CartItemDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof CartItemDetailsResponse
     */
    'cart_item_id': string;
    /**
     * 
     * @type {string}
     * @memberof CartItemDetailsResponse
     */
    'product_id': string;
    /**
     * 
     * @type {string}
     * @memberof CartItemDetailsResponse
     */
    'product_variant_id': string;
    /**
     * 
     * @type {string}
     * @memberof CartItemDetailsResponse
     */
    'product_name': string;
    /**
     * 
     * @type {string}
     * @memberof CartItemDetailsResponse
     */
    'variant_name': string;
    /**
     * 
     * @type {string}
     * @memberof CartItemDetailsResponse
     */
    'image_url': string;
    /**
     * 
     * @type {number}
     * @memberof CartItemDetailsResponse
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof CartItemDetailsResponse
     */
    'unit_price': string;
    /**
     * 
     * @type {string}
     * @memberof CartItemDetailsResponse
     */
    'total_price': string;
}
/**
 * 
 * @export
 * @interface CatalogResponse
 */
export interface CatalogResponse {
    /**
     * 
     * @type {Array<ProductDetailsResponse>}
     * @memberof CatalogResponse
     */
    'products': Array<ProductDetailsResponse>;
    /**
     * 
     * @type {number}
     * @memberof CatalogResponse
     */
    'total_count': number;
    /**
     * 
     * @type {number}
     * @memberof CatalogResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof CatalogResponse
     */
    'limit': number;
}
/**
 * Sort options for the catalog.
 * @export
 * @enum {string}
 */

export const CatalogSortOptions = {
    NameAsc: 'name_asc',
    NameDesc: 'name_desc',
    PriceAsc: 'price_asc',
    PriceDesc: 'price_desc'
} as const;

export type CatalogSortOptions = typeof CatalogSortOptions[keyof typeof CatalogSortOptions];


/**
 * 
 * @export
 * @interface CategoryDetailsResponse
 */
export interface CategoryDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof CategoryDetailsResponse
     */
    'category_id': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryDetailsResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CategoryDetailsResponse
     */
    'product_count': number;
}
/**
 * A reason for a charge to a patient. This is mostly used for internal tracking.
 * @export
 * @enum {string}
 */

export const ChargeReason = {
    DeductibleOwed: 'deductible owed',
    ClaimPartiallyPaid: 'claim partially paid',
    ClaimRejected: 'claim rejected',
    ClaimDenied: 'claim denied',
    ClaimStuckInSent: 'claim stuck in sent',
    NoShowFee: 'no-show fee',
    LateCancellationFee: 'late cancellation fee',
    PatientMissingInsurance: 'patient missing insurance',
    FullyPaidFee: 'fully paid fee',
    OutstandingBalanceCollection: 'outstanding balance collection'
} as const;

export type ChargeReason = typeof ChargeReason[keyof typeof ChargeReason];


/**
 * 
 * @export
 * @enum {string}
 */

export const ChargeStatus = {
    Initiated: 'initiated',
    Succeeded: 'succeeded',
    Failed: 'failed',
    Unknown: 'unknown',
    Canceled: 'canceled',
    Scheduled: 'scheduled',
    Refunded: 'refunded',
    WillNotSend: 'will_not_send'
} as const;

export type ChargeStatus = typeof ChargeStatus[keyof typeof ChargeStatus];


/**
 * 
 * @export
 * @interface ChatChannelResponse
 */
export interface ChatChannelResponse {
    /**
     * 
     * @type {string}
     * @memberof ChatChannelResponse
     */
    'chat_channel_id': string;
    /**
     * 
     * @type {ChatMessageResponse}
     * @memberof ChatChannelResponse
     */
    'latest_message': ChatMessageResponse | null;
    /**
     * 
     * @type {PatientSenderResponse}
     * @memberof ChatChannelResponse
     */
    'patient'?: PatientSenderResponse | null;
    /**
     * 
     * @type {ProviderSenderResponse}
     * @memberof ChatChannelResponse
     */
    'provider'?: ProviderSenderResponse | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChatChannelResponse
     */
    'has_active_relationship': boolean;
}
/**
 * 
 * @export
 * @interface ChatChannelsResponse
 */
export interface ChatChannelsResponse {
    /**
     * 
     * @type {Array<ChatChannelResponse>}
     * @memberof ChatChannelsResponse
     */
    'channels': Array<ChatChannelResponse>;
    /**
     * 
     * @type {string}
     * @memberof ChatChannelsResponse
     */
    'latest_updated_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatChannelsResponse
     */
    'earliest_updated_at': string | null;
}
/**
 * 
 * @export
 * @interface ChatChannelsUnreadCountResponse
 */
export interface ChatChannelsUnreadCountResponse {
    /**
     * 
     * @type {number}
     * @memberof ChatChannelsUnreadCountResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface ChatMessageAttachmentPayload
 */
export interface ChatMessageAttachmentPayload {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageAttachmentPayload
     */
    'file_key': string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageAttachmentPayload
     */
    'file_name': string;
}
/**
 * 
 * @export
 * @interface ChatMessageAttachmentResponse
 */
export interface ChatMessageAttachmentResponse {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageAttachmentResponse
     */
    'attachment_id': string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageAttachmentResponse
     */
    'file_name': string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageAttachmentResponse
     */
    'storage_key': string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageAttachmentResponse
     */
    'signed_url': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ChatMessageContentTypeResponse = {
    TextPlain: 'text/plain',
    TextHtml: 'text/html'
} as const;

export type ChatMessageContentTypeResponse = typeof ChatMessageContentTypeResponse[keyof typeof ChatMessageContentTypeResponse];


/**
 * 
 * @export
 * @interface ChatMessageResponse
 */
export interface ChatMessageResponse {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageResponse
     */
    'message_id': string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageResponse
     */
    'chat_channel_id': string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageResponse
     */
    'sender_id': string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageResponse
     */
    'sent_at': string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageResponse
     */
    'read_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageResponse
     */
    'deleted_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageResponse
     */
    'content': string;
    /**
     * 
     * @type {ChatMessageContentTypeResponse}
     * @memberof ChatMessageResponse
     */
    'content_type': ChatMessageContentTypeResponse;
    /**
     * 
     * @type {Array<ChatMessageAttachmentResponse>}
     * @memberof ChatMessageResponse
     */
    'attachments': Array<ChatMessageAttachmentResponse>;
    /**
     * 
     * @type {JournalEntryResponse}
     * @memberof ChatMessageResponse
     */
    'journal_entry': JournalEntryResponse | null;
    /**
     * 
     * @type {PatientLabResponse}
     * @memberof ChatMessageResponse
     */
    'patient_lab': PatientLabResponse | null;
}


/**
 * 
 * @export
 * @interface ChatMessagesResponse
 */
export interface ChatMessagesResponse {
    /**
     * 
     * @type {Array<ChatMessageResponse>}
     * @memberof ChatMessagesResponse
     */
    'messages': Array<ChatMessageResponse>;
    /**
     * 
     * @type {string}
     * @memberof ChatMessagesResponse
     */
    'earliest_sent_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessagesResponse
     */
    'latest_sent_at': string | null;
}
/**
 * 
 * @export
 * @interface ChimeBridgePayload
 */
export interface ChimeBridgePayload {
    /**
     * 
     * @type {Array<object>}
     * @memberof ChimeBridgePayload
     */
    'messages': Array<object>;
}
/**
 * 
 * @export
 * @interface ClientCareDetailsResponse
 */
export interface ClientCareDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof ClientCareDetailsResponse
     */
    'date_of_service': string;
    /**
     * 
     * @type {ClientCareServiceType}
     * @memberof ClientCareDetailsResponse
     */
    'service_type': ClientCareServiceType;
    /**
     * 
     * @type {string}
     * @memberof ClientCareDetailsResponse
     */
    'billable_remote_encounter_id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClientCareDetailsResponse
     */
    'billable_units'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClientCareDetailsResponse
     */
    'summary'?: string | null;
    /**
     * 
     * @type {PatientDetails}
     * @memberof ClientCareDetailsResponse
     */
    'patient': PatientDetails;
}


/**
 * 
 * @export
 * @interface ClientCareResponse
 */
export interface ClientCareResponse {
    /**
     * 
     * @type {string}
     * @memberof ClientCareResponse
     */
    'date_of_service': string;
    /**
     * 
     * @type {ClientCareServiceType}
     * @memberof ClientCareResponse
     */
    'service_type': ClientCareServiceType;
    /**
     * 
     * @type {string}
     * @memberof ClientCareResponse
     */
    'appointment_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientCareResponse
     */
    'calendar_event_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientCareResponse
     */
    'billable_remote_encounter_id'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ClientCareServiceType = {
    InitialConsultation: 'initial_consultation',
    FollowUp: 'follow_up',
    Chat: 'chat'
} as const;

export type ClientCareServiceType = typeof ClientCareServiceType[keyof typeof ClientCareServiceType];


/**
 * 
 * @export
 * @interface ClientReferralImpressionPayload
 */
export interface ClientReferralImpressionPayload {
    /**
     * 
     * @type {string}
     * @memberof ClientReferralImpressionPayload
     */
    'link_url': string;
}
/**
 * 
 * @export
 * @interface ClientReferralImpressionResponse
 */
export interface ClientReferralImpressionResponse {
    /**
     * 
     * @type {string}
     * @memberof ClientReferralImpressionResponse
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface ClientReferralResponse
 */
export interface ClientReferralResponse {
    /**
     * 
     * @type {string}
     * @memberof ClientReferralResponse
     */
    'referral_url': string;
    /**
     * 
     * @type {number}
     * @memberof ClientReferralResponse
     */
    'converted_client_count': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CognitoUserStatus = {
    Confirmed: 'CONFIRMED',
    Unconfirmed: 'UNCONFIRMED',
    Archived: 'ARCHIVED',
    Compromised: 'COMPROMISED',
    Unknown: 'UNKNOWN',
    ResetRequired: 'RESET_REQUIRED',
    ForceChangePassword: 'FORCE_CHANGE_PASSWORD'
} as const;

export type CognitoUserStatus = typeof CognitoUserStatus[keyof typeof CognitoUserStatus];


/**
 * 
 * @export
 * @interface CommandOrEventSchema
 */
export interface CommandOrEventSchema {
    /**
     * 
     * @type {object}
     * @memberof CommandOrEventSchema
     */
    'properties': object;
    /**
     * 
     * @type {string}
     * @memberof CommandOrEventSchema
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface CommandsAndEventsResponse
 */
export interface CommandsAndEventsResponse {
    /**
     * 
     * @type {Array<CommandOrEventSchema>}
     * @memberof CommandsAndEventsResponse
     */
    'commands': Array<CommandOrEventSchema>;
    /**
     * 
     * @type {Array<CommandOrEventSchema>}
     * @memberof CommandsAndEventsResponse
     */
    'events': Array<CommandOrEventSchema>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CommercialPlanType = {
    Employer: 'employer',
    School: 'school'
} as const;

export type CommercialPlanType = typeof CommercialPlanType[keyof typeof CommercialPlanType];


/**
 * 
 * @export
 * @interface CompleteGoalPayload
 */
export interface CompleteGoalPayload {
    /**
     * 
     * @type {string}
     * @memberof CompleteGoalPayload
     */
    'timezone': string | null;
}
/**
 * 
 * @export
 * @interface CompleteLoginChallengePayload
 */
export interface CompleteLoginChallengePayload {
    /**
     * 
     * @type {string}
     * @memberof CompleteLoginChallengePayload
     */
    'challenge_id': string;
    /**
     * 
     * @type {string}
     * @memberof CompleteLoginChallengePayload
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface CompletePartnerBookingPayload
 */
export interface CompletePartnerBookingPayload {
    /**
     * 
     * @type {string}
     * @memberof CompletePartnerBookingPayload
     */
    'email': string;
    /**
     * 
     * @type {AddressDetails}
     * @memberof CompletePartnerBookingPayload
     */
    'address'?: AddressDetails | null;
    /**
     * 
     * @type {InsuranceDetails}
     * @memberof CompletePartnerBookingPayload
     */
    'insurance'?: InsuranceDetails | null;
    /**
     * 
     * @type {StripePaymentDetails}
     * @memberof CompletePartnerBookingPayload
     */
    'payment'?: StripePaymentDetails | null;
    /**
     * 
     * @type {string}
     * @memberof CompletePartnerBookingPayload
     */
    'first_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletePartnerBookingPayload
     */
    'last_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletePartnerBookingPayload
     */
    'date_of_birth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletePartnerBookingPayload
     */
    'reason': string;
    /**
     * 
     * @type {number}
     * @memberof CompletePartnerBookingPayload
     */
    'weight_in_lbs': number;
    /**
     * 
     * @type {number}
     * @memberof CompletePartnerBookingPayload
     */
    'height_in_inches': number;
    /**
     * 
     * @type {Sex}
     * @memberof CompletePartnerBookingPayload
     */
    'gender': Sex;
    /**
     * 
     * @type {string}
     * @memberof CompletePartnerBookingPayload
     */
    'mobile_phone_no': string;
    /**
     * 
     * @type {Set<EnrollmentTags>}
     * @memberof CompletePartnerBookingPayload
     */
    'tags'?: Set<EnrollmentTags>;
}


/**
 * 
 * @export
 * @interface CompletePartnerBookingResponse
 */
export interface CompletePartnerBookingResponse {
    /**
     * 
     * @type {string}
     * @memberof CompletePartnerBookingResponse
     */
    'appointment_id': string;
    /**
     * 
     * @type {string}
     * @memberof CompletePartnerBookingResponse
     */
    'recurring_donation_access_token': string | null;
}
/**
 * 
 * @export
 * @interface CompletePatientGoalResponse
 */
export interface CompletePatientGoalResponse {
    /**
     * 
     * @type {PatientGoalResponse}
     * @memberof CompletePatientGoalResponse
     */
    'goal': PatientGoalResponse;
    /**
     * 
     * @type {RewardResponse}
     * @memberof CompletePatientGoalResponse
     */
    'reward': RewardResponse | null;
    /**
     * 
     * @type {ProgressResponse}
     * @memberof CompletePatientGoalResponse
     */
    'progress': ProgressResponse;
}
/**
 * 
 * @export
 * @interface CompleteResetPasswordPayload
 */
export interface CompleteResetPasswordPayload {
    /**
     * 
     * @type {string}
     * @memberof CompleteResetPasswordPayload
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof CompleteResetPasswordPayload
     */
    'new_password': string;
}
/**
 * 
 * @export
 * @interface CreateCalendarBlockPayload
 */
export interface CreateCalendarBlockPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateCalendarBlockPayload
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCalendarBlockPayload
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCalendarBlockPayload
     */
    'end': string;
}
/**
 * 
 * @export
 * @interface CreateClaimPayload
 */
export interface CreateClaimPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateClaimPayload
     */
    'appointment_id': string;
}
/**
 * 
 * @export
 * @interface CreateClaimResponse
 */
export interface CreateClaimResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateClaimResponse
     */
    'claim_id': string | null;
}
/**
 * 
 * @export
 * @interface CreateEligibilityFilePayload
 */
export interface CreateEligibilityFilePayload {
    /**
     * 
     * @type {string}
     * @memberof CreateEligibilityFilePayload
     */
    's3_bucket': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEligibilityFilePayload
     */
    's3_key': string;
}
/**
 * 
 * @export
 * @interface CreateExternalProvider
 */
export interface CreateExternalProvider {
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProvider
     */
    'provider_type': string;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProvider
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProvider
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProvider
     */
    'taxonomy': string;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProvider
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProvider
     */
    'npi_number': string;
    /**
     * 
     * @type {Array<CreateExternalProviderAddress>}
     * @memberof CreateExternalProvider
     */
    'addresses': Array<CreateExternalProviderAddress>;
}
/**
 * 
 * @export
 * @interface CreateExternalProviderAddress
 */
export interface CreateExternalProviderAddress {
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProviderAddress
     */
    'country_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProviderAddress
     */
    'country_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProviderAddress
     */
    'address_purpose': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProviderAddress
     */
    'address_type': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProviderAddress
     */
    'line1': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProviderAddress
     */
    'line2': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProviderAddress
     */
    'city': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProviderAddress
     */
    'state': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProviderAddress
     */
    'postal_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProviderAddress
     */
    'phone_number': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProviderAddress
     */
    'fax_number': string | null;
}
/**
 * 
 * @export
 * @interface CreateFormAnswer
 */
export interface CreateFormAnswer {
    /**
     * 
     * @type {string}
     * @memberof CreateFormAnswer
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFormAnswer
     */
    'answer': string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateFormAnswer
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof CreateFormAnswer
     */
    'stable_id': string;
}
/**
 * 
 * @export
 * @interface CreateGoalPayload
 */
export interface CreateGoalPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateGoalPayload
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface CreateJournalEntryFilePayload
 */
export interface CreateJournalEntryFilePayload {
    /**
     * 
     * @type {string}
     * @memberof CreateJournalEntryFilePayload
     */
    'file_key': string;
}
/**
 * 
 * @export
 * @interface CreateJournalEntryPayload
 */
export interface CreateJournalEntryPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateJournalEntryPayload
     */
    'content': string;
    /**
     * 
     * @type {Array<CreateJournalEntryFilePayload>}
     * @memberof CreateJournalEntryPayload
     */
    'files': Array<CreateJournalEntryFilePayload>;
    /**
     * 
     * @type {string}
     * @memberof CreateJournalEntryPayload
     */
    'occurred_at': string;
    /**
     * 
     * @type {string}
     * @memberof CreateJournalEntryPayload
     */
    'current_timezone'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateJournalEntryPayload
     */
    'add_to_current_provider_chat'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateNutritionResearchThreadResponse
 */
export interface CreateNutritionResearchThreadResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateNutritionResearchThreadResponse
     */
    'thread_id': string;
}
/**
 * 
 * @export
 * @interface CreatePaymentInstrumentPayload
 */
export interface CreatePaymentInstrumentPayload {
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentInstrumentPayload
     */
    'card_token': string;
    /**
     * 
     * @type {CardSource}
     * @memberof CreatePaymentInstrumentPayload
     */
    'card_source': CardSource;
}


/**
 * 
 * @export
 * @interface CursorPaginatedJournalEntriesResponse
 */
export interface CursorPaginatedJournalEntriesResponse {
    /**
     * 
     * @type {Array<JournalEntryResponse>}
     * @memberof CursorPaginatedJournalEntriesResponse
     */
    'entries': Array<JournalEntryResponse>;
    /**
     * 
     * @type {string}
     * @memberof CursorPaginatedJournalEntriesResponse
     */
    'next_cursor': string | null;
}
/**
 * 
 * @export
 * @interface DateRangePaginatedClientCareResponse
 */
export interface DateRangePaginatedClientCareResponse {
    /**
     * 
     * @type {Array<ClientCareResponse>}
     * @memberof DateRangePaginatedClientCareResponse
     */
    'client_care': Array<ClientCareResponse>;
    /**
     * 
     * @type {number}
     * @memberof DateRangePaginatedClientCareResponse
     */
    'next_page': number;
}
/**
 * 
 * @export
 * @interface DateTimeWindow
 */
export interface DateTimeWindow {
    /**
     * 
     * @type {string}
     * @memberof DateTimeWindow
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof DateTimeWindow
     */
    'end': string;
}
/**
 * 
 * @export
 * @enum {number}
 */

export const DayOfWeek = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7
} as const;

export type DayOfWeek = typeof DayOfWeek[keyof typeof DayOfWeek];


/**
 * 
 * @export
 * @interface EligibilityFileResponse
 */
export interface EligibilityFileResponse {
    /**
     * 
     * @type {string}
     * @memberof EligibilityFileResponse
     */
    'employer_eligibility_file_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityFileResponse
     */
    's3_bucket': string;
    /**
     * 
     * @type {string}
     * @memberof EligibilityFileResponse
     */
    's3_key': string;
    /**
     * 
     * @type {EmployerEligibilityFileStatus}
     * @memberof EligibilityFileResponse
     */
    'status': EmployerEligibilityFileStatus;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const EligibilityStatus = {
    Eligible: 'ELIGIBLE',
    Ineligible: 'INELIGIBLE',
    Invalid: 'INVALID',
    EligibleOverride: 'ELIGIBLE_OVERRIDE'
} as const;

export type EligibilityStatus = typeof EligibilityStatus[keyof typeof EligibilityStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const EmployerEligibilityFileStatus = {
    Pending: 'pending',
    Succeeded: 'succeeded',
    Failed: 'failed'
} as const;

export type EmployerEligibilityFileStatus = typeof EmployerEligibilityFileStatus[keyof typeof EmployerEligibilityFileStatus];


/**
 * 
 * @export
 * @interface EndDate
 */
export interface EndDate {
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EnrollmentTags = {
    Autoimmune: 'Autoimmune',
    Bariatric: 'Bariatric',
    Celiac: 'Celiac',
    Colitis: 'Colitis',
    CrohnsDisease: 'Crohn\'s Disease',
    Diabetes: 'Diabetes',
    EatingDisordersDisorderedEating: 'Eating Disorders & Disordered Eating',
    FoodAllergiesSensitivities: 'Food Allergies & Sensitivities',
    Gerd: 'GERD',
    GeneralNutrition: 'General Nutrition',
    GutHealth: 'Gut Health',
    HighBloodPressure: 'High Blood Pressure',
    HighCholesterol: 'High Cholesterol',
    Ibd: 'IBD',
    Ibs: 'IBS',
    KidneyDisease: 'Kidney Disease',
    PerimenopauseMenopause: 'Perimenopause & Menopause',
    OncologyCancer: 'Oncology / Cancer',
    Pcos: 'PCOS',
    Pots: 'POTS',
    Pediatrics: 'Pediatrics',
    PregnancyPostpartum: 'Pregnancy & Postpartum',
    Sibo: 'SIBO',
    SportsNutrition: 'Sports Nutrition',
    ThyroidHealth: 'Thyroid Health',
    Transplant: 'Transplant',
    VeganVegetarian: 'Vegan & Vegetarian',
    WeightLoss: 'Weight Loss',
    Menopause: 'Menopause',
    Pregnancy: 'Pregnancy',
    Oncology: 'Oncology'
} as const;

export type EnrollmentTags = typeof EnrollmentTags[keyof typeof EnrollmentTags];


/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'detail': string;
}
/**
 * 
 * @export
 * @interface ExternalProviderList
 */
export interface ExternalProviderList {
    /**
     * 
     * @type {Array<CreateExternalProvider>}
     * @memberof ExternalProviderList
     */
    'providers': Array<CreateExternalProvider>;
}
/**
 * 
 * @export
 * @interface ExternalProviderResponse
 */
export interface ExternalProviderResponse {
    /**
     * 
     * @type {string}
     * @memberof ExternalProviderResponse
     */
    'external_provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalProviderResponse
     */
    'provider_type': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalProviderResponse
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalProviderResponse
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalProviderResponse
     */
    'taxonomy': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalProviderResponse
     */
    'npi_number': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalProviderResponse
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalProviderResponse
     */
    'primary_email'?: string | null;
    /**
     * 
     * @type {Array<PatientExternalProviderAddress>}
     * @memberof ExternalProviderResponse
     */
    'addresses': Array<PatientExternalProviderAddress>;
}
/**
 * 
 * @export
 * @interface ExternalProviderResponseList
 */
export interface ExternalProviderResponseList {
    /**
     * 
     * @type {Array<ExternalProviderResponse>}
     * @memberof ExternalProviderResponseList
     */
    'providers': Array<ExternalProviderResponse>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ExternalProviderType = {
    PrimaryCareProvider: 'primary care provider',
    Therapist: 'therapist',
    Other: 'other'
} as const;

export type ExternalProviderType = typeof ExternalProviderType[keyof typeof ExternalProviderType];


/**
 * 
 * @export
 * @interface FormAnswerResponse
 */
export interface FormAnswerResponse {
    /**
     * 
     * @type {string}
     * @memberof FormAnswerResponse
     */
    'form_answer_id': string;
    /**
     * 
     * @type {string}
     * @memberof FormAnswerResponse
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof FormAnswerResponse
     */
    'answer': string | null;
    /**
     * 
     * @type {number}
     * @memberof FormAnswerResponse
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof FormAnswerResponse
     */
    'stable_id': string;
}
/**
 * 
 * @export
 * @interface FormAnswerResponseList
 */
export interface FormAnswerResponseList {
    /**
     * 
     * @type {Array<FormAnswerResponse>}
     * @memberof FormAnswerResponseList
     */
    'answers': Array<FormAnswerResponse>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Gender = {
    Male: 'Male',
    Female: 'Female',
    NonBinary: 'Non-binary',
    PreferNotToSay: 'Prefer not to say'
} as const;

export type Gender = typeof Gender[keyof typeof Gender];


/**
 * 
 * @export
 * @interface GenerateMealPlanPayload
 */
export interface GenerateMealPlanPayload {
    /**
     * The ID of the patient to generate the meal plan for
     * @type {string}
     * @memberof GenerateMealPlanPayload
     */
    'patient_id': string;
    /**
     * A description of the meal plan
     * @type {string}
     * @memberof GenerateMealPlanPayload
     */
    'description': string;
    /**
     * A list of health conditions that the meal plan should be designed to address
     * @type {Array<MealPlanCondition>}
     * @memberof GenerateMealPlanPayload
     */
    'conditions': Array<MealPlanCondition>;
    /**
     * 
     * @type {number}
     * @memberof GenerateMealPlanPayload
     */
    'calorie_target'?: number | null;
}
/**
 * 
 * @export
 * @interface GetNutritionResearchThreadMessagesResponse
 */
export interface GetNutritionResearchThreadMessagesResponse {
    /**
     * 
     * @type {string}
     * @memberof GetNutritionResearchThreadMessagesResponse
     */
    'thread_id': string;
    /**
     * 
     * @type {Array<NutritionResearchMessageDetails>}
     * @memberof GetNutritionResearchThreadMessagesResponse
     */
    'messages': Array<NutritionResearchMessageDetails>;
}
/**
 * 
 * @export
 * @interface GetNutritionResearchThreadResponse
 */
export interface GetNutritionResearchThreadResponse {
    /**
     * 
     * @type {string}
     * @memberof GetNutritionResearchThreadResponse
     */
    'thread_id': string;
}
/**
 * 
 * @export
 * @interface GoogleBusinessLocationId
 */
export interface GoogleBusinessLocationId {
}
/**
 * 
 * @export
 * @interface GoogleBusinessPlaceId
 */
export interface GoogleBusinessPlaceId {
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface Height
 */
export interface Height {
}
/**
 * 
 * @export
 * @interface HeightFieldInput
 */
export interface HeightFieldInput {
    /**
     * 
     * @type {HeightUnits}
     * @memberof HeightFieldInput
     */
    'unit': HeightUnits;
    /**
     * 
     * @type {Value}
     * @memberof HeightFieldInput
     */
    'value': Value;
}


/**
 * 
 * @export
 * @interface HeightFieldOutput
 */
export interface HeightFieldOutput {
    /**
     * 
     * @type {HeightUnits}
     * @memberof HeightFieldOutput
     */
    'unit': HeightUnits;
    /**
     * 
     * @type {string}
     * @memberof HeightFieldOutput
     */
    'value': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const HeightUnits = {
    Inches: 'inches',
    Centimeters: 'centimeters'
} as const;

export type HeightUnits = typeof HeightUnits[keyof typeof HeightUnits];


/**
 * 
 * @export
 * @enum {string}
 */

export const HolderRelationship = {
    Self: 'Self',
    Spouse: 'Spouse',
    Child: 'Child',
    Other: 'Other',
    Unknown: 'Unknown'
} as const;

export type HolderRelationship = typeof HolderRelationship[keyof typeof HolderRelationship];


/**
 * 
 * @export
 * @interface InitiateUpdateIdentityPayload
 */
export interface InitiateUpdateIdentityPayload {
    /**
     * 
     * @type {string}
     * @memberof InitiateUpdateIdentityPayload
     */
    'phone_number': string | null;
    /**
     * 
     * @type {string}
     * @memberof InitiateUpdateIdentityPayload
     */
    'email': string | null;
}
/**
 * 
 * @export
 * @interface Instagram
 */
export interface Instagram {
}
/**
 * 
 * @export
 * @interface Instagram1
 */
export interface Instagram1 {
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InsuranceCompany = {
    Aetna: 'Aetna',
    Cigna: 'Cigna',
    Humana: 'Humana',
    SelectHealth: 'Select Health',
    Anthem: 'Anthem',
    BlueCrossBlueShield: 'Blue Cross Blue Shield',
    BlueCareNetwork: 'Blue Care Network',
    BlueCross: 'Blue Cross',
    BlueShield: 'Blue Shield',
    Carefirst: 'Carefirst',
    Empire: 'Empire',
    FloridaBlue: 'Florida Blue',
    Highmark: 'Highmark',
    Horizon: 'Horizon',
    IndependenceBlueCross: 'Independence Blue Cross',
    RegenceBlueCrossBlueShield: 'Regence Blue Cross Blue Shield',
    UnitedHealthcare: 'United Healthcare',
    GoldenRule: 'Golden Rule',
    Optum: 'Optum',
    Oxford: 'Oxford',
    Umr: 'UMR',
    UnitedMedicalResources: 'United Medical Resources'
} as const;

export type InsuranceCompany = typeof InsuranceCompany[keyof typeof InsuranceCompany];


/**
 * 
 * @export
 * @interface InsuranceDetails
 */
export interface InsuranceDetails {
    /**
     * 
     * @type {string}
     * @memberof InsuranceDetails
     */
    'insurance_company': string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceDetails
     */
    'member_id': string;
}
/**
 * 
 * @export
 * @interface InsuranceValidationPayload
 */
export interface InsuranceValidationPayload {
    /**
     * 
     * @type {string}
     * @memberof InsuranceValidationPayload
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceValidationPayload
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceValidationPayload
     */
    'date_of_birth': string;
    /**
     * 
     * @type {InsuranceCompany}
     * @memberof InsuranceValidationPayload
     */
    'insurance_company': InsuranceCompany;
    /**
     * 
     * @type {string}
     * @memberof InsuranceValidationPayload
     */
    'member_id': string;
}


/**
 * 
 * @export
 * @interface InsuranceValidationResponse
 */
export interface InsuranceValidationResponse {
    /**
     * 
     * @type {EligibilityStatus}
     * @memberof InsuranceValidationResponse
     */
    'eligibility_status': EligibilityStatus;
    /**
     * 
     * @type {BaseAddress}
     * @memberof InsuranceValidationResponse
     */
    'address': BaseAddress | null;
}


/**
 * 
 * @export
 * @interface IntakeFormAnswerList
 */
export interface IntakeFormAnswerList {
    /**
     * 
     * @type {Array<CreateFormAnswer>}
     * @memberof IntakeFormAnswerList
     */
    'answers': Array<CreateFormAnswer>;
}
/**
 * 
 * @export
 * @interface IntakeFormResponse
 */
export interface IntakeFormResponse {
    /**
     * 
     * @type {FormAnswerResponseList}
     * @memberof IntakeFormResponse
     */
    'form_answers'?: FormAnswerResponseList | null;
    /**
     * 
     * @type {ExternalProviderResponseList}
     * @memberof IntakeFormResponse
     */
    'external_providers'?: ExternalProviderResponseList | null;
    /**
     * 
     * @type {Gender}
     * @memberof IntakeFormResponse
     */
    'gender'?: Gender | null;
    /**
     * 
     * @type {string}
     * @memberof IntakeFormResponse
     */
    'pronouns'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntakeFormResponse
     */
    'profession'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntakeFormResponse
     */
    'work_email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntakeFormResponse
     */
    'student_email'?: string | null;
}


/**
 * 
 * @export
 * @interface JournalEntryFileResponse
 */
export interface JournalEntryFileResponse {
    /**
     * 
     * @type {string}
     * @memberof JournalEntryFileResponse
     */
    'journal_entry_file_id': string;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryFileResponse
     */
    'file_key': string;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryFileResponse
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryFileResponse
     */
    'thumbnail_url': string | null;
}
/**
 * 
 * @export
 * @interface JournalEntryResponse
 */
export interface JournalEntryResponse {
    /**
     * 
     * @type {string}
     * @memberof JournalEntryResponse
     */
    'journal_entry_id': string;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryResponse
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryResponse
     */
    'content': string;
    /**
     * 
     * @type {Array<JournalEntryFileResponse>}
     * @memberof JournalEntryResponse
     */
    'files': Array<JournalEntryFileResponse>;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryResponse
     */
    'occurred_at': string;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryResponse
     */
    'updated_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryResponse
     */
    'deleted_at': string | null;
}
/**
 * 
 * @export
 * @interface JournalStreakResponse
 */
export interface JournalStreakResponse {
    /**
     * Total number of journal entries in the consecutive day streak.
     * @type {number}
     * @memberof JournalStreakResponse
     */
    'journal_count': number;
    /**
     * Consecutive days of journaling. Resets to 0if the patient has not journaled today or yesterday.
     * @type {number}
     * @memberof JournalStreakResponse
     */
    'consecutive_days': number;
    /**
     * 
     * @type {string}
     * @memberof JournalStreakResponse
     */
    'start_date': string | null;
    /**
     * The weekly streak display ([M ✅ , T ✅ , W...]). If there is no streak, an array starting with today in the patient\'s timezone will be returned.
     * @type {Array<WeeklyStreakDisplayResponse>}
     * @memberof JournalStreakResponse
     */
    'weekly_streak_display': Array<WeeklyStreakDisplayResponse>;
}
/**
 * 
 * @export
 * @interface LabResult
 */
export interface LabResult {
    /**
     * 
     * @type {string}
     * @memberof LabResult
     */
    'test': string;
    /**
     * 
     * @type {string}
     * @memberof LabResult
     */
    'result': string;
    /**
     * 
     * @type {string}
     * @memberof LabResult
     */
    'units': string | null;
    /**
     * 
     * @type {string}
     * @memberof LabResult
     */
    'reference_interval': string | null;
    /**
     * 
     * @type {LabResultStatus}
     * @memberof LabResult
     */
    'status': LabResultStatus | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const LabResultStatus = {
    Low: 'Low',
    Normal: 'Normal',
    High: 'High',
    NotSpecified: 'Not Specified',
    CheckLabReport: 'Check Lab Report'
} as const;

export type LabResultStatus = typeof LabResultStatus[keyof typeof LabResultStatus];


/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'challenge_id': string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'expires_at': string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'cooldown_expires_at': string;
    /**
     * 
     * @type {boolean}
     * @memberof LoginResponse
     */
    'is_authenticated': boolean;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'cognito_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'token'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MealPlanCondition = {
    GeneralHealthyDiet: 'general_healthy_diet',
    AnemiaB12Deficient: 'anemia_b12_deficient',
    AnemiaIronDeficient: 'anemia_iron_deficient',
    AntiInflammatory: 'anti_inflammatory',
    BariatricStage4: 'bariatric_stage_4',
    CardiovascularDiseaseDiabetes: 'cardiovascular_disease_diabetes',
    CeliacDisease: 'celiac_disease',
    Cirrhosis: 'cirrhosis',
    CkdStage12: 'ckd_stage_1_2',
    CkdStage34: 'ckd_stage_3_4',
    CkdStage5: 'ckd_stage_5',
    CkdStage5Hd: 'ckd_stage_5_hd',
    CkdStage5Pd: 'ckd_stage_5_pd',
    DashDiet: 'dash_diet',
    DiabetesType1: 'diabetes_type_1',
    DiabetesType2: 'diabetes_type_2',
    Diverticulitis: 'diverticulitis',
    Diverticulosis: 'diverticulosis',
    EatMoreVeggies: 'eat_more_veggies',
    Endurance: 'endurance',
    ExtensiveMacroMicro: 'extensive_macro_micro',
    Fertility: 'fertility',
    FollicularOvulationPhase: 'follicular_ovulation_phase',
    Glp1DiabetesManagement: 'glp_1_diabetes_management',
    Glp1WeightLoss: 'glp_1_weight_loss',
    HealthyFlex: 'healthy_flex',
    HeartFailure: 'heart_failure',
    HighCholesterol: 'high_cholesterol',
    HighFiberHighProtein: 'high_fiber_high_protein',
    Hypermetabolism: 'hypermetabolism',
    Hypertension: 'hypertension',
    IncreaseEnergy: 'increase_energy',
    Keto: 'keto',
    Lactation: 'lactation',
    LowCarb: 'low_carb',
    LowCarbHighFat: 'low_carb_high_fat',
    LowFodmap: 'low_fodmap',
    LowSodium: 'low_sodium',
    LutealPhase: 'luteal_phase',
    MediterraneanDiet: 'mediterranean_diet',
    Menopause: 'menopause',
    MenstrualPhase: 'menstrual_phase',
    MicrobiomeBooster: 'microbiome_booster',
    Migraine: 'migraine',
    MindDiet: 'mind_diet',
    MuscleGain: 'muscle_gain',
    Nafld: 'nafld',
    Nash: 'nash',
    Osteoporosis: 'osteoporosis',
    OverweightObesity: 'overweight_obesity',
    Pcos: 'pcos',
    PostMenopause: 'post_menopause',
    Postpartum: 'postpartum',
    Prediabetes: 'prediabetes',
    Pregnancy: 'pregnancy',
    Whole30: 'whole30',
    ZoneDiet: 'zone_diet',
    Autoimmune: 'autoimmune',
    PlannedBariatricSurgery: 'planned_bariatric_surgery',
    Colitis: 'colitis',
    CrohnsDisease: 'crohns_disease',
    Diabetes: 'diabetes',
    EatingDisorder: 'eating_disorder',
    HealthyEating: 'healthy_eating',
    GutHealth: 'gut_health',
    HighBloodPressure: 'high_blood_pressure',
    IrritableBowelSyndrome: 'irritable_bowel_syndrome',
    InflammatoryBowelDisease: 'inflammatory_bowel_disease',
    KidneyDisease: 'kidney_disease',
    Pots: 'pots',
    Sibo: 'sibo',
    Gerd: 'gerd',
    WeightLoss: 'weight_loss',
    WeightGain: 'weight_gain'
} as const;

export type MealPlanCondition = typeof MealPlanCondition[keyof typeof MealPlanCondition];


/**
 * 
 * @export
 * @interface MealPlanResponse
 */
export interface MealPlanResponse {
    /**
     * The ID of the meal plan
     * @type {string}
     * @memberof MealPlanResponse
     */
    'meal_plan_id': string;
    /**
     * The ID of the patient for whom the meal plan is generated
     * @type {string}
     * @memberof MealPlanResponse
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof MealPlanResponse
     */
    'chat_completion_id': string | null;
    /**
     * The meals associated with the meal plan
     * @type {Array<MealResponse>}
     * @memberof MealPlanResponse
     */
    'meals': Array<MealResponse>;
    /**
     * Indicates if the meal plan has been generated
     * @type {boolean}
     * @memberof MealPlanResponse
     */
    'is_generated': boolean;
}
/**
 * 
 * @export
 * @interface MealResponse
 */
export interface MealResponse {
    /**
     * The ID of the meal
     * @type {string}
     * @memberof MealResponse
     */
    'meal_id': string;
    /**
     * The type of meal
     * @type {MealType}
     * @memberof MealResponse
     */
    'meal_type': MealType;
    /**
     * The recipe associated with the meal
     * @type {RecipeResponse}
     * @memberof MealResponse
     */
    'recipe': RecipeResponse;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const MealType = {
    Breakfast: 'Breakfast',
    Lunch: 'Lunch',
    Dinner: 'Dinner',
    Snack: 'Snack'
} as const;

export type MealType = typeof MealType[keyof typeof MealType];


/**
 * 
 * @export
 * @interface NPIProviderAddress
 */
export interface NPIProviderAddress {
    /**
     * 
     * @type {string}
     * @memberof NPIProviderAddress
     */
    'country_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderAddress
     */
    'country_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderAddress
     */
    'address_purpose'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderAddress
     */
    'address_type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderAddress
     */
    'address_1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderAddress
     */
    'address_2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderAddress
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderAddress
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderAddress
     */
    'postal_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderAddress
     */
    'telephone_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderAddress
     */
    'fax_number'?: string | null;
}
/**
 * 
 * @export
 * @interface NPIProviderResponse
 */
export interface NPIProviderResponse {
    /**
     * 
     * @type {string}
     * @memberof NPIProviderResponse
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderResponse
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderResponse
     */
    'middle_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderResponse
     */
    'npi_number': string;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderResponse
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderResponse
     */
    'taxonomy': string;
    /**
     * 
     * @type {Array<NPIProviderAddress>}
     * @memberof NPIProviderResponse
     */
    'addresses': Array<NPIProviderAddress>;
}
/**
 * 
 * @export
 * @interface NutritionResearchCitation
 */
export interface NutritionResearchCitation {
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchCitation
     */
    'nutrition_research_citation_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchCitation
     */
    'nutrition_research_message_id': string;
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchCitation
     */
    'source_name': string;
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchCitation
     */
    'citation_text': string;
    /**
     * 
     * @type {number}
     * @memberof NutritionResearchCitation
     */
    'citation_text_start_index': number;
}
/**
 * 
 * @export
 * @interface NutritionResearchCitationDetails
 */
export interface NutritionResearchCitationDetails {
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchCitationDetails
     */
    'citation_id': string;
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchCitationDetails
     */
    'message_id': string;
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchCitationDetails
     */
    'source_name': string;
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchCitationDetails
     */
    'citation_text': string;
    /**
     * 
     * @type {number}
     * @memberof NutritionResearchCitationDetails
     */
    'citation_text_start_index': number;
}
/**
 * 
 * @export
 * @interface NutritionResearchFeedbackPayload
 */
export interface NutritionResearchFeedbackPayload {
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchFeedbackPayload
     */
    'message_id': string;
    /**
     * 
     * @type {NutritionResearchFeedbackRating}
     * @memberof NutritionResearchFeedbackPayload
     */
    'feedback_rating': NutritionResearchFeedbackRating;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const NutritionResearchFeedbackRating = {
    Positive: 'positive',
    Negative: 'negative'
} as const;

export type NutritionResearchFeedbackRating = typeof NutritionResearchFeedbackRating[keyof typeof NutritionResearchFeedbackRating];


/**
 * 
 * @export
 * @interface NutritionResearchMessageDetails
 */
export interface NutritionResearchMessageDetails {
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchMessageDetails
     */
    'message_id': string;
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchMessageDetails
     */
    'thread_id': string;
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchMessageDetails
     */
    'content': string;
    /**
     * 
     * @type {Role}
     * @memberof NutritionResearchMessageDetails
     */
    'role': Role;
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchMessageDetails
     */
    'created_at': string;
    /**
     * 
     * @type {NutritionResearchFeedbackRating}
     * @memberof NutritionResearchMessageDetails
     */
    'feedback_rating': NutritionResearchFeedbackRating | null;
    /**
     * 
     * @type {Array<NutritionResearchCitationDetails>}
     * @memberof NutritionResearchMessageDetails
     */
    'citations': Array<NutritionResearchCitationDetails>;
}


/**
 * 
 * @export
 * @interface NutritionResearchRunThreadResponse
 */
export interface NutritionResearchRunThreadResponse {
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchRunThreadResponse
     */
    'response': string;
    /**
     * 
     * @type {Array<NutritionResearchCitation>}
     * @memberof NutritionResearchRunThreadResponse
     */
    'citations': Array<NutritionResearchCitation>;
}
/**
 * 
 * @export
 * @interface NutritionResearchSendMessagePayload
 */
export interface NutritionResearchSendMessagePayload {
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchSendMessagePayload
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchSendMessagePayload
     */
    'thread_id': string;
}
/**
 * 
 * @export
 * @interface OAuthResponse
 */
export interface OAuthResponse {
    /**
     * 
     * @type {boolean}
     * @memberof OAuthResponse
     */
    'is_connected'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OAuthResponse
     */
    'is_revoked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OAuthResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OAuthResponse
     */
    'connect_url'?: string | null;
}
/**
 * 
 * @export
 * @interface OffboardStatusResponse
 */
export interface OffboardStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof OffboardStatusResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof OffboardStatusResponse
     */
    'provider_name': string;
    /**
     * 
     * @type {boolean}
     * @memberof OffboardStatusResponse
     */
    'cognito_offboarded': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OffboardStatusResponse
     */
    'circle_offboarded': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OffboardStatusResponse
     */
    'appointments_cancelled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OffboardStatusResponse
     */
    'database_offboarded': boolean;
}
/**
 * 
 * @export
 * @interface OnboardableProviderResponse
 */
export interface OnboardableProviderResponse {
    /**
     * 
     * @type {string}
     * @memberof OnboardableProviderResponse
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof OnboardableProviderResponse
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof OnboardableProviderResponse
     */
    'npi': string;
    /**
     * 
     * @type {string}
     * @memberof OnboardableProviderResponse
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof OnboardableProviderResponse
     */
    'timezone': string | null;
}
/**
 * 
 * @export
 * @interface OpenAppResponse
 */
export interface OpenAppResponse {
    /**
     * 
     * @type {boolean}
     * @memberof OpenAppResponse
     */
    'did_add_to_streak': boolean;
    /**
     * 
     * @type {StreakSummaryResponse}
     * @memberof OpenAppResponse
     */
    'streak': StreakSummaryResponse | null;
}
/**
 * 
 * @export
 * @interface PartnerBookingVerifyPaymentMethodPayload
 */
export interface PartnerBookingVerifyPaymentMethodPayload {
    /**
     * 
     * @type {string}
     * @memberof PartnerBookingVerifyPaymentMethodPayload
     */
    'stripe_token': string;
    /**
     * 
     * @type {CardSource}
     * @memberof PartnerBookingVerifyPaymentMethodPayload
     */
    'card_source': CardSource;
    /**
     * 
     * @type {string}
     * @memberof PartnerBookingVerifyPaymentMethodPayload
     */
    'partner_booking_code': string;
}


/**
 * 
 * @export
 * @interface PartnerCode
 */
export interface PartnerCode {
    /**
     * 
     * @type {PartnerName}
     * @memberof PartnerCode
     */
    'name': PartnerName;
    /**
     * 
     * @type {string}
     * @memberof PartnerCode
     */
    'code': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PartnerName = {
    Zocdoc: 'zocdoc',
    Levels: 'levels'
} as const;

export type PartnerName = typeof PartnerName[keyof typeof PartnerName];


/**
 * 
 * @export
 * @interface PatientAddress
 */
export interface PatientAddress {
    /**
     * 
     * @type {string}
     * @memberof PatientAddress
     */
    'line1': string;
    /**
     * 
     * @type {string}
     * @memberof PatientAddress
     */
    'line2': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientAddress
     */
    'city': string;
    /**
     * 
     * @type {USState}
     * @memberof PatientAddress
     */
    'state': USState;
    /**
     * 
     * @type {string}
     * @memberof PatientAddress
     */
    'postal_code': string;
    /**
     * 
     * @type {string}
     * @memberof PatientAddress
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof PatientAddress
     */
    'address_id': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PatientBillingItemCoverageStatus = {
    Covered: 'Covered',
    PartiallyCovered: 'Partially covered',
    NoShow: 'No show',
    Pending: 'Pending'
} as const;

export type PatientBillingItemCoverageStatus = typeof PatientBillingItemCoverageStatus[keyof typeof PatientBillingItemCoverageStatus];


/**
 * 
 * @export
 * @interface PatientBillingItemDetailsResponse
 */
export interface PatientBillingItemDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof PatientBillingItemDetailsResponse
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientBillingItemDetailsResponse
     */
    'appointment_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientBillingItemDetailsResponse
     */
    'appointment_date': string;
    /**
     * 
     * @type {APIAppointmentType}
     * @memberof PatientBillingItemDetailsResponse
     */
    'appointment_type': APIAppointmentType;
    /**
     * 
     * @type {PatientBillingItemCoverageStatus}
     * @memberof PatientBillingItemDetailsResponse
     */
    'coverage_status': PatientBillingItemCoverageStatus | null;
    /**
     * 
     * @type {string}
     * @memberof PatientBillingItemDetailsResponse
     */
    'cost_of_appointment': string;
    /**
     * 
     * @type {string}
     * @memberof PatientBillingItemDetailsResponse
     */
    'amount_covered': string;
    /**
     * 
     * @type {string}
     * @memberof PatientBillingItemDetailsResponse
     */
    'amount_charged': string;
    /**
     * 
     * @type {string}
     * @memberof PatientBillingItemDetailsResponse
     */
    'charge_id': string | null;
    /**
     * 
     * @type {ChargeStatus}
     * @memberof PatientBillingItemDetailsResponse
     */
    'charge_status': ChargeStatus | null;
    /**
     * 
     * @type {string}
     * @memberof PatientBillingItemDetailsResponse
     */
    'charged_at': string | null;
    /**
     * 
     * @type {InsuranceCompany}
     * @memberof PatientBillingItemDetailsResponse
     */
    'insurance_company': InsuranceCompany | null;
    /**
     * 
     * @type {string}
     * @memberof PatientBillingItemDetailsResponse
     */
    'charge_refund_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientBillingItemDetailsResponse
     */
    'payment_instrument_id': string | null;
    /**
     * 
     * @type {ChargeReason}
     * @memberof PatientBillingItemDetailsResponse
     */
    'charge_reason': ChargeReason | null;
}


/**
 * 
 * @export
 * @interface PatientBookingSubmitEmailPayload
 */
export interface PatientBookingSubmitEmailPayload {
    /**
     * 
     * @type {string}
     * @memberof PatientBookingSubmitEmailPayload
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PatientBookingSubmitEmailPayload
     */
    'provider_id': string;
}
/**
 * 
 * @export
 * @interface PatientBookingSubmitEmailResponse
 */
export interface PatientBookingSubmitEmailResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PatientBookingSubmitEmailResponse
     */
    'found': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientBookingSubmitEmailResponse
     */
    'is_intake_required': boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientBookingSubmitEmailResponse
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PatientBookingSubmitEmailResponse
     */
    'email_hash': string;
}
/**
 * 
 * @export
 * @interface PatientCommercialPlanDetails
 */
export interface PatientCommercialPlanDetails {
    /**
     * 
     * @type {string}
     * @memberof PatientCommercialPlanDetails
     */
    'commercial_plan_id': string;
    /**
     * 
     * @type {CommercialPlanType}
     * @memberof PatientCommercialPlanDetails
     */
    'commercial_plan_type': CommercialPlanType;
}


/**
 * 
 * @export
 * @interface PatientConfirmationByCodePayload
 */
export interface PatientConfirmationByCodePayload {
    /**
     * 
     * @type {string}
     * @memberof PatientConfirmationByCodePayload
     */
    'new_password': string;
    /**
     * 
     * @type {string}
     * @memberof PatientConfirmationByCodePayload
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface PatientConfirmationPayload
 */
export interface PatientConfirmationPayload {
    /**
     * 
     * @type {string}
     * @memberof PatientConfirmationPayload
     */
    'new_password': string;
}
/**
 * 
 * @export
 * @interface PatientConfirmationPreflightResponse
 */
export interface PatientConfirmationPreflightResponse {
    /**
     * 
     * @type {string}
     * @memberof PatientConfirmationPreflightResponse
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientConfirmationPreflightResponse
     */
    'can_confirm': boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientConfirmationPreflightResponse
     */
    'cognito_id': string;
}
/**
 * 
 * @export
 * @interface PatientDetails
 */
export interface PatientDetails {
    /**
     * 
     * @type {string}
     * @memberof PatientDetails
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetails
     */
    'preferred_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetails
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetails
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetails
     */
    'cognito_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetails
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetails
     */
    'joined_at': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetails
     */
    'date_of_birth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetails
     */
    'phone_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetails
     */
    'avatar_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetails
     */
    'provider_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetails
     */
    'enrollment_reason'?: string | null;
    /**
     * 
     * @type {Set<EnrollmentTags>}
     * @memberof PatientDetails
     */
    'enrollment_tags'?: Set<EnrollmentTags> | null;
    /**
     * 
     * @type {Sex}
     * @memberof PatientDetails
     */
    'sex'?: Sex | null;
    /**
     * 
     * @type {Gender}
     * @memberof PatientDetails
     */
    'gender'?: Gender | null;
    /**
     * 
     * @type {WeightFieldOutput}
     * @memberof PatientDetails
     */
    'weight'?: WeightFieldOutput | null;
    /**
     * 
     * @type {HeightFieldOutput}
     * @memberof PatientDetails
     */
    'height'?: HeightFieldOutput | null;
    /**
     * 
     * @type {BMI}
     * @memberof PatientDetails
     */
    'bmi'?: BMI | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetails
     */
    'timezone': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetails
     */
    'profession'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetails
     */
    'work_email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetails
     */
    'student_email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetails
     */
    'pronouns'?: string | null;
    /**
     * 
     * @type {PatientInsurancePolicy}
     * @memberof PatientDetails
     */
    'insurance_policy'?: PatientInsurancePolicy | null;
    /**
     * 
     * @type {PatientAddress}
     * @memberof PatientDetails
     */
    'mailing_address'?: PatientAddress | null;
    /**
     * 
     * @type {Array<PatientFormAnswer>}
     * @memberof PatientDetails
     */
    'intake_form_answers'?: Array<PatientFormAnswer>;
    /**
     * 
     * @type {Array<PatientExternalProviders>}
     * @memberof PatientDetails
     */
    'external_providers'?: Array<PatientExternalProviders>;
    /**
     * 
     * @type {Array<PatientProviderDetails>}
     * @memberof PatientDetails
     */
    'patient_provider_history'?: Array<PatientProviderDetails>;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDetails
     */
    'is_fully_registered': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDetails
     */
    'has_phone_authentication_authority': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDetails
     */
    'has_set_password': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDetails
     */
    'has_touched_intake': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDetails
     */
    'has_completed_intake': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDetails
     */
    'should_do_intake': boolean;
    /**
     * 
     * @type {PatientInformationReleaseAuthorization}
     * @memberof PatientDetails
     */
    'information_release_authorization'?: PatientInformationReleaseAuthorization | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetails
     */
    'onboarding_code'?: string | null;
    /**
     * 
     * @type {PatientCommercialPlanDetails}
     * @memberof PatientDetails
     */
    'commercial_plan'?: PatientCommercialPlanDetails | null;
    /**
     * 
     * @type {PatientPayoutProgram}
     * @memberof PatientDetails
     */
    'payout_program'?: PatientPayoutProgram | null;
    /**
     * 
     * @type {ReferralSource}
     * @memberof PatientDetails
     */
    'referral_source'?: ReferralSource | null;
    /**
     * 
     * @type {PatientProviderReferralSource}
     * @memberof PatientDetails
     */
    'provider_referral_source'?: PatientProviderReferralSource | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetails
     */
    'levels_user_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetails
     */
    'full_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetails
     */
    'full_legal_name': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PatientDetailsInclusions = {
    NextAppointment: 'next_appointment',
    OldPatients: 'old_patients'
} as const;

export type PatientDetailsInclusions = typeof PatientDetailsInclusions[keyof typeof PatientDetailsInclusions];


/**
 * 
 * @export
 * @interface PatientDetailsListResponse
 */
export interface PatientDetailsListResponse {
    /**
     * 
     * @type {Array<PatientDetailsResponse>}
     * @memberof PatientDetailsListResponse
     */
    'patients': Array<PatientDetailsResponse>;
}
/**
 * 
 * @export
 * @interface PatientDetailsResponse
 */
export interface PatientDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'provider_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'cognito_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'preferred_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'full_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'full_legal_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDetailsResponse
     */
    'has_levels_account': boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'joined_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'date_of_birth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'phone_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'avatar_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'enrollment_reason'?: string | null;
    /**
     * 
     * @type {Set<EnrollmentTags>}
     * @memberof PatientDetailsResponse
     */
    'enrollment_tags'?: Set<EnrollmentTags> | null;
    /**
     * 
     * @type {Sex}
     * @memberof PatientDetailsResponse
     */
    'sex'?: Sex | null;
    /**
     * 
     * @type {WeightFieldOutput}
     * @memberof PatientDetailsResponse
     */
    'weight'?: WeightFieldOutput | null;
    /**
     * 
     * @type {HeightFieldOutput}
     * @memberof PatientDetailsResponse
     */
    'height'?: HeightFieldOutput | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'timezone': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'pronouns'?: string | null;
    /**
     * 
     * @type {PatientInsurancePolicyResponse}
     * @memberof PatientDetailsResponse
     */
    'insurance_policy'?: PatientInsurancePolicyResponse | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDetailsResponse
     */
    'insurance_policy_hidden'?: boolean;
    /**
     * 
     * @type {PatientAddress}
     * @memberof PatientDetailsResponse
     */
    'mailing_address'?: PatientAddress | null;
    /**
     * 
     * @type {UpcomingAppointmentSummary}
     * @memberof PatientDetailsResponse
     */
    'next_appointment'?: UpcomingAppointmentSummary | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'current_provider_id'?: string | null;
    /**
     * 
     * @type {ExternalProviderResponseList}
     * @memberof PatientDetailsResponse
     */
    'external_providers'?: ExternalProviderResponseList | null;
    /**
     * 
     * @type {PatientInformationReleaseAuthorization}
     * @memberof PatientDetailsResponse
     */
    'information_release_authorization'?: PatientInformationReleaseAuthorization | null;
    /**
     * 
     * @type {PatientPayoutProgram}
     * @memberof PatientDetailsResponse
     */
    'payout_program'?: PatientPayoutProgram | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDetailsResponse
     */
    'has_touched_intake': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDetailsResponse
     */
    'has_completed_intake': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDetailsResponse
     */
    'should_do_intake': boolean | null;
    /**
     * 
     * @type {PatientReferralSourceResponse}
     * @memberof PatientDetailsResponse
     */
    'referral_source'?: PatientReferralSourceResponse | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PatientDiscoverySource = {
    FacebookInstagram: 'Facebook / Instagram',
    TikTok: 'TikTok',
    Google: 'Google',
    YouTube: 'YouTube',
    Podcast: 'Podcast',
    Tv: 'TV',
    FriendFamilyAcquaintance: 'Friend / family / acquaintance',
    DoctorTherapist: 'Doctor / therapist',
    Employer: 'Employer',
    InsuranceCompany: 'Insurance company',
    NewsArticlePress: 'News article / press',
    NonProfit: 'Non-profit',
    Other: 'Other'
} as const;

export type PatientDiscoverySource = typeof PatientDiscoverySource[keyof typeof PatientDiscoverySource];


/**
 * 
 * @export
 * @interface PatientExternalFacingDetailsResponse
 */
export interface PatientExternalFacingDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'patient_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'patient_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'patient_date_of_birth'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'appointment_count': number;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'latest_chart_notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'latest_chart_notes_timestamp'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'height_in_inches': number | null;
    /**
     * 
     * @type {number}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'weight_in_lbs': number | null;
    /**
     * 
     * @type {number}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'bmi': number | null;
    /**
     * 
     * @type {Set<EnrollmentTags>}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'enrollment_tags': Set<EnrollmentTags>;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'provider_full_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'provider_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'provider_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'provider_avatar_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'provider_slug': string;
    /**
     * 
     * @type {Set<InsuranceCompany>}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'supported_billing_insurance_companies': Set<InsuranceCompany>;
    /**
     * 
     * @type {Set<InsuranceCompany>}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'supported_booking_insurance_companies': Set<InsuranceCompany>;
}
/**
 * 
 * @export
 * @interface PatientExternalProviderAddress
 */
export interface PatientExternalProviderAddress {
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'external_provider_address_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'external_provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'country_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'country_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'address_purpose': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'address_type': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'line1': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'line2': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'city': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'state': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'postal_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'phone_number': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'fax_number': string | null;
}
/**
 * 
 * @export
 * @interface PatientExternalProviders
 */
export interface PatientExternalProviders {
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviders
     */
    'external_provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviders
     */
    'provider_type': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviders
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviders
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviders
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviders
     */
    'npi_number': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviders
     */
    'taxonomy': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviders
     */
    'primary_email': string | null;
    /**
     * 
     * @type {Array<PatientExternalProviderAddress>}
     * @memberof PatientExternalProviders
     */
    'addresses': Array<PatientExternalProviderAddress>;
}
/**
 * 
 * @export
 * @interface PatientFormAnswer
 */
export interface PatientFormAnswer {
    /**
     * 
     * @type {string}
     * @memberof PatientFormAnswer
     */
    'form_answer_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientFormAnswer
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof PatientFormAnswer
     */
    'answer': string | null;
    /**
     * 
     * @type {number}
     * @memberof PatientFormAnswer
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof PatientFormAnswer
     */
    'stable_id': string;
}
/**
 * 
 * @export
 * @interface PatientGoalResponse
 */
export interface PatientGoalResponse {
    /**
     * 
     * @type {string}
     * @memberof PatientGoalResponse
     */
    'patient_goal_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientGoalResponse
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof PatientGoalResponse
     */
    'points': number;
    /**
     * 
     * @type {string}
     * @memberof PatientGoalResponse
     */
    'last_completed_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientGoalResponse
     */
    'created_at': string;
}
/**
 * 
 * @export
 * @interface PatientGoalsResponse
 */
export interface PatientGoalsResponse {
    /**
     * 
     * @type {Array<PatientGoalResponse>}
     * @memberof PatientGoalsResponse
     */
    'goals': Array<PatientGoalResponse>;
    /**
     * 
     * @type {ProgressResponse}
     * @memberof PatientGoalsResponse
     */
    'progress': ProgressResponse;
}
/**
 * 
 * @export
 * @interface PatientId
 */
export interface PatientId {
}
/**
 * 
 * @export
 * @interface PatientId1
 */
export interface PatientId1 {
}
/**
 * 
 * @export
 * @interface PatientInfo
 */
export interface PatientInfo {
    /**
     * 
     * @type {string}
     * @memberof PatientInfo
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientInfo
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientInfo
     */
    'date_of_birth': string;
    /**
     * 
     * @type {string}
     * @memberof PatientInfo
     */
    'gender': string;
    /**
     * 
     * @type {string}
     * @memberof PatientInfo
     */
    'email_address': string;
    /**
     * 
     * @type {string}
     * @memberof PatientInfo
     */
    'mobile_phone_no': string;
}
/**
 * 
 * @export
 * @interface PatientInformationReleaseAuthorization
 */
export interface PatientInformationReleaseAuthorization {
    /**
     * 
     * @type {string}
     * @memberof PatientInformationReleaseAuthorization
     */
    'information_release_authorization_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientInformationReleaseAuthorization
     */
    'authorized_at': string;
    /**
     * 
     * @type {string}
     * @memberof PatientInformationReleaseAuthorization
     */
    'release_form_preview_url': string;
    /**
     * 
     * @type {number}
     * @memberof PatientInformationReleaseAuthorization
     */
    'version': number;
}
/**
 * 
 * @export
 * @interface PatientInsurancePolicy
 */
export interface PatientInsurancePolicy {
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicy
     */
    'policy_number': string;
    /**
     * 
     * @type {InsuranceCompany}
     * @memberof PatientInsurancePolicy
     */
    'payer': InsuranceCompany;
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicy
     */
    'policy_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicy
     */
    'effective_date_start': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicy
     */
    'effective_date_end': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicy
     */
    'holder_date_of_birth': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicy
     */
    'patient_first_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicy
     */
    'patient_last_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicy
     */
    'holder_first_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicy
     */
    'holder_last_name': string | null;
    /**
     * 
     * @type {HolderRelationship}
     * @memberof PatientInsurancePolicy
     */
    'holder_relationship': HolderRelationship;
    /**
     * 
     * @type {boolean}
     * @memberof PatientInsurancePolicy
     */
    'is_eligible': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientInsurancePolicy
     */
    'is_invalid': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicy
     */
    'patient_address_line1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicy
     */
    'patient_address_line2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicy
     */
    'patient_address_city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicy
     */
    'patient_address_state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicy
     */
    'patient_address_postal_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicy
     */
    'holder_address_line1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicy
     */
    'holder_address_line2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicy
     */
    'holder_address_city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicy
     */
    'holder_address_state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicy
     */
    'holder_address_postal_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicy
     */
    'external_eligibility_request_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicy
     */
    'group_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicy
     */
    'insurance_type_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicy
     */
    'insurance_plan'?: string | null;
}


/**
 * 
 * @export
 * @interface PatientInsurancePolicyResponse
 */
export interface PatientInsurancePolicyResponse {
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicyResponse
     */
    'policy_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicyResponse
     */
    'policy_number': string;
    /**
     * 
     * @type {InsuranceCompany}
     * @memberof PatientInsurancePolicyResponse
     */
    'payer': InsuranceCompany;
    /**
     * 
     * @type {boolean}
     * @memberof PatientInsurancePolicyResponse
     */
    'is_invalid'?: boolean | null;
}


/**
 * 
 * @export
 * @interface PatientLabResponse
 */
export interface PatientLabResponse {
    /**
     * 
     * @type {string}
     * @memberof PatientLabResponse
     */
    'patient_lab_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientLabResponse
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof PatientLabResponse
     */
    'uploaded_at': string;
    /**
     * 
     * @type {string}
     * @memberof PatientLabResponse
     */
    'document_link': string;
    /**
     * 
     * @type {Array<LabResult>}
     * @memberof PatientLabResponse
     */
    'lab_results': Array<LabResult>;
    /**
     * 
     * @type {PatientLabStatus}
     * @memberof PatientLabResponse
     */
    'status': PatientLabStatus;
    /**
     * 
     * @type {boolean}
     * @memberof PatientLabResponse
     */
    'can_accept_feedback': boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PatientLabStatus = {
    Processing: 'Processing',
    Completed: 'Completed',
    Failed: 'Failed'
} as const;

export type PatientLabStatus = typeof PatientLabStatus[keyof typeof PatientLabStatus];


/**
 * 
 * @export
 * @interface PatientLabsResponse
 */
export interface PatientLabsResponse {
    /**
     * 
     * @type {Array<PatientLabResponse>}
     * @memberof PatientLabsResponse
     */
    'patient_labs': Array<PatientLabResponse>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PatientPayoutProgram = {
    AffiliateEnrollmentProgram: 'affiliate_enrollment_program'
} as const;

export type PatientPayoutProgram = typeof PatientPayoutProgram[keyof typeof PatientPayoutProgram];


/**
 * 
 * @export
 * @interface PatientProviderDetails
 */
export interface PatientProviderDetails {
    /**
     * 
     * @type {string}
     * @memberof PatientProviderDetails
     */
    'patient_provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientProviderDetails
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientProviderDetails
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientProviderDetails
     */
    'created_at': string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientProviderDetails
     */
    'is_boosted_provider': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PatientProviderReferralSource = {
    Fay: 'fay',
    FayReferralPartner: 'fay_referral_partner',
    FayBoostedProvider: 'fay_boosted_provider',
    ProviderBookingLink: 'provider_booking_link'
} as const;

export type PatientProviderReferralSource = typeof PatientProviderReferralSource[keyof typeof PatientProviderReferralSource];


/**
 * 
 * @export
 * @interface PatientRecapResponse
 */
export interface PatientRecapResponse {
    /**
     * 
     * @type {string}
     * @memberof PatientRecapResponse
     */
    'patient_id': string;
    /**
     * 
     * @type {PatientRecapStatus}
     * @memberof PatientRecapResponse
     */
    'status': PatientRecapStatus;
    /**
     * 
     * @type {string}
     * @memberof PatientRecapResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof PatientRecapResponse
     */
    'personal_life_details'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientRecapResponse
     */
    'personal_life_details_chat_completion_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientRecapResponse
     */
    'work_life_details'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientRecapResponse
     */
    'work_life_details_chat_completion_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientRecapResponse
     */
    'foods_to_avoid_details'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientRecapResponse
     */
    'foods_to_avoid_details_chat_completion_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientRecapResponse
     */
    'income_levels_details'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientRecapResponse
     */
    'income_levels_details_chat_completion_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientRecapResponse
     */
    'last_appointment_summary'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientRecapResponse
     */
    'last_appointment_summary_chat_completion_id'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PatientRecapStatus = {
    Initialized: 'initialized',
    NeedsOccurredAppointment: 'needs_occurred_appointment',
    NeedsUpdatedRecapFields: 'needs_updated_recap_fields',
    Done: 'done'
} as const;

export type PatientRecapStatus = typeof PatientRecapStatus[keyof typeof PatientRecapStatus];


/**
 * 
 * @export
 * @interface PatientReferralSourceResponse
 */
export interface PatientReferralSourceResponse {
    /**
     * 
     * @type {ReferralSourceType}
     * @memberof PatientReferralSourceResponse
     */
    'source_type': ReferralSourceType;
    /**
     * 
     * @type {string}
     * @memberof PatientReferralSourceResponse
     */
    'source_id': string;
}


/**
 * 
 * @export
 * @interface PatientReleaseFormPreviewUrlResponse
 */
export interface PatientReleaseFormPreviewUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof PatientReleaseFormPreviewUrlResponse
     */
    'release_form_preview_url': string;
    /**
     * 
     * @type {number}
     * @memberof PatientReleaseFormPreviewUrlResponse
     */
    'version': number;
}
/**
 * 
 * @export
 * @interface PatientRemoteEncounterDetailsResponse
 */
export interface PatientRemoteEncounterDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof PatientRemoteEncounterDetailsResponse
     */
    'billable_remote_encounter_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientRemoteEncounterDetailsResponse
     */
    'date_of_service': string;
}
/**
 * 
 * @export
 * @interface PatientRewardsResponse
 */
export interface PatientRewardsResponse {
    /**
     * 
     * @type {string}
     * @memberof PatientRewardsResponse
     */
    'patient_id': string;
    /**
     * 
     * @type {Array<RewardResponse>}
     * @memberof PatientRewardsResponse
     */
    'rewards': Array<RewardResponse>;
}
/**
 * 
 * @export
 * @interface PatientSenderResponse
 */
export interface PatientSenderResponse {
    /**
     * 
     * @type {string}
     * @memberof PatientSenderResponse
     */
    'display_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientSenderResponse
     */
    'preferred_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientSenderResponse
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientSenderResponse
     */
    'avatar_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientSenderResponse
     */
    'cognito_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientSenderResponse
     */
    'type'?: PatientSenderResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PatientSenderResponse
     */
    'patient_id': string;
}

export const PatientSenderResponseTypeEnum = {
    Patient: 'patient'
} as const;

export type PatientSenderResponseTypeEnum = typeof PatientSenderResponseTypeEnum[keyof typeof PatientSenderResponseTypeEnum];

/**
 * 
 * @export
 * @interface PaymentInstrumentResponse
 */
export interface PaymentInstrumentResponse {
    /**
     * 
     * @type {string}
     * @memberof PaymentInstrumentResponse
     */
    'payment_instrument_id': string;
    /**
     * 
     * @type {CardBrand}
     * @memberof PaymentInstrumentResponse
     */
    'brand': CardBrand;
    /**
     * 
     * @type {string}
     * @memberof PaymentInstrumentResponse
     */
    'last_four': string;
    /**
     * 
     * @type {number}
     * @memberof PaymentInstrumentResponse
     */
    'expiration_month': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentInstrumentResponse
     */
    'expiration_year': number;
    /**
     * 
     * @type {CardSource}
     * @memberof PaymentInstrumentResponse
     */
    'card_source': CardSource;
}


/**
 * 
 * @export
 * @interface PayoutDetailsResponse
 */
export interface PayoutDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof PayoutDetailsResponse
     */
    'amount': string;
    /**
     * 
     * @type {string}
     * @memberof PayoutDetailsResponse
     */
    'period_start': string;
    /**
     * 
     * @type {string}
     * @memberof PayoutDetailsResponse
     */
    'period_end': string;
    /**
     * 
     * @type {Array<PayoutLineItemResponse>}
     * @memberof PayoutDetailsResponse
     */
    'line_items': Array<PayoutLineItemResponse>;
}
/**
 * 
 * @export
 * @interface PayoutLineItemResponse
 */
export interface PayoutLineItemResponse {
    /**
     * 
     * @type {string}
     * @memberof PayoutLineItemResponse
     */
    'appointment_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof PayoutLineItemResponse
     */
    'appointment_time': string;
    /**
     * 
     * @type {string}
     * @memberof PayoutLineItemResponse
     */
    'rate': string | null;
    /**
     * 
     * @type {string}
     * @memberof PayoutLineItemResponse
     */
    'payment_source': string;
    /**
     * 
     * @type {string}
     * @memberof PayoutLineItemResponse
     */
    'amount': string;
}
/**
 * 
 * @export
 * @interface PayoutsListResponse
 */
export interface PayoutsListResponse {
    /**
     * 
     * @type {string}
     * @memberof PayoutsListResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {Array<PayoutDetailsResponse>}
     * @memberof PayoutsListResponse
     */
    'payouts': Array<PayoutDetailsResponse>;
}
/**
 * 
 * @export
 * @interface PracticeMetricsResponse
 */
export interface PracticeMetricsResponse {
    /**
     * 
     * @type {string}
     * @memberof PracticeMetricsResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof PracticeMetricsResponse
     */
    'as_of_date': string;
    /**
     * 
     * @type {number}
     * @memberof PracticeMetricsResponse
     */
    'weekly_availability': number;
    /**
     * 
     * @type {number}
     * @memberof PracticeMetricsResponse
     */
    'weekly_retention': number | null;
    /**
     * 
     * @type {number}
     * @memberof PracticeMetricsResponse
     */
    'average_appointment_length': number | null;
    /**
     * 
     * @type {number}
     * @memberof PracticeMetricsResponse
     */
    'average_rating': number | null;
    /**
     * 
     * @type {number}
     * @memberof PracticeMetricsResponse
     */
    'score': number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PriceCategory = {
    InvalidInfo: 'INVALID_INFO',
    Covered: 'COVERED',
    OutOfPocket: 'OUT_OF_POCKET'
} as const;

export type PriceCategory = typeof PriceCategory[keyof typeof PriceCategory];


/**
 * 
 * @export
 * @interface PricePayload
 */
export interface PricePayload {
    /**
     * 
     * @type {string}
     * @memberof PricePayload
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof PricePayload
     */
    'last_name': string;
    /**
     * 
     * @type {InsuranceCompany}
     * @memberof PricePayload
     */
    'insurance_company': InsuranceCompany;
    /**
     * 
     * @type {string}
     * @memberof PricePayload
     */
    'member_id': string;
    /**
     * 
     * @type {string}
     * @memberof PricePayload
     */
    'date_of_birth': string;
    /**
     * 
     * @type {string}
     * @memberof PricePayload
     */
    'mobile_phone_no': string;
}


/**
 * 
 * @export
 * @interface PriceResponse
 */
export interface PriceResponse {
    /**
     * 
     * @type {PriceCategory}
     * @memberof PriceResponse
     */
    'price_result_type': PriceCategory;
    /**
     * 
     * @type {number}
     * @memberof PriceResponse
     */
    'savings_percentage': number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceResponse
     */
    'initial_appointment_price': number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceResponse
     */
    'follow_up_appointment_price': number | null;
}


/**
 * 
 * @export
 * @interface ProductCategoryDetailsResponse
 */
export interface ProductCategoryDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryDetailsResponse
     */
    'category_id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryDetailsResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ProductDetailsResponse
 */
export interface ProductDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsResponse
     */
    'product_id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsResponse
     */
    'description': string;
    /**
     * 
     * @type {Array<ProductVariantDetailsResponse>}
     * @memberof ProductDetailsResponse
     */
    'variants': Array<ProductVariantDetailsResponse>;
    /**
     * 
     * @type {Array<ProductCategoryDetailsResponse>}
     * @memberof ProductDetailsResponse
     */
    'categories': Array<ProductCategoryDetailsResponse>;
}
/**
 * 
 * @export
 * @interface ProductVariantDetailsResponse
 */
export interface ProductVariantDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductVariantDetailsResponse
     */
    'variant_id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariantDetailsResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariantDetailsResponse
     */
    'price': string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariantDetailsResponse
     */
    'image_url': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ProductVariantDetailsResponse
     */
    'options'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {number}
     * @memberof ProductVariantDetailsResponse
     */
    'stock': number;
}
/**
 * 
 * @export
 * @interface ProgressResponse
 */
export interface ProgressResponse {
    /**
     * 
     * @type {number}
     * @memberof ProgressResponse
     */
    'current_points': number;
    /**
     * 
     * @type {number}
     * @memberof ProgressResponse
     */
    'max_points': number;
    /**
     * 
     * @type {string}
     * @memberof ProgressResponse
     */
    'last_completed_at': string | null;
}
/**
 * 
 * @export
 * @interface ProviderAvailabilityScheduleResponse
 */
export interface ProviderAvailabilityScheduleResponse {
    /**
     * 
     * @type {string}
     * @memberof ProviderAvailabilityScheduleResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {Array<ProviderAvailabilityWindowResponse>}
     * @memberof ProviderAvailabilityScheduleResponse
     */
    'windows': Array<ProviderAvailabilityWindowResponse>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProviderAvailabilityType = {
    InPerson: 'in_person',
    Virtual: 'virtual'
} as const;

export type ProviderAvailabilityType = typeof ProviderAvailabilityType[keyof typeof ProviderAvailabilityType];


/**
 * 
 * @export
 * @interface ProviderAvailabilityWindowResponse
 */
export interface ProviderAvailabilityWindowResponse {
    /**
     * 
     * @type {string}
     * @memberof ProviderAvailabilityWindowResponse
     */
    'provider_availability_window_id': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderAvailabilityWindowResponse
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderAvailabilityWindowResponse
     */
    'end_time': string;
    /**
     * 
     * @type {DayOfWeek}
     * @memberof ProviderAvailabilityWindowResponse
     */
    'day_of_week': DayOfWeek;
    /**
     * 
     * @type {string}
     * @memberof ProviderAvailabilityWindowResponse
     */
    'timezone': string;
    /**
     * 
     * @type {ProviderAvailabilityType}
     * @memberof ProviderAvailabilityWindowResponse
     */
    'availability_type': ProviderAvailabilityType;
}


/**
 * 
 * @export
 * @interface ProviderConfigDetails
 */
export interface ProviderConfigDetails {
    /**
     * 
     * @type {string}
     * @memberof ProviderConfigDetails
     */
    'provider_config_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderConfigDetails
     */
    'offers_free_consultations': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderConfigDetails
     */
    'fay_referrals_disabled': boolean;
}
/**
 * 
 * @export
 * @interface ProviderConfirmationPayload
 */
export interface ProviderConfirmationPayload {
    /**
     * 
     * @type {string}
     * @memberof ProviderConfirmationPayload
     */
    'new_password': string;
}
/**
 * 
 * @export
 * @interface ProviderConfirmationPreflightResponse
 */
export interface ProviderConfirmationPreflightResponse {
    /**
     * 
     * @type {string}
     * @memberof ProviderConfirmationPreflightResponse
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderConfirmationPreflightResponse
     */
    'can_confirm': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProviderConfirmationPreflightResponse
     */
    'cognito_id': string;
}
/**
 * 
 * @export
 * @interface ProviderDetailsBasicResponse
 */
export interface ProviderDetailsBasicResponse {
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsBasicResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsBasicResponse
     */
    'full_name': string;
    /**
     * 
     * @type {Set<InsuranceCompany>}
     * @memberof ProviderDetailsBasicResponse
     */
    'supported_booking_insurance_companies': Set<InsuranceCompany>;
    /**
     * 
     * @type {ProviderSettings}
     * @memberof ProviderDetailsBasicResponse
     */
    'settings': ProviderSettings;
    /**
     * 
     * @type {Array<USState>}
     * @memberof ProviderDetailsBasicResponse
     */
    'supported_us_states': Array<USState>;
    /**
     * 
     * @type {BaseAddress}
     * @memberof ProviderDetailsBasicResponse
     */
    'primary_practice_address'?: BaseAddress | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderDetailsBasicResponse
     */
    'is_admin': boolean;
}
/**
 * 
 * @export
 * @interface ProviderDetailsResponse
 */
export interface ProviderDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'cognito_id': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'preferred_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'full_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'full_legal_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'npi': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'timezone': string;
    /**
     * 
     * @type {Sex}
     * @memberof ProviderDetailsResponse
     */
    'sex': Sex | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'pronouns': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'phone_number': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'avatar_url': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderDetailsResponse
     */
    'is_admin': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderDetailsResponse
     */
    'is_churned': boolean;
    /**
     * 
     * @type {Set<InsuranceCompany>}
     * @memberof ProviderDetailsResponse
     */
    'supported_booking_insurance_companies': Set<InsuranceCompany>;
    /**
     * 
     * @type {Set<InsuranceCompany>}
     * @memberof ProviderDetailsResponse
     */
    'supported_billing_insurance_companies': Set<InsuranceCompany>;
    /**
     * 
     * @type {Set<USState>}
     * @memberof ProviderDetailsResponse
     */
    'supported_us_states': Set<USState>;
    /**
     * 
     * @type {ProviderSettings}
     * @memberof ProviderDetailsResponse
     */
    'settings': ProviderSettings;
    /**
     * 
     * @type {ProviderConfigDetails}
     * @memberof ProviderDetailsResponse
     */
    'provider_config': ProviderConfigDetails;
    /**
     * 
     * @type {ProviderWebPresenceDetails}
     * @memberof ProviderDetailsResponse
     */
    'web_presence': ProviderWebPresenceDetails;
    /**
     * 
     * @type {Array<UserRole>}
     * @memberof ProviderDetailsResponse
     */
    'roles'?: Array<UserRole>;
    /**
     * 
     * @type {BaseAddress}
     * @memberof ProviderDetailsResponse
     */
    'primary_practice_address'?: BaseAddress | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderDetailsResponse
     */
    'has_google_business_profile'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderDetailsResponse
     */
    'is_imported': boolean;
}


/**
 * 
 * @export
 * @interface ProviderFeedbackPayload
 */
export interface ProviderFeedbackPayload {
    /**
     * 
     * @type {string}
     * @memberof ProviderFeedbackPayload
     */
    'comment': string;
    /**
     * 
     * @type {number}
     * @memberof ProviderFeedbackPayload
     */
    'rating': number | null;
}
/**
 * 
 * @export
 * @interface ProviderForPatientDetailsResponse
 */
export interface ProviderForPatientDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof ProviderForPatientDetailsResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderForPatientDetailsResponse
     */
    'cognito_id': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderForPatientDetailsResponse
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderForPatientDetailsResponse
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderForPatientDetailsResponse
     */
    'preferred_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderForPatientDetailsResponse
     */
    'full_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderForPatientDetailsResponse
     */
    'avatar_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderForPatientDetailsResponse
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderForPatientDetailsResponse
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderForPatientDetailsResponse
     */
    'timezone': string;
    /**
     * 
     * @type {Sex}
     * @memberof ProviderForPatientDetailsResponse
     */
    'sex': Sex | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderForPatientDetailsResponse
     */
    'pronouns': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderForPatientDetailsResponse
     */
    'current': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProviderForPatientDetailsResponse
     */
    'started_seeing_provider_at': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderForPatientDetailsResponse
     */
    'is_admin': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderForPatientDetailsResponse
     */
    'is_churned': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderForPatientDetailsResponse
     */
    'is_migrated': boolean;
}


/**
 * 
 * @export
 * @interface ProviderGoogleBusinessReviewLinkResponse
 */
export interface ProviderGoogleBusinessReviewLinkResponse {
    /**
     * 
     * @type {string}
     * @memberof ProviderGoogleBusinessReviewLinkResponse
     */
    'review_link': string;
}
/**
 * 
 * @export
 * @interface ProviderId
 */
export interface ProviderId {
}
/**
 * 
 * @export
 * @interface ProviderId1
 */
export interface ProviderId1 {
}
/**
 * 
 * @export
 * @interface ProviderIdentityDetailsResponse
 */
export interface ProviderIdentityDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof ProviderIdentityDetailsResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderIdentityDetailsResponse
     */
    'cognito_id': string;
    /**
     * 
     * @type {CognitoUserStatus}
     * @memberof ProviderIdentityDetailsResponse
     */
    'status': CognitoUserStatus;
    /**
     * 
     * @type {string}
     * @memberof ProviderIdentityDetailsResponse
     */
    'created_at': string;
}


/**
 * 
 * @export
 * @interface ProviderInfo
 */
export interface ProviderInfo {
    /**
     * 
     * @type {string}
     * @memberof ProviderInfo
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderInfo
     */
    'provider_name': string;
}
/**
 * 
 * @export
 * @interface ProviderRateDetail
 */
export interface ProviderRateDetail {
    /**
     * 
     * @type {InsuranceCompany}
     * @memberof ProviderRateDetail
     */
    'payer': InsuranceCompany;
    /**
     * 
     * @type {string}
     * @memberof ProviderRateDetail
     */
    'hourly_rate': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderRateDetail
     */
    'is_eligible': boolean;
}


/**
 * 
 * @export
 * @interface ProviderRatesResponse
 */
export interface ProviderRatesResponse {
    /**
     * 
     * @type {{ [key: string]: ProviderRateDetail; }}
     * @memberof ProviderRatesResponse
     */
    'rates': { [key: string]: ProviderRateDetail; };
    /**
     * 
     * @type {boolean}
     * @memberof ProviderRatesResponse
     */
    'is_any_fay_referral_plan_available': boolean;
}
/**
 * 
 * @export
 * @interface ProviderReputationResponse
 */
export interface ProviderReputationResponse {
    /**
     * 
     * @type {string}
     * @memberof ProviderReputationResponse
     */
    'average_rating': string | null;
    /**
     * 
     * @type {Array<ProviderReviewResponse>}
     * @memberof ProviderReputationResponse
     */
    'reviews'?: Array<ProviderReviewResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderReputationResponse
     */
    'has_google_business_profile'?: boolean;
}
/**
 * 
 * @export
 * @interface ProviderReviewResponse
 */
export interface ProviderReviewResponse {
    /**
     * 
     * @type {string}
     * @memberof ProviderReviewResponse
     */
    'reviewer_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderReviewResponse
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderReviewResponse
     */
    'date_created': string;
}
/**
 * 
 * @export
 * @interface ProviderSenderResponse
 */
export interface ProviderSenderResponse {
    /**
     * 
     * @type {string}
     * @memberof ProviderSenderResponse
     */
    'display_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderSenderResponse
     */
    'preferred_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderSenderResponse
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderSenderResponse
     */
    'avatar_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderSenderResponse
     */
    'cognito_id': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderSenderResponse
     */
    'type'?: ProviderSenderResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProviderSenderResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderSenderResponse
     */
    'slug': string;
}

export const ProviderSenderResponseTypeEnum = {
    Provider: 'provider'
} as const;

export type ProviderSenderResponseTypeEnum = typeof ProviderSenderResponseTypeEnum[keyof typeof ProviderSenderResponseTypeEnum];

/**
 * 
 * @export
 * @interface ProviderSettings
 */
export interface ProviderSettings {
    /**
     * 
     * @type {number}
     * @memberof ProviderSettings
     */
    'minimum_advance_schedule_time'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProviderSettings
     */
    'max_days_in_future'?: number;
}
/**
 * 
 * @export
 * @interface ProviderWebPresenceDetails
 */
export interface ProviderWebPresenceDetails {
    /**
     * 
     * @type {Instagram}
     * @memberof ProviderWebPresenceDetails
     */
    'instagram': Instagram;
    /**
     * 
     * @type {Tiktok}
     * @memberof ProviderWebPresenceDetails
     */
    'tiktok': Tiktok;
    /**
     * 
     * @type {Website}
     * @memberof ProviderWebPresenceDetails
     */
    'website': Website;
    /**
     * 
     * @type {GoogleBusinessLocationId}
     * @memberof ProviderWebPresenceDetails
     */
    'google_business_location_id': GoogleBusinessLocationId;
    /**
     * 
     * @type {GoogleBusinessPlaceId}
     * @memberof ProviderWebPresenceDetails
     */
    'google_business_place_id': GoogleBusinessPlaceId;
}
/**
 * 
 * @export
 * @interface RecipeResponse
 */
export interface RecipeResponse {
    /**
     * The name of the recipe
     * @type {string}
     * @memberof RecipeResponse
     */
    'name': string;
    /**
     * The ingredients of the recipe
     * @type {Array<string>}
     * @memberof RecipeResponse
     */
    'ingredients': Array<string>;
    /**
     * The steps of the recipe
     * @type {Array<string>}
     * @memberof RecipeResponse
     */
    'steps': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RecipeResponse
     */
    'image_url': string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RecurrenceType = {
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    BiWeekly: 'Bi-weekly'
} as const;

export type RecurrenceType = typeof RecurrenceType[keyof typeof RecurrenceType];


/**
 * 
 * @export
 * @interface RecurringDonationResponse
 */
export interface RecurringDonationResponse {
    /**
     * 
     * @type {string}
     * @memberof RecurringDonationResponse
     */
    'amount': string;
}
/**
 * 
 * @export
 * @interface ReferralSource
 */
export interface ReferralSource {
    /**
     * 
     * @type {ReferralSourceType}
     * @memberof ReferralSource
     */
    'source_type': ReferralSourceType;
    /**
     * 
     * @type {SourceId}
     * @memberof ReferralSource
     */
    'source_id': SourceId;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ReferralSourceType = {
    Fay: 'fay',
    Provider: 'provider',
    Partner: 'partner',
    ShareASaleAffiliate: 'share_a_sale_affiliate'
} as const;

export type ReferralSourceType = typeof ReferralSourceType[keyof typeof ReferralSourceType];


/**
 * 
 * @export
 * @interface RegisterPatientPayload
 */
export interface RegisterPatientPayload {
    /**
     * 
     * @type {string}
     * @memberof RegisterPatientPayload
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterPatientPayload
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterPatientPayload
     */
    'email': string;
    /**
     * 
     * @type {Sex}
     * @memberof RegisterPatientPayload
     */
    'sex'?: Sex | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterPatientPayload
     */
    'date_of_birth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterPatientPayload
     */
    'phone_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterPatientPayload
     */
    'timezone': string;
    /**
     * 
     * @type {HeightFieldInput}
     * @memberof RegisterPatientPayload
     */
    'height'?: HeightFieldInput | null;
    /**
     * 
     * @type {WeightFieldInput}
     * @memberof RegisterPatientPayload
     */
    'weight'?: WeightFieldInput | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterPatientPayload
     */
    'enrollment_reason'?: string | null;
    /**
     * 
     * @type {Set<EnrollmentTags>}
     * @memberof RegisterPatientPayload
     */
    'enrollment_tags'?: Set<EnrollmentTags> | null;
    /**
     * 
     * @type {PatientDiscoverySource}
     * @memberof RegisterPatientPayload
     */
    'discovery_source'?: PatientDiscoverySource | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const RegisterPatientReferralType = {
    ProviderClientReferralImpressionToken: 'provider_client_referral_impression_token',
    LevelsReferralToken: 'levels_referral_token',
    ShareASaleAffiliateReferralId: 'share_a_sale_affiliate_referral_id'
} as const;

export type RegisterPatientReferralType = typeof RegisterPatientReferralType[keyof typeof RegisterPatientReferralType];


/**
 * 
 * @export
 * @interface RegisterProviderPayload
 */
export interface RegisterProviderPayload {
    /**
     * 
     * @type {string}
     * @memberof RegisterProviderPayload
     */
    'provider_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterProviderPayload
     */
    'cognito_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterProviderPayload
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterProviderPayload
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterProviderPayload
     */
    'slug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterProviderPayload
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterProviderPayload
     */
    'npi': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterProviderPayload
     */
    'timezone': string;
    /**
     * Is the provider a Fay Staff admin?
     * @type {boolean}
     * @memberof RegisterProviderPayload
     */
    'is_admin'?: boolean;
    /**
     * Is the provider a test provider?
     * @type {boolean}
     * @memberof RegisterProviderPayload
     */
    'is_test'?: boolean;
}
/**
 * 
 * @export
 * @interface ReleaseOfInformationPayload
 */
export interface ReleaseOfInformationPayload {
    /**
     * 
     * @type {boolean}
     * @memberof ReleaseOfInformationPayload
     */
    'authorized': boolean;
    /**
     * 
     * @type {string}
     * @memberof ReleaseOfInformationPayload
     */
    'release_form_preview_url'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReleaseOfInformationPayload
     */
    'version'?: number | null;
}
/**
 * 
 * @export
 * @interface ResetPasswordResponse
 */
export interface ResetPasswordResponse {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordResponse
     */
    'challenge_id': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordResponse
     */
    'expires_at': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordResponse
     */
    'cooldown_expires_at': string;
    /**
     * 
     * @type {boolean}
     * @memberof ResetPasswordResponse
     */
    'password_changed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordResponse
     */
    'username'?: string | null;
}
/**
 * 
 * @export
 * @interface RewardResponse
 */
export interface RewardResponse {
    /**
     * 
     * @type {string}
     * @memberof RewardResponse
     */
    'reward_id': string;
    /**
     * Type of reward, e.g., DIAMOND_PERIWINKLE
     * @type {RewardType}
     * @memberof RewardResponse
     */
    'reward_type': RewardType;
    /**
     * 
     * @type {boolean}
     * @memberof RewardResponse
     */
    'is_claimed': boolean;
    /**
     * 
     * @type {RewardSourceType}
     * @memberof RewardResponse
     */
    'source_type': RewardSourceType;
    /**
     * 
     * @type {string}
     * @memberof RewardResponse
     */
    'source_id': string;
    /**
     * 
     * @type {string}
     * @memberof RewardResponse
     */
    'granted_at': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const RewardSourceType = {
    Appointment: 'appointment',
    Progress: 'progress'
} as const;

export type RewardSourceType = typeof RewardSourceType[keyof typeof RewardSourceType];


/**
 * 
 * @export
 * @enum {string}
 */

export const RewardType = {
    DiamondBlack: 'DIAMOND_BLACK',
    DiamondBubblegum: 'DIAMOND_BUBBLEGUM',
    DiamondCanary: 'DIAMOND_CANARY',
    DiamondEmerald: 'DIAMOND_EMERALD',
    DiamondFire: 'DIAMOND_FIRE',
    DiamondGolden: 'DIAMOND_GOLDEN',
    DiamondIce: 'DIAMOND_ICE',
    DiamondPeriwinkle: 'DIAMOND_PERIWINKLE',
    StarEmerald: 'STAR_EMERALD',
    StarFire: 'STAR_FIRE',
    StarGolden: 'STAR_GOLDEN',
    StarPeriwinkle: 'STAR_PERIWINKLE'
} as const;

export type RewardType = typeof RewardType[keyof typeof RewardType];


/**
 * 
 * @export
 * @enum {string}
 */

export const Role = {
    User: 'user',
    Assistant: 'assistant'
} as const;

export type Role = typeof Role[keyof typeof Role];


/**
 * 
 * @export
 * @interface SendChatMessagePayload
 */
export interface SendChatMessagePayload {
    /**
     * 
     * @type {string}
     * @memberof SendChatMessagePayload
     */
    'content': string;
    /**
     * 
     * @type {ChatMessageContentTypeResponse}
     * @memberof SendChatMessagePayload
     */
    'content_type': ChatMessageContentTypeResponse;
    /**
     * 
     * @type {Array<ChatMessageAttachmentPayload>}
     * @memberof SendChatMessagePayload
     */
    'attachments'?: Array<ChatMessageAttachmentPayload>;
}


/**
 * 
 * @export
 * @interface SendClientReferralSMSPayload
 */
export interface SendClientReferralSMSPayload {
    /**
     * 
     * @type {string}
     * @memberof SendClientReferralSMSPayload
     */
    'phone_number': string;
}
/**
 * 
 * @export
 * @interface SetupPatientAndBookAppointmentAddressDetails
 */
export interface SetupPatientAndBookAppointmentAddressDetails {
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentAddressDetails
     */
    'line1': string;
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentAddressDetails
     */
    'line2': string | null;
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentAddressDetails
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentAddressDetails
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentAddressDetails
     */
    'postal_code': string;
}
/**
 * 
 * @export
 * @interface SetupPatientAndBookAppointmentAnalyticsDetails
 */
export interface SetupPatientAndBookAppointmentAnalyticsDetails {
    /**
     * 
     * @type {Array<UTMParameter>}
     * @memberof SetupPatientAndBookAppointmentAnalyticsDetails
     */
    'utm_parameters'?: Array<UTMParameter>;
}
/**
 * 
 * @export
 * @interface SetupPatientAndBookAppointmentAppointmentDetails
 */
export interface SetupPatientAndBookAppointmentAppointmentDetails {
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentAppointmentDetails
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentAppointmentDetails
     */
    'enrollment_reason': string | null;
    /**
     * 
     * @type {Set<EnrollmentTags>}
     * @memberof SetupPatientAndBookAppointmentAppointmentDetails
     */
    'tags': Set<EnrollmentTags> | null;
    /**
     * 
     * @type {AppointmentLocationType}
     * @memberof SetupPatientAndBookAppointmentAppointmentDetails
     */
    'location_type'?: AppointmentLocationType;
}


/**
 * 
 * @export
 * @interface SetupPatientAndBookAppointmentInsuranceDetails
 */
export interface SetupPatientAndBookAppointmentInsuranceDetails {
    /**
     * 
     * @type {InsuranceCompany}
     * @memberof SetupPatientAndBookAppointmentInsuranceDetails
     */
    'payer': InsuranceCompany;
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentInsuranceDetails
     */
    'policy_number': string;
}


/**
 * 
 * @export
 * @interface SetupPatientAndBookAppointmentPayload
 */
export interface SetupPatientAndBookAppointmentPayload {
    /**
     * 
     * @type {SetupPatientAndBookAppointmentPersonalDetails}
     * @memberof SetupPatientAndBookAppointmentPayload
     */
    'personal': SetupPatientAndBookAppointmentPersonalDetails;
    /**
     * 
     * @type {SetupPatientAndBookAppointmentAppointmentDetails}
     * @memberof SetupPatientAndBookAppointmentPayload
     */
    'appointment': SetupPatientAndBookAppointmentAppointmentDetails;
    /**
     * 
     * @type {SetupPatientAndBookAppointmentProviderDetails}
     * @memberof SetupPatientAndBookAppointmentPayload
     */
    'provider': SetupPatientAndBookAppointmentProviderDetails;
    /**
     * 
     * @type {SetupPatientAndBookAppointmentPaymentDetails}
     * @memberof SetupPatientAndBookAppointmentPayload
     */
    'payment'?: SetupPatientAndBookAppointmentPaymentDetails | null;
    /**
     * 
     * @type {SetupPatientAndBookAppointmentInsuranceDetails}
     * @memberof SetupPatientAndBookAppointmentPayload
     */
    'insurance'?: SetupPatientAndBookAppointmentInsuranceDetails | null;
    /**
     * 
     * @type {SetupPatientAndBookAppointmentAddressDetails}
     * @memberof SetupPatientAndBookAppointmentPayload
     */
    'address'?: SetupPatientAndBookAppointmentAddressDetails | null;
    /**
     * 
     * @type {SetupPatientAndBookAppointmentAnalyticsDetails}
     * @memberof SetupPatientAndBookAppointmentPayload
     */
    'analytics'?: SetupPatientAndBookAppointmentAnalyticsDetails | null;
    /**
     * 
     * @type {SetupPatientAndBookAppointmentReferralDetails}
     * @memberof SetupPatientAndBookAppointmentPayload
     */
    'referral'?: SetupPatientAndBookAppointmentReferralDetails | null;
}
/**
 * 
 * @export
 * @interface SetupPatientAndBookAppointmentPaymentDetails
 */
export interface SetupPatientAndBookAppointmentPaymentDetails {
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentPaymentDetails
     */
    'stripe_customer_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentPaymentDetails
     */
    'stripe_payment_method_id': string | null;
}
/**
 * 
 * @export
 * @interface SetupPatientAndBookAppointmentPersonalDetails
 */
export interface SetupPatientAndBookAppointmentPersonalDetails {
    /**
     * 
     * @type {boolean}
     * @memberof SetupPatientAndBookAppointmentPersonalDetails
     */
    'new_account': boolean;
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentPersonalDetails
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentPersonalDetails
     */
    'timezone': string;
    /**
     * 
     * @type {Weight}
     * @memberof SetupPatientAndBookAppointmentPersonalDetails
     */
    'weight': Weight | null;
    /**
     * 
     * @type {Height}
     * @memberof SetupPatientAndBookAppointmentPersonalDetails
     */
    'height': Height | null;
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentPersonalDetails
     */
    'first_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentPersonalDetails
     */
    'last_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentPersonalDetails
     */
    'date_of_birth'?: string | null;
    /**
     * 
     * @type {Sex}
     * @memberof SetupPatientAndBookAppointmentPersonalDetails
     */
    'sex'?: Sex | null;
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentPersonalDetails
     */
    'phone_number'?: string | null;
    /**
     * 
     * @type {PatientDiscoverySource}
     * @memberof SetupPatientAndBookAppointmentPersonalDetails
     */
    'discovery_source'?: PatientDiscoverySource | null;
}


/**
 * 
 * @export
 * @interface SetupPatientAndBookAppointmentProviderDetails
 */
export interface SetupPatientAndBookAppointmentProviderDetails {
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentProviderDetails
     */
    'provider_id': string;
}
/**
 * 
 * @export
 * @interface SetupPatientAndBookAppointmentReferralDetails
 */
export interface SetupPatientAndBookAppointmentReferralDetails {
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentReferralDetails
     */
    'referral_code': string;
    /**
     * 
     * @type {RegisterPatientReferralType}
     * @memberof SetupPatientAndBookAppointmentReferralDetails
     */
    'referral_type': RegisterPatientReferralType;
}


/**
 * 
 * @export
 * @interface SetupPatientAndBookAppointmentResponse
 */
export interface SetupPatientAndBookAppointmentResponse {
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentResponse
     */
    'appointment_id': string;
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentResponse
     */
    'zoom_join_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentResponse
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentResponse
     */
    'end_time': string;
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentResponse
     */
    'appointment_description': string;
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentResponse
     */
    'recurring_donation_access_token': string | null;
    /**
     * 
     * @type {AppointmentLocationType}
     * @memberof SetupPatientAndBookAppointmentResponse
     */
    'location_type': AppointmentLocationType;
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentResponse
     */
    'location': string | null;
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentResponse
     */
    'ics_data': string;
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentResponse
     */
    'add_to_google_calendar_url': string;
    /**
     * 
     * @type {string}
     * @memberof SetupPatientAndBookAppointmentResponse
     */
    'add_to_outlook_url': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const Sex = {
    Male: 'Male',
    Female: 'Female',
    Other: 'Other',
    PreferNotToSay: 'Prefer not to say'
} as const;

export type Sex = typeof Sex[keyof typeof Sex];


/**
 * 
 * @export
 * @interface SolicitProviderReviewPayload
 */
export interface SolicitProviderReviewPayload {
    /**
     * 
     * @type {string}
     * @memberof SolicitProviderReviewPayload
     */
    'patient_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SolicitProviderReviewPayload
     */
    'phone_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SolicitProviderReviewPayload
     */
    'message_content': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SortOrdering = {
    Ascending: 'ASCENDING',
    Descending: 'DESCENDING'
} as const;

export type SortOrdering = typeof SortOrdering[keyof typeof SortOrdering];


/**
 * 
 * @export
 * @interface SourceId
 */
export interface SourceId {
    /**
     * 
     * @type {PartnerName}
     * @memberof SourceId
     */
    'name': PartnerName;
    /**
     * 
     * @type {string}
     * @memberof SourceId
     */
    'code': string;
}


/**
 * 
 * @export
 * @interface StartDate
 */
export interface StartDate {
}
/**
 * 
 * @export
 * @interface StreakSummaryResponse
 */
export interface StreakSummaryResponse {
    /**
     * Total number of journal entries in the consecutive day streak.
     * @type {number}
     * @memberof StreakSummaryResponse
     */
    'streak_count': number;
    /**
     * Consecutive days of journaling. Resets to 0if the patient has not journaled today or yesterday.
     * @type {number}
     * @memberof StreakSummaryResponse
     */
    'consecutive_days': number;
    /**
     * 
     * @type {string}
     * @memberof StreakSummaryResponse
     */
    'start_date': string | null;
    /**
     * The weekly streak display ([M ✅ , T ✅ , W...]). If there is no streak, an array starting with today in the patient\'s timezone will be returned.
     * @type {Array<WeeklyStreakDisplayResponse>}
     * @memberof StreakSummaryResponse
     */
    'weekly_streak_display': Array<WeeklyStreakDisplayResponse>;
    /**
     * Total points accumulated in the current streak.
     * @type {number}
     * @memberof StreakSummaryResponse
     */
    'total_points': number;
}
/**
 * 
 * @export
 * @interface StripePaymentDetails
 */
export interface StripePaymentDetails {
    /**
     * 
     * @type {CardSource}
     * @memberof StripePaymentDetails
     */
    'payment_type': CardSource;
    /**
     * 
     * @type {string}
     * @memberof StripePaymentDetails
     */
    'stripe_code': string;
}


/**
 * 
 * @export
 * @interface SuggestChatResponsePayload
 */
export interface SuggestChatResponsePayload {
    /**
     * 
     * @type {string}
     * @memberof SuggestChatResponsePayload
     */
    'patient_id': string;
}
/**
 * 
 * @export
 * @interface SuggestChatResponseResponse
 */
export interface SuggestChatResponseResponse {
    /**
     * 
     * @type {string}
     * @memberof SuggestChatResponseResponse
     */
    'response': string;
    /**
     * 
     * @type {string}
     * @memberof SuggestChatResponseResponse
     */
    'chat_completion_id': string;
}
/**
 * 
 * @export
 * @interface SweepstakesExpirationResponse
 */
export interface SweepstakesExpirationResponse {
    /**
     * 
     * @type {string}
     * @memberof SweepstakesExpirationResponse
     */
    'expiration_date': string | null;
}
/**
 * 
 * @export
 * @interface SyncedCalendarEventDetailsResponse
 */
export interface SyncedCalendarEventDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof SyncedCalendarEventDetailsResponse
     */
    'synced_calendar_event_id': string;
    /**
     * 
     * @type {string}
     * @memberof SyncedCalendarEventDetailsResponse
     */
    'synced_calendar_id': string;
    /**
     * 
     * @type {string}
     * @memberof SyncedCalendarEventDetailsResponse
     */
    'ical_uid': string;
    /**
     * 
     * @type {string}
     * @memberof SyncedCalendarEventDetailsResponse
     */
    'source_calendar_event_id': string;
    /**
     * 
     * @type {SyncedCalendarSource}
     * @memberof SyncedCalendarEventDetailsResponse
     */
    'source': SyncedCalendarSource;
    /**
     * 
     * @type {string}
     * @memberof SyncedCalendarEventDetailsResponse
     */
    'synced_event_url': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SyncedCalendarEventDetailsResponse
     */
    'is_busy': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SyncedCalendarEventDetailsResponse
     */
    'is_cancelled': boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const SyncedCalendarSource = {
    Google: 'google'
} as const;

export type SyncedCalendarSource = typeof SyncedCalendarSource[keyof typeof SyncedCalendarSource];


/**
 * 
 * @export
 * @interface Tiktok
 */
export interface Tiktok {
}
/**
 * 
 * @export
 * @interface Tiktok1
 */
export interface Tiktok1 {
}
/**
 * 
 * @export
 * @enum {string}
 */

export const USState = {
    Al: 'AL',
    Ak: 'AK',
    Az: 'AZ',
    Ar: 'AR',
    Ca: 'CA',
    Co: 'CO',
    Ct: 'CT',
    De: 'DE',
    Fl: 'FL',
    Ga: 'GA',
    Hi: 'HI',
    Id: 'ID',
    Il: 'IL',
    In: 'IN',
    Ia: 'IA',
    Ks: 'KS',
    Ky: 'KY',
    La: 'LA',
    Me: 'ME',
    Md: 'MD',
    Ma: 'MA',
    Mi: 'MI',
    Mn: 'MN',
    Ms: 'MS',
    Mo: 'MO',
    Mt: 'MT',
    Ne: 'NE',
    Nv: 'NV',
    Nh: 'NH',
    Nj: 'NJ',
    Nm: 'NM',
    Ny: 'NY',
    Nc: 'NC',
    Nd: 'ND',
    Oh: 'OH',
    Ok: 'OK',
    Or: 'OR',
    Pa: 'PA',
    Ri: 'RI',
    Sc: 'SC',
    Sd: 'SD',
    Tn: 'TN',
    Tx: 'TX',
    Ut: 'UT',
    Vt: 'VT',
    Va: 'VA',
    Wa: 'WA',
    Wv: 'WV',
    Wi: 'WI',
    Wy: 'WY',
    As: 'AS',
    Dc: 'DC',
    Gu: 'GU',
    Mp: 'MP',
    Pr: 'PR',
    Vi: 'VI',
    Aa: 'AA',
    Ae: 'AE',
    Ap: 'AP'
} as const;

export type USState = typeof USState[keyof typeof USState];


/**
 * 
 * @export
 * @interface UTMParameter
 */
export interface UTMParameter {
    /**
     * 
     * @type {string}
     * @memberof UTMParameter
     */
    'parameter_name': string;
    /**
     * 
     * @type {string}
     * @memberof UTMParameter
     */
    'parameter_value': string;
}
/**
 * 
 * @export
 * @interface UpcomingAppointmentSummary
 */
export interface UpcomingAppointmentSummary {
    /**
     * 
     * @type {string}
     * @memberof UpcomingAppointmentSummary
     */
    'appointment_id': string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingAppointmentSummary
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingAppointmentSummary
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingAppointmentSummary
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingAppointmentSummary
     */
    'end_time': string;
    /**
     * 
     * @type {RecurrenceType}
     * @memberof UpcomingAppointmentSummary
     */
    'recurrence_type'?: RecurrenceType | null;
    /**
     * 
     * @type {number}
     * @memberof UpcomingAppointmentSummary
     */
    'duration': number;
}


/**
 * 
 * @export
 * @interface UpdateAddressPayload
 */
export interface UpdateAddressPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressPayload
     */
    'line1': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressPayload
     */
    'line2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressPayload
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressPayload
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressPayload
     */
    'postal_code': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressPayload
     */
    'country': string;
}
/**
 * 
 * @export
 * @interface UpdateAppointmentPayload
 */
export interface UpdateAppointmentPayload {
    /**
     * 
     * @type {APIAppointmentStatus}
     * @memberof UpdateAppointmentPayload
     */
    'status'?: APIAppointmentStatus | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentPayload
     */
    'start'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAppointmentPayload
     */
    'duration_in_minutes'?: number | null;
    /**
     * 
     * @type {APIAppointmentType}
     * @memberof UpdateAppointmentPayload
     */
    'appointment_type'?: APIAppointmentType | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentPayload
     */
    'chart_notes'?: string | null;
    /**
     * 
     * @type {AppointmentLocationType}
     * @memberof UpdateAppointmentPayload
     */
    'location_type'?: AppointmentLocationType;
}


/**
 * 
 * @export
 * @interface UpdateCalendarEventPayload
 */
export interface UpdateCalendarEventPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateCalendarEventPayload
     */
    'title': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCalendarEventPayload
     */
    'start': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCalendarEventPayload
     */
    'end': string | null;
}
/**
 * 
 * @export
 * @interface UpdateCartItemPayload
 */
export interface UpdateCartItemPayload {
    /**
     * 
     * @type {number}
     * @memberof UpdateCartItemPayload
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface UpdateChatCompletionFeedbackPayload
 */
export interface UpdateChatCompletionFeedbackPayload {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateChatCompletionFeedbackPayload
     */
    'binary_feedback'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateChatCompletionFeedbackPayload
     */
    'feedback_text'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateClientDetailsPayload
 */
export interface UpdateClientDetailsPayload {
    /**
     * 
     * @type {number}
     * @memberof UpdateClientDetailsPayload
     */
    'billable_units': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientDetailsPayload
     */
    'summary': string;
}
/**
 * 
 * @export
 * @interface UpdateGoalPayload
 */
export interface UpdateGoalPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalPayload
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface UpdateIdentityResponse
 */
export interface UpdateIdentityResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateIdentityResponse
     */
    'challenge_id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateIdentityResponse
     */
    'expires_at': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateIdentityResponse
     */
    'cooldown_expires_at': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIdentityResponse
     */
    'updated_login'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateInsurancePolicyPayload
 */
export interface UpdateInsurancePolicyPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateInsurancePolicyPayload
     */
    'policy_number': string;
    /**
     * 
     * @type {InsuranceCompany}
     * @memberof UpdateInsurancePolicyPayload
     */
    'payer': InsuranceCompany;
}


/**
 * 
 * @export
 * @interface UpdateIntakePayload
 */
export interface UpdateIntakePayload {
    /**
     * 
     * @type {IntakeFormAnswerList}
     * @memberof UpdateIntakePayload
     */
    'intake_form_answers'?: IntakeFormAnswerList | null;
    /**
     * 
     * @type {ExternalProviderList}
     * @memberof UpdateIntakePayload
     */
    'external_providers'?: ExternalProviderList | null;
    /**
     * 
     * @type {Gender}
     * @memberof UpdateIntakePayload
     */
    'gender'?: Gender | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntakePayload
     */
    'pronouns'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntakePayload
     */
    'profession'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntakePayload
     */
    'work_email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntakePayload
     */
    'student_email'?: string | null;
}


/**
 * 
 * @export
 * @interface UpdateJournalEntryFilePayload
 */
export interface UpdateJournalEntryFilePayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateJournalEntryFilePayload
     */
    'file_key': string;
}
/**
 * 
 * @export
 * @interface UpdateJournalEntryPayload
 */
export interface UpdateJournalEntryPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateJournalEntryPayload
     */
    'content'?: string | null;
    /**
     * 
     * @type {Array<UpdateJournalEntryFilePayload>}
     * @memberof UpdateJournalEntryPayload
     */
    'files'?: Array<UpdateJournalEntryFilePayload> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateJournalEntryPayload
     */
    'occurred_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateJournalEntryPayload
     */
    'current_timezone'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateMealPayload
 */
export interface UpdateMealPayload {
    /**
     * The updated recipe details
     * @type {UpdateRecipePayload}
     * @memberof UpdateMealPayload
     */
    'recipe': UpdateRecipePayload;
}
/**
 * 
 * @export
 * @interface UpdatePatientPayload
 */
export interface UpdatePatientPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientPayload
     */
    'first_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientPayload
     */
    'last_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientPayload
     */
    'preferred_first_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientPayload
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientPayload
     */
    'date_of_birth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientPayload
     */
    'phone_number'?: string | null;
    /**
     * 
     * @type {Sex}
     * @memberof UpdatePatientPayload
     */
    'sex'?: Sex | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientPayload
     */
    'pronouns'?: string | null;
    /**
     * 
     * @type {HeightFieldInput}
     * @memberof UpdatePatientPayload
     */
    'height'?: HeightFieldInput | null;
    /**
     * 
     * @type {WeightFieldInput}
     * @memberof UpdatePatientPayload
     */
    'weight'?: WeightFieldInput | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientPayload
     */
    'avatar_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientPayload
     */
    'timezone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientPayload
     */
    'enrollment_reason'?: string | null;
    /**
     * 
     * @type {UpdateInsurancePolicyPayload}
     * @memberof UpdatePatientPayload
     */
    'insurance_policy'?: UpdateInsurancePolicyPayload | null;
    /**
     * 
     * @type {UpdateAddressPayload}
     * @memberof UpdatePatientPayload
     */
    'mailing_address'?: UpdateAddressPayload | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientPayload
     */
    'stripe_customer_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientPayload
     */
    'stripe_payment_method_id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePatientPayload
     */
    'disallow_eligible_override'?: boolean | null;
}


/**
 * 
 * @export
 * @interface UpdateProviderAvailabilitySchedulePayload
 */
export interface UpdateProviderAvailabilitySchedulePayload {
    /**
     * 
     * @type {Array<UpdateProviderAvailabilityScheduleWindowPayload>}
     * @memberof UpdateProviderAvailabilitySchedulePayload
     */
    'windows': Array<UpdateProviderAvailabilityScheduleWindowPayload>;
}
/**
 * 
 * @export
 * @interface UpdateProviderAvailabilityScheduleWindowPayload
 */
export interface UpdateProviderAvailabilityScheduleWindowPayload {
    /**
     * 
     * @type {DayOfWeek}
     * @memberof UpdateProviderAvailabilityScheduleWindowPayload
     */
    'day_of_week': DayOfWeek;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderAvailabilityScheduleWindowPayload
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderAvailabilityScheduleWindowPayload
     */
    'end_time': string;
    /**
     * 
     * @type {ProviderAvailabilityType}
     * @memberof UpdateProviderAvailabilityScheduleWindowPayload
     */
    'availability_type'?: ProviderAvailabilityType;
}


/**
 * 
 * @export
 * @interface UpdateProviderConfigPayload
 */
export interface UpdateProviderConfigPayload {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProviderConfigPayload
     */
    'offers_free_consultations'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProviderConfigPayload
     */
    'fay_referrals_disabled'?: boolean | null;
}
/**
 * 
 * @export
 * @interface UpdateProviderPayload
 */
export interface UpdateProviderPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderPayload
     */
    'preferred_first_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderPayload
     */
    'first_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderPayload
     */
    'last_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderPayload
     */
    'email'?: string | null;
    /**
     * 
     * @type {Sex}
     * @memberof UpdateProviderPayload
     */
    'sex'?: Sex | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderPayload
     */
    'pronouns'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderPayload
     */
    'phone_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderPayload
     */
    'timezone'?: string | null;
    /**
     * 
     * @type {UpdateProviderConfigPayload}
     * @memberof UpdateProviderPayload
     */
    'provider_config'?: UpdateProviderConfigPayload | null;
    /**
     * 
     * @type {UpdateWebPresencePayload}
     * @memberof UpdateProviderPayload
     */
    'web_presence'?: UpdateWebPresencePayload | null;
}


/**
 * 
 * @export
 * @interface UpdateRecipePayload
 */
export interface UpdateRecipePayload {
    /**
     * The name of the recipe
     * @type {string}
     * @memberof UpdateRecipePayload
     */
    'name': string;
    /**
     * The ingredients of the recipe
     * @type {Array<string>}
     * @memberof UpdateRecipePayload
     */
    'ingredients': Array<string>;
    /**
     * The steps of the recipe
     * @type {Array<string>}
     * @memberof UpdateRecipePayload
     */
    'steps': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateRecurringDonationPayload
 */
export interface UpdateRecurringDonationPayload {
    /**
     * 
     * @type {Amount}
     * @memberof UpdateRecurringDonationPayload
     */
    'amount': Amount;
}
/**
 * 
 * @export
 * @interface UpdateWebPresencePayload
 */
export interface UpdateWebPresencePayload {
    /**
     * 
     * @type {Instagram1}
     * @memberof UpdateWebPresencePayload
     */
    'instagram'?: Instagram1 | null;
    /**
     * 
     * @type {Tiktok1}
     * @memberof UpdateWebPresencePayload
     */
    'tiktok'?: Tiktok1 | null;
    /**
     * 
     * @type {Website1}
     * @memberof UpdateWebPresencePayload
     */
    'website'?: Website1 | null;
}
/**
 * 
 * @export
 * @interface UploadPatientLabPayload
 */
export interface UploadPatientLabPayload {
    /**
     * 
     * @type {string}
     * @memberof UploadPatientLabPayload
     */
    'document_s3_key': string;
    /**
     * 
     * @type {string}
     * @memberof UploadPatientLabPayload
     */
    'intended_provider_id': string;
}
/**
 * Defines the roles that a user can have in order to determine their permissions.  For now, we only have roles for admin providers, but we are likely to add additional roles as the system evolves. As we migrate towards role-based permissions, note that permissions may be implicitly granted (by default) to admin users.
 * @export
 * @enum {string}
 */

export const UserRole = {
    BillingOperations: 'billing_operations'
} as const;

export type UserRole = typeof UserRole[keyof typeof UserRole];


/**
 * 
 * @export
 * @enum {string}
 */

export const UserType = {
    Provider: 'provider',
    Patient: 'patient',
    Machine: 'machine'
} as const;

export type UserType = typeof UserType[keyof typeof UserType];


/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorItem
 */
export interface ValidationErrorItem {
    /**
     * 
     * @type {string}
     * @memberof ValidationErrorItem
     */
    'input': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ValidationErrorItem
     */
    'loc': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ValidationErrorItem
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationErrorItem
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}
/**
 * 
 * @export
 * @interface ValidationErrorResponse
 */
export interface ValidationErrorResponse {
    /**
     * 
     * @type {Array<ValidationErrorItem>}
     * @memberof ValidationErrorResponse
     */
    'detail': Array<ValidationErrorItem>;
}
/**
 * 
 * @export
 * @interface Value
 */
export interface Value {
}
/**
 * 
 * @export
 * @interface VerifyPaymentMethodPayload
 */
export interface VerifyPaymentMethodPayload {
    /**
     * 
     * @type {string}
     * @memberof VerifyPaymentMethodPayload
     */
    'stripe_token': string;
    /**
     * 
     * @type {CardSource}
     * @memberof VerifyPaymentMethodPayload
     */
    'card_source': CardSource;
    /**
     * 
     * @type {string}
     * @memberof VerifyPaymentMethodPayload
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyPaymentMethodPayload
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyPaymentMethodPayload
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyPaymentMethodPayload
     */
    'stripe_customer_id'?: string | null;
}


/**
 * 
 * @export
 * @interface VerifyPaymentMethodResponse
 */
export interface VerifyPaymentMethodResponse {
    /**
     * 
     * @type {string}
     * @memberof VerifyPaymentMethodResponse
     */
    'stripe_customer_id': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyPaymentMethodResponse
     */
    'payment_method_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof VerifyPaymentMethodResponse
     */
    'error'?: string | null;
}
/**
 * 
 * @export
 * @interface VerifyTokenPayload
 */
export interface VerifyTokenPayload {
    /**
     * 
     * @type {string}
     * @memberof VerifyTokenPayload
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface VerifyTokenResponse
 */
export interface VerifyTokenResponse {
    /**
     * 
     * @type {boolean}
     * @memberof VerifyTokenResponse
     */
    'is_valid': boolean;
}
/**
 * 
 * @export
 * @interface Website
 */
export interface Website {
}
/**
 * 
 * @export
 * @interface Website1
 */
export interface Website1 {
}
/**
 * 
 * @export
 * @interface WeeklyStreakDisplayResponse
 */
export interface WeeklyStreakDisplayResponse {
    /**
     * The day of the week for this streak display item.
     * @type {string}
     * @memberof WeeklyStreakDisplayResponse
     */
    'label': string;
    /**
     * Indicates whether this day is part of the current streak.
     * @type {boolean}
     * @memberof WeeklyStreakDisplayResponse
     */
    'filled': boolean;
    /**
     * Indicates whether this day should be animated when displayed.
     * @type {boolean}
     * @memberof WeeklyStreakDisplayResponse
     */
    'animate_in': boolean;
}
/**
 * 
 * @export
 * @interface Weight
 */
export interface Weight {
}
/**
 * 
 * @export
 * @interface WeightFieldInput
 */
export interface WeightFieldInput {
    /**
     * 
     * @type {WeightUnits}
     * @memberof WeightFieldInput
     */
    'unit': WeightUnits;
    /**
     * 
     * @type {Value}
     * @memberof WeightFieldInput
     */
    'value': Value;
}


/**
 * 
 * @export
 * @interface WeightFieldOutput
 */
export interface WeightFieldOutput {
    /**
     * 
     * @type {WeightUnits}
     * @memberof WeightFieldOutput
     */
    'unit': WeightUnits;
    /**
     * 
     * @type {string}
     * @memberof WeightFieldOutput
     */
    'value': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const WeightUnits = {
    Pounds: 'pounds',
    Kilograms: 'kilograms'
} as const;

export type WeightUnits = typeof WeightUnits[keyof typeof WeightUnits];


/**
 * 
 * @export
 * @interface ZocdocAppointment
 */
export interface ZocdocAppointment {
    /**
     * 
     * @type {string}
     * @memberof ZocdocAppointment
     */
    'appointment_id': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocAppointment
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocAppointment
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocAppointment
     */
    'start_time': string;
    /**
     * 
     * @type {number}
     * @memberof ZocdocAppointment
     */
    'duration': number;
    /**
     * 
     * @type {ZocdocAppointmentType}
     * @memberof ZocdocAppointment
     */
    'visit_reason_id': ZocdocAppointmentType;
    /**
     * 
     * @type {string}
     * @memberof ZocdocAppointment
     */
    'location_id': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocAppointment
     */
    'appointment_status_id': string;
}


/**
 * 
 * @export
 * @interface ZocdocAppointmentCancelResponse
 */
export interface ZocdocAppointmentCancelResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ZocdocAppointmentCancelResponse
     */
    'successful': boolean;
    /**
     * 
     * @type {string}
     * @memberof ZocdocAppointmentCancelResponse
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface ZocdocAppointmentIdPayload
 */
export interface ZocdocAppointmentIdPayload {
    /**
     * 
     * @type {string}
     * @memberof ZocdocAppointmentIdPayload
     */
    'appointment_id': string;
}
/**
 * 
 * @export
 * @interface ZocdocAppointmentStatus
 */
export interface ZocdocAppointmentStatus {
    /**
     * 
     * @type {string}
     * @memberof ZocdocAppointmentStatus
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocAppointmentStatus
     */
    'name': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ZocdocAppointmentType = {
    _148145: '148145'
} as const;

export type ZocdocAppointmentType = typeof ZocdocAppointmentType[keyof typeof ZocdocAppointmentType];


/**
 * 
 * @export
 * @interface ZocdocAvailabilitySlot
 */
export interface ZocdocAvailabilitySlot {
    /**
     * 
     * @type {string}
     * @memberof ZocdocAvailabilitySlot
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocAvailabilitySlot
     */
    'end_time': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocAvailabilitySlot
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocAvailabilitySlot
     */
    'location_id'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof ZocdocAvailabilitySlot
     */
    'available_slot_types'?: Array<any>;
}
/**
 * 
 * @export
 * @interface ZocdocCreateAppointmentPayload
 */
export interface ZocdocCreateAppointmentPayload {
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreateAppointmentPayload
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreateAppointmentPayload
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreateAppointmentPayload
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreateAppointmentPayload
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreateAppointmentPayload
     */
    'patient_address1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreateAppointmentPayload
     */
    'patient_address2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreateAppointmentPayload
     */
    'patient_city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreateAppointmentPayload
     */
    'patient_state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreateAppointmentPayload
     */
    'patient_zip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreateAppointmentPayload
     */
    'insurance_carrier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreateAppointmentPayload
     */
    'insurance_plan'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreateAppointmentPayload
     */
    'insurance_member_id'?: string | null;
}
/**
 * 
 * @export
 * @interface ZocdocCreateAppointmentResponse
 */
export interface ZocdocCreateAppointmentResponse {
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreateAppointmentResponse
     */
    'appointment_id': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ZocdocCreateAppointmentResponse
     */
    'successful': boolean;
}
/**
 * 
 * @export
 * @interface ZocdocCreatePatientPayload
 */
export interface ZocdocCreatePatientPayload {
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreatePatientPayload
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreatePatientPayload
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreatePatientPayload
     */
    'date_of_birth': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreatePatientPayload
     */
    'email_address': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreatePatientPayload
     */
    'phone_number': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreatePatientPayload
     */
    'provider_id'?: string | null;
    /**
     * 
     * @type {ZocdocGender}
     * @memberof ZocdocCreatePatientPayload
     */
    'gender': ZocdocGender;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreatePatientPayload
     */
    'insurance_carrier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreatePatientPayload
     */
    'insurance_plan'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreatePatientPayload
     */
    'insurance_member_id'?: string | null;
}


/**
 * 
 * @export
 * @interface ZocdocCreatePatientResponse
 */
export interface ZocdocCreatePatientResponse {
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreatePatientResponse
     */
    'patient_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreatePatientResponse
     */
    'provider_id': string | null;
}
/**
 * This is a reductive and incorrect representation of gender but it\'s what the spec requires.
 * @export
 * @enum {string}
 */

export const ZocdocGender = {
    M: 'M',
    F: 'F'
} as const;

export type ZocdocGender = typeof ZocdocGender[keyof typeof ZocdocGender];


/**
 * 
 * @export
 * @interface ZocdocPatient
 */
export interface ZocdocPatient {
    /**
     * 
     * @type {string}
     * @memberof ZocdocPatient
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocPatient
     */
    'provider_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof ZocdocPatient
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocPatient
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocPatient
     */
    'date_of_birth': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocPatient
     */
    'email_address': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocPatient
     */
    'phone_number': string | null;
    /**
     * 
     * @type {ZocdocGender}
     * @memberof ZocdocPatient
     */
    'gender'?: ZocdocGender | null;
}


/**
 * 
 * @export
 * @interface ZocdocPatientSearchPayload
 */
export interface ZocdocPatientSearchPayload {
    /**
     * 
     * @type {string}
     * @memberof ZocdocPatientSearchPayload
     */
    'email_address': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocPatientSearchPayload
     */
    'phone_number'?: string | null;
}
/**
 * 
 * @export
 * @interface ZocdocProvider
 */
export interface ZocdocProvider {
    /**
     * 
     * @type {string}
     * @memberof ZocdocProvider
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocProvider
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ZocdocVisitReason
 */
export interface ZocdocVisitReason {
    /**
     * 
     * @type {ZocdocAppointmentType}
     * @memberof ZocdocVisitReason
     */
    'id': ZocdocAppointmentType;
    /**
     * 
     * @type {string}
     * @memberof ZocdocVisitReason
     */
    'name': string;
}



/**
 * AIFeedbackApi - axios parameter creator
 * @export
 */
export const AIFeedbackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update Chat Completion Feedback
         * @param {string} chatCompletionId 
         * @param {UpdateChatCompletionFeedbackPayload} updateChatCompletionFeedbackPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChatCompletionFeedbackAiFeedbackChatCompletionChatCompletionIdPut: async (chatCompletionId: string, updateChatCompletionFeedbackPayload: UpdateChatCompletionFeedbackPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatCompletionId' is not null or undefined
            assertParamExists('updateChatCompletionFeedbackAiFeedbackChatCompletionChatCompletionIdPut', 'chatCompletionId', chatCompletionId)
            // verify required parameter 'updateChatCompletionFeedbackPayload' is not null or undefined
            assertParamExists('updateChatCompletionFeedbackAiFeedbackChatCompletionChatCompletionIdPut', 'updateChatCompletionFeedbackPayload', updateChatCompletionFeedbackPayload)
            const localVarPath = `/ai-feedback/chat-completion/{chat_completion_id}`
                .replace(`{${"chat_completion_id"}}`, encodeURIComponent(String(chatCompletionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateChatCompletionFeedbackPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AIFeedbackApi - functional programming interface
 * @export
 */
export const AIFeedbackApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AIFeedbackApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update Chat Completion Feedback
         * @param {string} chatCompletionId 
         * @param {UpdateChatCompletionFeedbackPayload} updateChatCompletionFeedbackPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChatCompletionFeedbackAiFeedbackChatCompletionChatCompletionIdPut(chatCompletionId: string, updateChatCompletionFeedbackPayload: UpdateChatCompletionFeedbackPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateChatCompletionFeedbackAiFeedbackChatCompletionChatCompletionIdPut(chatCompletionId, updateChatCompletionFeedbackPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIFeedbackApi.updateChatCompletionFeedbackAiFeedbackChatCompletionChatCompletionIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AIFeedbackApi - factory interface
 * @export
 */
export const AIFeedbackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AIFeedbackApiFp(configuration)
    return {
        /**
         * 
         * @summary Update Chat Completion Feedback
         * @param {string} chatCompletionId 
         * @param {UpdateChatCompletionFeedbackPayload} updateChatCompletionFeedbackPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChatCompletionFeedbackAiFeedbackChatCompletionChatCompletionIdPut(chatCompletionId: string, updateChatCompletionFeedbackPayload: UpdateChatCompletionFeedbackPayload, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateChatCompletionFeedbackAiFeedbackChatCompletionChatCompletionIdPut(chatCompletionId, updateChatCompletionFeedbackPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AIFeedbackApi - object-oriented interface
 * @export
 * @class AIFeedbackApi
 * @extends {BaseAPI}
 */
export class AIFeedbackApi extends BaseAPI {
    /**
     * 
     * @summary Update Chat Completion Feedback
     * @param {string} chatCompletionId 
     * @param {UpdateChatCompletionFeedbackPayload} updateChatCompletionFeedbackPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIFeedbackApi
     */
    public updateChatCompletionFeedbackAiFeedbackChatCompletionChatCompletionIdPut(chatCompletionId: string, updateChatCompletionFeedbackPayload: UpdateChatCompletionFeedbackPayload, options?: RawAxiosRequestConfig) {
        return AIFeedbackApiFp(this.configuration).updateChatCompletionFeedbackAiFeedbackChatCompletionChatCompletionIdPut(chatCompletionId, updateChatCompletionFeedbackPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AppReviewApi - axios parameter creator
 * @export
 */
export const AppReviewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Post Review
         * @param {AppReviewPayload} appReviewPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReviewAppReviewPost: async (appReviewPayload: AppReviewPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appReviewPayload' is not null or undefined
            assertParamExists('postReviewAppReviewPost', 'appReviewPayload', appReviewPayload)
            const localVarPath = `/app-review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appReviewPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppReviewApi - functional programming interface
 * @export
 */
export const AppReviewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppReviewApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Post Review
         * @param {AppReviewPayload} appReviewPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReviewAppReviewPost(appReviewPayload: AppReviewPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppReviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReviewAppReviewPost(appReviewPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppReviewApi.postReviewAppReviewPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AppReviewApi - factory interface
 * @export
 */
export const AppReviewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppReviewApiFp(configuration)
    return {
        /**
         * 
         * @summary Post Review
         * @param {AppReviewPayload} appReviewPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReviewAppReviewPost(appReviewPayload: AppReviewPayload, options?: RawAxiosRequestConfig): AxiosPromise<AppReviewResponse> {
            return localVarFp.postReviewAppReviewPost(appReviewPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppReviewApi - object-oriented interface
 * @export
 * @class AppReviewApi
 * @extends {BaseAPI}
 */
export class AppReviewApi extends BaseAPI {
    /**
     * 
     * @summary Post Review
     * @param {AppReviewPayload} appReviewPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppReviewApi
     */
    public postReviewAppReviewPost(appReviewPayload: AppReviewPayload, options?: RawAxiosRequestConfig) {
        return AppReviewApiFp(this.configuration).postReviewAppReviewPost(appReviewPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AppointmentApi - axios parameter creator
 * @export
 */
export const AppointmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Book Appointment
         * @param {BookAppointmentPayload} bookAppointmentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookAppointmentAppointmentsPost: async (bookAppointmentPayload: BookAppointmentPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookAppointmentPayload' is not null or undefined
            assertParamExists('bookAppointmentAppointmentsPost', 'bookAppointmentPayload', bookAppointmentPayload)
            const localVarPath = `/appointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookAppointmentPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel Appointment
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAppointmentAppointmentAppointmentIdCancelPost: async (appointmentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('cancelAppointmentAppointmentAppointmentIdCancelPost', 'appointmentId', appointmentId)
            const localVarPath = `/appointment/{appointment_id}/cancel`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Calendar Block
         * @param {CreateCalendarBlockPayload} createCalendarBlockPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCalendarBlockCalendarEventsBlocksPost: async (createCalendarBlockPayload: CreateCalendarBlockPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCalendarBlockPayload' is not null or undefined
            assertParamExists('createCalendarBlockCalendarEventsBlocksPost', 'createCalendarBlockPayload', createCalendarBlockPayload)
            const localVarPath = `/calendar-events/blocks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCalendarBlockPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Calendar Event
         * @param {string} calendarEventId 
         * @param {boolean} withSubsequentEventsInRecurrenceSeries If true, all events after this event in the recurrence series will also be deleted. When false, only this event is deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendarEventCalendarEventsCalendarEventIdDelete: async (calendarEventId: string, withSubsequentEventsInRecurrenceSeries: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarEventId' is not null or undefined
            assertParamExists('deleteCalendarEventCalendarEventsCalendarEventIdDelete', 'calendarEventId', calendarEventId)
            // verify required parameter 'withSubsequentEventsInRecurrenceSeries' is not null or undefined
            assertParamExists('deleteCalendarEventCalendarEventsCalendarEventIdDelete', 'withSubsequentEventsInRecurrenceSeries', withSubsequentEventsInRecurrenceSeries)
            const localVarPath = `/calendar-events/{calendar_event_id}`
                .replace(`{${"calendar_event_id"}}`, encodeURIComponent(String(calendarEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (withSubsequentEventsInRecurrenceSeries !== undefined) {
                localVarQueryParameter['with_subsequent_events_in_recurrence_series'] = withSubsequentEventsInRecurrenceSeries;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint is authenticated by either an appointment-based JWT token or a Cognito user.
         * @summary Get Appointment Add To Calendar Details
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentAddToCalendarDetailsAppointmentsAppointmentIdCalendarDetailsGet: async (appointmentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('getAppointmentAddToCalendarDetailsAppointmentsAppointmentIdCalendarDetailsGet', 'appointmentId', appointmentId)
            const localVarPath = `/appointments/{appointment_id}/calendar-details`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Appointment
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentAppointmentsAppointmentIdGet: async (appointmentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('getAppointmentAppointmentsAppointmentIdGet', 'appointmentId', appointmentId)
            const localVarPath = `/appointments/{appointment_id}`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Appointments
         * @param {string | null} [providerId] 
         * @param {string | null} [patientId] 
         * @param {string | null} [before] 
         * @param {string | null} [after] 
         * @param {string | null} [startBefore] 
         * @param {string | null} [startAfter] 
         * @param {string | null} [endBefore] 
         * @param {string | null} [endAfter] 
         * @param {AllowedSortParam | null} [sort] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentsAppointmentsGet: async (providerId?: string | null, patientId?: string | null, before?: string | null, after?: string | null, startBefore?: string | null, startAfter?: string | null, endBefore?: string | null, endAfter?: string | null, sort?: AllowedSortParam | null, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/appointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (providerId !== undefined) {
                localVarQueryParameter['provider_id'] = providerId;
            }

            if (patientId !== undefined) {
                localVarQueryParameter['patient_id'] = patientId;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = (before as any instanceof Date) ?
                    (before as any).toISOString() :
                    before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = (after as any instanceof Date) ?
                    (after as any).toISOString() :
                    after;
            }

            if (startBefore !== undefined) {
                localVarQueryParameter['start_before'] = (startBefore as any instanceof Date) ?
                    (startBefore as any).toISOString() :
                    startBefore;
            }

            if (startAfter !== undefined) {
                localVarQueryParameter['start_after'] = (startAfter as any instanceof Date) ?
                    (startAfter as any).toISOString() :
                    startAfter;
            }

            if (endBefore !== undefined) {
                localVarQueryParameter['end_before'] = (endBefore as any instanceof Date) ?
                    (endBefore as any).toISOString() :
                    endBefore;
            }

            if (endAfter !== undefined) {
                localVarQueryParameter['end_after'] = (endAfter as any instanceof Date) ?
                    (endAfter as any).toISOString() :
                    endAfter;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Calendar Event
         * @param {string} calendarEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEventCalendarEventsCalendarEventIdGet: async (calendarEventId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarEventId' is not null or undefined
            assertParamExists('getCalendarEventCalendarEventsCalendarEventIdGet', 'calendarEventId', calendarEventId)
            const localVarPath = `/calendar-events/{calendar_event_id}`
                .replace(`{${"calendar_event_id"}}`, encodeURIComponent(String(calendarEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Calendar Events
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEventsCalendarEventsGet: async (startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getCalendarEventsCalendarEventsGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getCalendarEventsCalendarEventsGet', 'endTime', endTime)
            const localVarPath = `/calendar-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Provider Availability Schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderAvailabilityScheduleAvailabilityScheduleGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/availability-schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of available slots for the given provider between the given start and end times. The slots returned are the start times of the available slots in 15 minute increments.
         * @summary Get Provider Available Slots
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {number} appointmentDurationInMinutes 
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderAvailableSlotsAvailabilitySlotsGet: async (startTime: string, endTime: string, appointmentDurationInMinutes: number, providerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getProviderAvailableSlotsAvailabilitySlotsGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getProviderAvailableSlotsAvailabilitySlotsGet', 'endTime', endTime)
            // verify required parameter 'appointmentDurationInMinutes' is not null or undefined
            assertParamExists('getProviderAvailableSlotsAvailabilitySlotsGet', 'appointmentDurationInMinutes', appointmentDurationInMinutes)
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getProviderAvailableSlotsAvailabilitySlotsGet', 'providerId', providerId)
            const localVarPath = `/availability-slots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (appointmentDurationInMinutes !== undefined) {
                localVarQueryParameter['appointment_duration_in_minutes'] = appointmentDurationInMinutes;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['provider_id'] = providerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Provider Availability Schedule
         * @param {UpdateProviderAvailabilitySchedulePayload} updateProviderAvailabilitySchedulePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProviderAvailabilityScheduleAvailabilitySchedulePost: async (updateProviderAvailabilitySchedulePayload: UpdateProviderAvailabilitySchedulePayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateProviderAvailabilitySchedulePayload' is not null or undefined
            assertParamExists('setProviderAvailabilityScheduleAvailabilitySchedulePost', 'updateProviderAvailabilitySchedulePayload', updateProviderAvailabilitySchedulePayload)
            const localVarPath = `/availability-schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProviderAvailabilitySchedulePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Setup Patient And Book Appointment
         * @param {SetupPatientAndBookAppointmentPayload} setupPatientAndBookAppointmentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupPatientAndBookAppointmentAppointmentsSetupPatientPost: async (setupPatientAndBookAppointmentPayload: SetupPatientAndBookAppointmentPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setupPatientAndBookAppointmentPayload' is not null or undefined
            assertParamExists('setupPatientAndBookAppointmentAppointmentsSetupPatientPost', 'setupPatientAndBookAppointmentPayload', setupPatientAndBookAppointmentPayload)
            const localVarPath = `/appointments/setup-patient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setupPatientAndBookAppointmentPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Appointment
         * @param {string} appointmentId 
         * @param {UpdateAppointmentPayload} updateAppointmentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppointmentAppointmentsAppointmentIdPatch: async (appointmentId: string, updateAppointmentPayload: UpdateAppointmentPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('updateAppointmentAppointmentsAppointmentIdPatch', 'appointmentId', appointmentId)
            // verify required parameter 'updateAppointmentPayload' is not null or undefined
            assertParamExists('updateAppointmentAppointmentsAppointmentIdPatch', 'updateAppointmentPayload', updateAppointmentPayload)
            const localVarPath = `/appointments/{appointment_id}`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAppointmentPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Calendar Event
         * @param {string} calendarEventId 
         * @param {UpdateCalendarEventPayload} updateCalendarEventPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalendarEventCalendarEventsCalendarEventIdPut: async (calendarEventId: string, updateCalendarEventPayload: UpdateCalendarEventPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarEventId' is not null or undefined
            assertParamExists('updateCalendarEventCalendarEventsCalendarEventIdPut', 'calendarEventId', calendarEventId)
            // verify required parameter 'updateCalendarEventPayload' is not null or undefined
            assertParamExists('updateCalendarEventCalendarEventsCalendarEventIdPut', 'updateCalendarEventPayload', updateCalendarEventPayload)
            const localVarPath = `/calendar-events/{calendar_event_id}`
                .replace(`{${"calendar_event_id"}}`, encodeURIComponent(String(calendarEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCalendarEventPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppointmentApi - functional programming interface
 * @export
 */
export const AppointmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppointmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Book Appointment
         * @param {BookAppointmentPayload} bookAppointmentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookAppointmentAppointmentsPost(bookAppointmentPayload: BookAppointmentPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookAppointmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookAppointmentAppointmentsPost(bookAppointmentPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentApi.bookAppointmentAppointmentsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Cancel Appointment
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelAppointmentAppointmentAppointmentIdCancelPost(appointmentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentCanceledResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelAppointmentAppointmentAppointmentIdCancelPost(appointmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentApi.cancelAppointmentAppointmentAppointmentIdCancelPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Calendar Block
         * @param {CreateCalendarBlockPayload} createCalendarBlockPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCalendarBlockCalendarEventsBlocksPost(createCalendarBlockPayload: CreateCalendarBlockPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCalendarBlockCalendarEventsBlocksPost(createCalendarBlockPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentApi.createCalendarBlockCalendarEventsBlocksPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Calendar Event
         * @param {string} calendarEventId 
         * @param {boolean} withSubsequentEventsInRecurrenceSeries If true, all events after this event in the recurrence series will also be deleted. When false, only this event is deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCalendarEventCalendarEventsCalendarEventIdDelete(calendarEventId: string, withSubsequentEventsInRecurrenceSeries: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCalendarEventCalendarEventsCalendarEventIdDelete(calendarEventId, withSubsequentEventsInRecurrenceSeries, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentApi.deleteCalendarEventCalendarEventsCalendarEventIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This endpoint is authenticated by either an appointment-based JWT token or a Cognito user.
         * @summary Get Appointment Add To Calendar Details
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentAddToCalendarDetailsAppointmentsAppointmentIdCalendarDetailsGet(appointmentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentAddToCalendarDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentAddToCalendarDetailsAppointmentsAppointmentIdCalendarDetailsGet(appointmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentApi.getAppointmentAddToCalendarDetailsAppointmentsAppointmentIdCalendarDetailsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Appointment
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentAppointmentsAppointmentIdGet(appointmentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentAppointmentsAppointmentIdGet(appointmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentApi.getAppointmentAppointmentsAppointmentIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Appointments
         * @param {string | null} [providerId] 
         * @param {string | null} [patientId] 
         * @param {string | null} [before] 
         * @param {string | null} [after] 
         * @param {string | null} [startBefore] 
         * @param {string | null} [startAfter] 
         * @param {string | null} [endBefore] 
         * @param {string | null} [endAfter] 
         * @param {AllowedSortParam | null} [sort] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentsAppointmentsGet(providerId?: string | null, patientId?: string | null, before?: string | null, after?: string | null, startBefore?: string | null, startAfter?: string | null, endBefore?: string | null, endAfter?: string | null, sort?: AllowedSortParam | null, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppointmentResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentsAppointmentsGet(providerId, patientId, before, after, startBefore, startAfter, endBefore, endAfter, sort, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentApi.getAppointmentsAppointmentsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Calendar Event
         * @param {string} calendarEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarEventCalendarEventsCalendarEventIdGet(calendarEventId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarEventCalendarEventsCalendarEventIdGet(calendarEventId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentApi.getCalendarEventCalendarEventsCalendarEventIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Calendar Events
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarEventsCalendarEventsGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEventsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarEventsCalendarEventsGet(startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentApi.getCalendarEventsCalendarEventsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Provider Availability Schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderAvailabilityScheduleAvailabilityScheduleGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderAvailabilityScheduleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderAvailabilityScheduleAvailabilityScheduleGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentApi.getProviderAvailabilityScheduleAvailabilityScheduleGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a list of available slots for the given provider between the given start and end times. The slots returned are the start times of the available slots in 15 minute increments.
         * @summary Get Provider Available Slots
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {number} appointmentDurationInMinutes 
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderAvailableSlotsAvailabilitySlotsGet(startTime: string, endTime: string, appointmentDurationInMinutes: number, providerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailabilitySlotsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderAvailableSlotsAvailabilitySlotsGet(startTime, endTime, appointmentDurationInMinutes, providerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentApi.getProviderAvailableSlotsAvailabilitySlotsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Set Provider Availability Schedule
         * @param {UpdateProviderAvailabilitySchedulePayload} updateProviderAvailabilitySchedulePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setProviderAvailabilityScheduleAvailabilitySchedulePost(updateProviderAvailabilitySchedulePayload: UpdateProviderAvailabilitySchedulePayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderAvailabilityScheduleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setProviderAvailabilityScheduleAvailabilitySchedulePost(updateProviderAvailabilitySchedulePayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentApi.setProviderAvailabilityScheduleAvailabilitySchedulePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Setup Patient And Book Appointment
         * @param {SetupPatientAndBookAppointmentPayload} setupPatientAndBookAppointmentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setupPatientAndBookAppointmentAppointmentsSetupPatientPost(setupPatientAndBookAppointmentPayload: SetupPatientAndBookAppointmentPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetupPatientAndBookAppointmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setupPatientAndBookAppointmentAppointmentsSetupPatientPost(setupPatientAndBookAppointmentPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentApi.setupPatientAndBookAppointmentAppointmentsSetupPatientPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Appointment
         * @param {string} appointmentId 
         * @param {UpdateAppointmentPayload} updateAppointmentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAppointmentAppointmentsAppointmentIdPatch(appointmentId: string, updateAppointmentPayload: UpdateAppointmentPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAppointmentAppointmentsAppointmentIdPatch(appointmentId, updateAppointmentPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentApi.updateAppointmentAppointmentsAppointmentIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Calendar Event
         * @param {string} calendarEventId 
         * @param {UpdateCalendarEventPayload} updateCalendarEventPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCalendarEventCalendarEventsCalendarEventIdPut(calendarEventId: string, updateCalendarEventPayload: UpdateCalendarEventPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCalendarEventCalendarEventsCalendarEventIdPut(calendarEventId, updateCalendarEventPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentApi.updateCalendarEventCalendarEventsCalendarEventIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AppointmentApi - factory interface
 * @export
 */
export const AppointmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppointmentApiFp(configuration)
    return {
        /**
         * 
         * @summary Book Appointment
         * @param {BookAppointmentPayload} bookAppointmentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookAppointmentAppointmentsPost(bookAppointmentPayload: BookAppointmentPayload, options?: RawAxiosRequestConfig): AxiosPromise<BookAppointmentResponse> {
            return localVarFp.bookAppointmentAppointmentsPost(bookAppointmentPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel Appointment
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAppointmentAppointmentAppointmentIdCancelPost(appointmentId: string, options?: RawAxiosRequestConfig): AxiosPromise<AppointmentCanceledResponse> {
            return localVarFp.cancelAppointmentAppointmentAppointmentIdCancelPost(appointmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Calendar Block
         * @param {CreateCalendarBlockPayload} createCalendarBlockPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCalendarBlockCalendarEventsBlocksPost(createCalendarBlockPayload: CreateCalendarBlockPayload, options?: RawAxiosRequestConfig): AxiosPromise<CalendarEventResponse> {
            return localVarFp.createCalendarBlockCalendarEventsBlocksPost(createCalendarBlockPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Calendar Event
         * @param {string} calendarEventId 
         * @param {boolean} withSubsequentEventsInRecurrenceSeries If true, all events after this event in the recurrence series will also be deleted. When false, only this event is deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendarEventCalendarEventsCalendarEventIdDelete(calendarEventId: string, withSubsequentEventsInRecurrenceSeries: boolean, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteCalendarEventCalendarEventsCalendarEventIdDelete(calendarEventId, withSubsequentEventsInRecurrenceSeries, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint is authenticated by either an appointment-based JWT token or a Cognito user.
         * @summary Get Appointment Add To Calendar Details
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentAddToCalendarDetailsAppointmentsAppointmentIdCalendarDetailsGet(appointmentId: string, options?: RawAxiosRequestConfig): AxiosPromise<AppointmentAddToCalendarDetailsResponse> {
            return localVarFp.getAppointmentAddToCalendarDetailsAppointmentsAppointmentIdCalendarDetailsGet(appointmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Appointment
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentAppointmentsAppointmentIdGet(appointmentId: string, options?: RawAxiosRequestConfig): AxiosPromise<AppointmentResponse> {
            return localVarFp.getAppointmentAppointmentsAppointmentIdGet(appointmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Appointments
         * @param {string | null} [providerId] 
         * @param {string | null} [patientId] 
         * @param {string | null} [before] 
         * @param {string | null} [after] 
         * @param {string | null} [startBefore] 
         * @param {string | null} [startAfter] 
         * @param {string | null} [endBefore] 
         * @param {string | null} [endAfter] 
         * @param {AllowedSortParam | null} [sort] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentsAppointmentsGet(providerId?: string | null, patientId?: string | null, before?: string | null, after?: string | null, startBefore?: string | null, startAfter?: string | null, endBefore?: string | null, endAfter?: string | null, sort?: AllowedSortParam | null, limit?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<AppointmentResponse>> {
            return localVarFp.getAppointmentsAppointmentsGet(providerId, patientId, before, after, startBefore, startAfter, endBefore, endAfter, sort, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Calendar Event
         * @param {string} calendarEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEventCalendarEventsCalendarEventIdGet(calendarEventId: string, options?: RawAxiosRequestConfig): AxiosPromise<CalendarEventResponse> {
            return localVarFp.getCalendarEventCalendarEventsCalendarEventIdGet(calendarEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Calendar Events
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEventsCalendarEventsGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<CalendarEventsResponse> {
            return localVarFp.getCalendarEventsCalendarEventsGet(startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Provider Availability Schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderAvailabilityScheduleAvailabilityScheduleGet(options?: RawAxiosRequestConfig): AxiosPromise<ProviderAvailabilityScheduleResponse> {
            return localVarFp.getProviderAvailabilityScheduleAvailabilityScheduleGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of available slots for the given provider between the given start and end times. The slots returned are the start times of the available slots in 15 minute increments.
         * @summary Get Provider Available Slots
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {number} appointmentDurationInMinutes 
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderAvailableSlotsAvailabilitySlotsGet(startTime: string, endTime: string, appointmentDurationInMinutes: number, providerId: string, options?: RawAxiosRequestConfig): AxiosPromise<AvailabilitySlotsResponse> {
            return localVarFp.getProviderAvailableSlotsAvailabilitySlotsGet(startTime, endTime, appointmentDurationInMinutes, providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Provider Availability Schedule
         * @param {UpdateProviderAvailabilitySchedulePayload} updateProviderAvailabilitySchedulePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProviderAvailabilityScheduleAvailabilitySchedulePost(updateProviderAvailabilitySchedulePayload: UpdateProviderAvailabilitySchedulePayload, options?: RawAxiosRequestConfig): AxiosPromise<ProviderAvailabilityScheduleResponse> {
            return localVarFp.setProviderAvailabilityScheduleAvailabilitySchedulePost(updateProviderAvailabilitySchedulePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Setup Patient And Book Appointment
         * @param {SetupPatientAndBookAppointmentPayload} setupPatientAndBookAppointmentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupPatientAndBookAppointmentAppointmentsSetupPatientPost(setupPatientAndBookAppointmentPayload: SetupPatientAndBookAppointmentPayload, options?: RawAxiosRequestConfig): AxiosPromise<SetupPatientAndBookAppointmentResponse> {
            return localVarFp.setupPatientAndBookAppointmentAppointmentsSetupPatientPost(setupPatientAndBookAppointmentPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Appointment
         * @param {string} appointmentId 
         * @param {UpdateAppointmentPayload} updateAppointmentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppointmentAppointmentsAppointmentIdPatch(appointmentId: string, updateAppointmentPayload: UpdateAppointmentPayload, options?: RawAxiosRequestConfig): AxiosPromise<AppointmentResponse> {
            return localVarFp.updateAppointmentAppointmentsAppointmentIdPatch(appointmentId, updateAppointmentPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Calendar Event
         * @param {string} calendarEventId 
         * @param {UpdateCalendarEventPayload} updateCalendarEventPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalendarEventCalendarEventsCalendarEventIdPut(calendarEventId: string, updateCalendarEventPayload: UpdateCalendarEventPayload, options?: RawAxiosRequestConfig): AxiosPromise<CalendarEventResponse> {
            return localVarFp.updateCalendarEventCalendarEventsCalendarEventIdPut(calendarEventId, updateCalendarEventPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppointmentApi - object-oriented interface
 * @export
 * @class AppointmentApi
 * @extends {BaseAPI}
 */
export class AppointmentApi extends BaseAPI {
    /**
     * 
     * @summary Book Appointment
     * @param {BookAppointmentPayload} bookAppointmentPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public bookAppointmentAppointmentsPost(bookAppointmentPayload: BookAppointmentPayload, options?: RawAxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).bookAppointmentAppointmentsPost(bookAppointmentPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel Appointment
     * @param {string} appointmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public cancelAppointmentAppointmentAppointmentIdCancelPost(appointmentId: string, options?: RawAxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).cancelAppointmentAppointmentAppointmentIdCancelPost(appointmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Calendar Block
     * @param {CreateCalendarBlockPayload} createCalendarBlockPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public createCalendarBlockCalendarEventsBlocksPost(createCalendarBlockPayload: CreateCalendarBlockPayload, options?: RawAxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).createCalendarBlockCalendarEventsBlocksPost(createCalendarBlockPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Calendar Event
     * @param {string} calendarEventId 
     * @param {boolean} withSubsequentEventsInRecurrenceSeries If true, all events after this event in the recurrence series will also be deleted. When false, only this event is deleted.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public deleteCalendarEventCalendarEventsCalendarEventIdDelete(calendarEventId: string, withSubsequentEventsInRecurrenceSeries: boolean, options?: RawAxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).deleteCalendarEventCalendarEventsCalendarEventIdDelete(calendarEventId, withSubsequentEventsInRecurrenceSeries, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint is authenticated by either an appointment-based JWT token or a Cognito user.
     * @summary Get Appointment Add To Calendar Details
     * @param {string} appointmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getAppointmentAddToCalendarDetailsAppointmentsAppointmentIdCalendarDetailsGet(appointmentId: string, options?: RawAxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getAppointmentAddToCalendarDetailsAppointmentsAppointmentIdCalendarDetailsGet(appointmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Appointment
     * @param {string} appointmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getAppointmentAppointmentsAppointmentIdGet(appointmentId: string, options?: RawAxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getAppointmentAppointmentsAppointmentIdGet(appointmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Appointments
     * @param {string | null} [providerId] 
     * @param {string | null} [patientId] 
     * @param {string | null} [before] 
     * @param {string | null} [after] 
     * @param {string | null} [startBefore] 
     * @param {string | null} [startAfter] 
     * @param {string | null} [endBefore] 
     * @param {string | null} [endAfter] 
     * @param {AllowedSortParam | null} [sort] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getAppointmentsAppointmentsGet(providerId?: string | null, patientId?: string | null, before?: string | null, after?: string | null, startBefore?: string | null, startAfter?: string | null, endBefore?: string | null, endAfter?: string | null, sort?: AllowedSortParam | null, limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getAppointmentsAppointmentsGet(providerId, patientId, before, after, startBefore, startAfter, endBefore, endAfter, sort, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Calendar Event
     * @param {string} calendarEventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getCalendarEventCalendarEventsCalendarEventIdGet(calendarEventId: string, options?: RawAxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getCalendarEventCalendarEventsCalendarEventIdGet(calendarEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Calendar Events
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getCalendarEventsCalendarEventsGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getCalendarEventsCalendarEventsGet(startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Provider Availability Schedule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getProviderAvailabilityScheduleAvailabilityScheduleGet(options?: RawAxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getProviderAvailabilityScheduleAvailabilityScheduleGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of available slots for the given provider between the given start and end times. The slots returned are the start times of the available slots in 15 minute increments.
     * @summary Get Provider Available Slots
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {number} appointmentDurationInMinutes 
     * @param {string} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getProviderAvailableSlotsAvailabilitySlotsGet(startTime: string, endTime: string, appointmentDurationInMinutes: number, providerId: string, options?: RawAxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getProviderAvailableSlotsAvailabilitySlotsGet(startTime, endTime, appointmentDurationInMinutes, providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Provider Availability Schedule
     * @param {UpdateProviderAvailabilitySchedulePayload} updateProviderAvailabilitySchedulePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public setProviderAvailabilityScheduleAvailabilitySchedulePost(updateProviderAvailabilitySchedulePayload: UpdateProviderAvailabilitySchedulePayload, options?: RawAxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).setProviderAvailabilityScheduleAvailabilitySchedulePost(updateProviderAvailabilitySchedulePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Setup Patient And Book Appointment
     * @param {SetupPatientAndBookAppointmentPayload} setupPatientAndBookAppointmentPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public setupPatientAndBookAppointmentAppointmentsSetupPatientPost(setupPatientAndBookAppointmentPayload: SetupPatientAndBookAppointmentPayload, options?: RawAxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).setupPatientAndBookAppointmentAppointmentsSetupPatientPost(setupPatientAndBookAppointmentPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Appointment
     * @param {string} appointmentId 
     * @param {UpdateAppointmentPayload} updateAppointmentPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public updateAppointmentAppointmentsAppointmentIdPatch(appointmentId: string, updateAppointmentPayload: UpdateAppointmentPayload, options?: RawAxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).updateAppointmentAppointmentsAppointmentIdPatch(appointmentId, updateAppointmentPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Calendar Event
     * @param {string} calendarEventId 
     * @param {UpdateCalendarEventPayload} updateCalendarEventPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public updateCalendarEventCalendarEventsCalendarEventIdPut(calendarEventId: string, updateCalendarEventPayload: UpdateCalendarEventPayload, options?: RawAxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).updateCalendarEventCalendarEventsCalendarEventIdPut(calendarEventId, updateCalendarEventPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Begin Update Identity
         * @param {InitiateUpdateIdentityPayload} initiateUpdateIdentityPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beginUpdateIdentityAuthUpdateIdentityPost: async (initiateUpdateIdentityPayload: InitiateUpdateIdentityPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'initiateUpdateIdentityPayload' is not null or undefined
            assertParamExists('beginUpdateIdentityAuthUpdateIdentityPost', 'initiateUpdateIdentityPayload', initiateUpdateIdentityPayload)
            const localVarPath = `/auth/update-identity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initiateUpdateIdentityPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Can Patient Verify Phone Number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canPatientVerifyPhoneNumberAuthIdentityCanVerifyPhoneNumberGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/identity/can-verify-phone-number`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete Login
         * @param {CompleteLoginChallengePayload} completeLoginChallengePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeLoginAuthLoginCompletePost: async (completeLoginChallengePayload: CompleteLoginChallengePayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'completeLoginChallengePayload' is not null or undefined
            assertParamExists('completeLoginAuthLoginCompletePost', 'completeLoginChallengePayload', completeLoginChallengePayload)
            const localVarPath = `/auth/login/complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeLoginChallengePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete Reset Password
         * @param {CompleteResetPasswordPayload} completeResetPasswordPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeResetPasswordAuthResetPasswordCompletePost: async (completeResetPasswordPayload: CompleteResetPasswordPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'completeResetPasswordPayload' is not null or undefined
            assertParamExists('completeResetPasswordAuthResetPasswordCompletePost', 'completeResetPasswordPayload', completeResetPasswordPayload)
            const localVarPath = `/auth/reset-password/complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeResetPasswordPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete Update Identity
         * @param {CompleteLoginChallengePayload} completeLoginChallengePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeUpdateIdentityAuthUpdateIdentityCompletePost: async (completeLoginChallengePayload: CompleteLoginChallengePayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'completeLoginChallengePayload' is not null or undefined
            assertParamExists('completeUpdateIdentityAuthUpdateIdentityCompletePost', 'completeLoginChallengePayload', completeLoginChallengePayload)
            const localVarPath = `/auth/update-identity/complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeLoginChallengePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login
         * @param {BeginPhoneLoginChallengePayload} beginPhoneLoginChallengePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAuthLoginPost: async (beginPhoneLoginChallengePayload: BeginPhoneLoginChallengePayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'beginPhoneLoginChallengePayload' is not null or undefined
            assertParamExists('loginAuthLoginPost', 'beginPhoneLoginChallengePayload', beginPhoneLoginChallengePayload)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(beginPhoneLoginChallengePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset Password
         * @param {BeginResetPasswordPayload} beginResetPasswordPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordAuthResetPasswordPost: async (beginResetPasswordPayload: BeginResetPasswordPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'beginResetPasswordPayload' is not null or undefined
            assertParamExists('resetPasswordAuthResetPasswordPost', 'beginResetPasswordPayload', beginResetPasswordPayload)
            const localVarPath = `/auth/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(beginResetPasswordPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Begin Update Identity
         * @param {InitiateUpdateIdentityPayload} initiateUpdateIdentityPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beginUpdateIdentityAuthUpdateIdentityPost(initiateUpdateIdentityPayload: InitiateUpdateIdentityPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateIdentityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.beginUpdateIdentityAuthUpdateIdentityPost(initiateUpdateIdentityPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.beginUpdateIdentityAuthUpdateIdentityPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Can Patient Verify Phone Number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async canPatientVerifyPhoneNumberAuthIdentityCanVerifyPhoneNumberGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.canPatientVerifyPhoneNumberAuthIdentityCanVerifyPhoneNumberGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.canPatientVerifyPhoneNumberAuthIdentityCanVerifyPhoneNumberGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Complete Login
         * @param {CompleteLoginChallengePayload} completeLoginChallengePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeLoginAuthLoginCompletePost(completeLoginChallengePayload: CompleteLoginChallengePayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeLoginAuthLoginCompletePost(completeLoginChallengePayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.completeLoginAuthLoginCompletePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Complete Reset Password
         * @param {CompleteResetPasswordPayload} completeResetPasswordPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeResetPasswordAuthResetPasswordCompletePost(completeResetPasswordPayload: CompleteResetPasswordPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResetPasswordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeResetPasswordAuthResetPasswordCompletePost(completeResetPasswordPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.completeResetPasswordAuthResetPasswordCompletePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Complete Update Identity
         * @param {CompleteLoginChallengePayload} completeLoginChallengePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeUpdateIdentityAuthUpdateIdentityCompletePost(completeLoginChallengePayload: CompleteLoginChallengePayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateIdentityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeUpdateIdentityAuthUpdateIdentityCompletePost(completeLoginChallengePayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.completeUpdateIdentityAuthUpdateIdentityCompletePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Login
         * @param {BeginPhoneLoginChallengePayload} beginPhoneLoginChallengePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginAuthLoginPost(beginPhoneLoginChallengePayload: BeginPhoneLoginChallengePayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginAuthLoginPost(beginPhoneLoginChallengePayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.loginAuthLoginPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Reset Password
         * @param {BeginResetPasswordPayload} beginResetPasswordPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordAuthResetPasswordPost(beginResetPasswordPayload: BeginResetPasswordPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResetPasswordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordAuthResetPasswordPost(beginResetPasswordPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.resetPasswordAuthResetPasswordPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Begin Update Identity
         * @param {InitiateUpdateIdentityPayload} initiateUpdateIdentityPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beginUpdateIdentityAuthUpdateIdentityPost(initiateUpdateIdentityPayload: InitiateUpdateIdentityPayload, options?: RawAxiosRequestConfig): AxiosPromise<UpdateIdentityResponse> {
            return localVarFp.beginUpdateIdentityAuthUpdateIdentityPost(initiateUpdateIdentityPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Can Patient Verify Phone Number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canPatientVerifyPhoneNumberAuthIdentityCanVerifyPhoneNumberGet(options?: RawAxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.canPatientVerifyPhoneNumberAuthIdentityCanVerifyPhoneNumberGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete Login
         * @param {CompleteLoginChallengePayload} completeLoginChallengePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeLoginAuthLoginCompletePost(completeLoginChallengePayload: CompleteLoginChallengePayload, options?: RawAxiosRequestConfig): AxiosPromise<LoginResponse> {
            return localVarFp.completeLoginAuthLoginCompletePost(completeLoginChallengePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete Reset Password
         * @param {CompleteResetPasswordPayload} completeResetPasswordPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeResetPasswordAuthResetPasswordCompletePost(completeResetPasswordPayload: CompleteResetPasswordPayload, options?: RawAxiosRequestConfig): AxiosPromise<ResetPasswordResponse> {
            return localVarFp.completeResetPasswordAuthResetPasswordCompletePost(completeResetPasswordPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete Update Identity
         * @param {CompleteLoginChallengePayload} completeLoginChallengePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeUpdateIdentityAuthUpdateIdentityCompletePost(completeLoginChallengePayload: CompleteLoginChallengePayload, options?: RawAxiosRequestConfig): AxiosPromise<UpdateIdentityResponse> {
            return localVarFp.completeUpdateIdentityAuthUpdateIdentityCompletePost(completeLoginChallengePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login
         * @param {BeginPhoneLoginChallengePayload} beginPhoneLoginChallengePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAuthLoginPost(beginPhoneLoginChallengePayload: BeginPhoneLoginChallengePayload, options?: RawAxiosRequestConfig): AxiosPromise<LoginResponse> {
            return localVarFp.loginAuthLoginPost(beginPhoneLoginChallengePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset Password
         * @param {BeginResetPasswordPayload} beginResetPasswordPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordAuthResetPasswordPost(beginResetPasswordPayload: BeginResetPasswordPayload, options?: RawAxiosRequestConfig): AxiosPromise<ResetPasswordResponse> {
            return localVarFp.resetPasswordAuthResetPasswordPost(beginResetPasswordPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Begin Update Identity
     * @param {InitiateUpdateIdentityPayload} initiateUpdateIdentityPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public beginUpdateIdentityAuthUpdateIdentityPost(initiateUpdateIdentityPayload: InitiateUpdateIdentityPayload, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).beginUpdateIdentityAuthUpdateIdentityPost(initiateUpdateIdentityPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Can Patient Verify Phone Number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public canPatientVerifyPhoneNumberAuthIdentityCanVerifyPhoneNumberGet(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).canPatientVerifyPhoneNumberAuthIdentityCanVerifyPhoneNumberGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete Login
     * @param {CompleteLoginChallengePayload} completeLoginChallengePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public completeLoginAuthLoginCompletePost(completeLoginChallengePayload: CompleteLoginChallengePayload, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).completeLoginAuthLoginCompletePost(completeLoginChallengePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete Reset Password
     * @param {CompleteResetPasswordPayload} completeResetPasswordPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public completeResetPasswordAuthResetPasswordCompletePost(completeResetPasswordPayload: CompleteResetPasswordPayload, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).completeResetPasswordAuthResetPasswordCompletePost(completeResetPasswordPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete Update Identity
     * @param {CompleteLoginChallengePayload} completeLoginChallengePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public completeUpdateIdentityAuthUpdateIdentityCompletePost(completeLoginChallengePayload: CompleteLoginChallengePayload, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).completeUpdateIdentityAuthUpdateIdentityCompletePost(completeLoginChallengePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login
     * @param {BeginPhoneLoginChallengePayload} beginPhoneLoginChallengePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginAuthLoginPost(beginPhoneLoginChallengePayload: BeginPhoneLoginChallengePayload, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).loginAuthLoginPost(beginPhoneLoginChallengePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset Password
     * @param {BeginResetPasswordPayload} beginResetPasswordPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public resetPasswordAuthResetPasswordPost(beginResetPasswordPayload: BeginResetPasswordPayload, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).resetPasswordAuthResetPasswordPost(beginResetPasswordPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ChatApi - axios parameter creator
 * @export
 */
export const ChatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Fetches a chat channel for the current user.
         * @summary Get Chat Channel
         * @param {string} chatChannelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatChannelChatChannelsChatChannelIdGet: async (chatChannelId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatChannelId' is not null or undefined
            assertParamExists('getChatChannelChatChannelsChatChannelIdGet', 'chatChannelId', chatChannelId)
            const localVarPath = `/chat/channels/{chat_channel_id}`
                .replace(`{${"chat_channel_id"}}`, encodeURIComponent(String(chatChannelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sorted by sent_at descending (most recent first). If sent_after is provided, fetch messages sent after the provided time. If sent_before is provided, fetch messages sent before the provided time.  The earliest and latest sent_at times are returned for pagination.
         * @summary Get Chat Channel Messages
         * @param {string} chatChannelId 
         * @param {string | null} [sentAfter] 
         * @param {string | null} [sentBefore] 
         * @param {number} [limit] 
         * @param {SortOrdering} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatChannelMessagesChatChannelsChatChannelIdMessagesGet: async (chatChannelId: string, sentAfter?: string | null, sentBefore?: string | null, limit?: number, order?: SortOrdering, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatChannelId' is not null or undefined
            assertParamExists('getChatChannelMessagesChatChannelsChatChannelIdMessagesGet', 'chatChannelId', chatChannelId)
            const localVarPath = `/chat/channels/{chat_channel_id}/messages`
                .replace(`{${"chat_channel_id"}}`, encodeURIComponent(String(chatChannelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sentAfter !== undefined) {
                localVarQueryParameter['sent_after'] = (sentAfter as any instanceof Date) ?
                    (sentAfter as any).toISOString() :
                    sentAfter;
            }

            if (sentBefore !== undefined) {
                localVarQueryParameter['sent_before'] = (sentBefore as any instanceof Date) ?
                    (sentBefore as any).toISOString() :
                    sentBefore;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches a list of chat channels for the current user.  It is ordered by last activity time first (e.g. message sent), created at second. NOTE: this is not a stable ordering. An acceptable design tradeoff.  If provider_id is provided, filter the channels for a matching provider in the conversation participants. If patient_id is provided, filter the channels for a matching patient in the conversation participants. If updated_after is provided, fetch channels updated after the provided time. If updated_before is provided, fetch channels updated before the provided time.  If more results than the limit are available, the response provides a cursor to be used for pagination. - `latest_updated_at` is returned if no `updated_` or `updated_after` was set. - `earliest_updated_at` is returned if `updated_before` was set.
         * @summary Get Chat Channels
         * @param {string | null} [providerId] 
         * @param {string | null} [patientId] 
         * @param {string | null} [updatedAfter] 
         * @param {string | null} [updatedBefore] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatChannelsChatChannelsGet: async (providerId?: string | null, patientId?: string | null, updatedAfter?: string | null, updatedBefore?: string | null, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chat/channels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (providerId !== undefined) {
                localVarQueryParameter['provider_id'] = providerId;
            }

            if (patientId !== undefined) {
                localVarQueryParameter['patient_id'] = patientId;
            }

            if (updatedAfter !== undefined) {
                localVarQueryParameter['updated_after'] = (updatedAfter as any instanceof Date) ?
                    (updatedAfter as any).toISOString() :
                    updatedAfter;
            }

            if (updatedBefore !== undefined) {
                localVarQueryParameter['updated_before'] = (updatedBefore as any instanceof Date) ?
                    (updatedBefore as any).toISOString() :
                    updatedBefore;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches the count of unread chat channels for the current user.
         * @summary Get Chat Metadata Channels Unread
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatMetadataChannelsUnreadChatMetadataChannelsUnreadGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chat/metadata/channels/unread`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post Chat Channel Message
         * @param {string} chatChannelId 
         * @param {SendChatMessagePayload} sendChatMessagePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postChatChannelMessageChatChannelsChatChannelIdMessagesPost: async (chatChannelId: string, sendChatMessagePayload: SendChatMessagePayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatChannelId' is not null or undefined
            assertParamExists('postChatChannelMessageChatChannelsChatChannelIdMessagesPost', 'chatChannelId', chatChannelId)
            // verify required parameter 'sendChatMessagePayload' is not null or undefined
            assertParamExists('postChatChannelMessageChatChannelsChatChannelIdMessagesPost', 'sendChatMessagePayload', sendChatMessagePayload)
            const localVarPath = `/chat/channels/{chat_channel_id}/messages`
                .replace(`{${"chat_channel_id"}}`, encodeURIComponent(String(chatChannelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendChatMessagePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Marks all messages in the `chat_channel` read before `read_sent_at`.  Only marks messages for which the user was not the sender.
         * @summary Post Chat Channel Read
         * @param {string} chatChannelId 
         * @param {string} readSentAt 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postChatChannelReadChatChannelsChatChannelIdReadPost: async (chatChannelId: string, readSentAt: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatChannelId' is not null or undefined
            assertParamExists('postChatChannelReadChatChannelsChatChannelIdReadPost', 'chatChannelId', chatChannelId)
            // verify required parameter 'readSentAt' is not null or undefined
            assertParamExists('postChatChannelReadChatChannelsChatChannelIdReadPost', 'readSentAt', readSentAt)
            const localVarPath = `/chat/channels/{chat_channel_id}/read`
                .replace(`{${"chat_channel_id"}}`, encodeURIComponent(String(chatChannelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (readSentAt !== undefined) {
                localVarQueryParameter['read_sent_at'] = (readSentAt as any instanceof Date) ?
                    (readSentAt as any).toISOString() :
                    readSentAt;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Store Messages From Chime
         * @param {ChimeBridgePayload} chimeBridgePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeMessagesFromChimeChatChimeBridgePost: async (chimeBridgePayload: ChimeBridgePayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chimeBridgePayload' is not null or undefined
            assertParamExists('storeMessagesFromChimeChatChimeBridgePost', 'chimeBridgePayload', chimeBridgePayload)
            const localVarPath = `/chat/chime-bridge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chimeBridgePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Suggest Chat Response
         * @param {SuggestChatResponsePayload} suggestChatResponsePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestChatResponseChatSuggestionPost: async (suggestChatResponsePayload: SuggestChatResponsePayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'suggestChatResponsePayload' is not null or undefined
            assertParamExists('suggestChatResponseChatSuggestionPost', 'suggestChatResponsePayload', suggestChatResponsePayload)
            const localVarPath = `/chat/suggestion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(suggestChatResponsePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatApi - functional programming interface
 * @export
 */
export const ChatApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatApiAxiosParamCreator(configuration)
    return {
        /**
         * Fetches a chat channel for the current user.
         * @summary Get Chat Channel
         * @param {string} chatChannelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChatChannelChatChannelsChatChannelIdGet(chatChannelId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatChannelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChatChannelChatChannelsChatChannelIdGet(chatChannelId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatApi.getChatChannelChatChannelsChatChannelIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Sorted by sent_at descending (most recent first). If sent_after is provided, fetch messages sent after the provided time. If sent_before is provided, fetch messages sent before the provided time.  The earliest and latest sent_at times are returned for pagination.
         * @summary Get Chat Channel Messages
         * @param {string} chatChannelId 
         * @param {string | null} [sentAfter] 
         * @param {string | null} [sentBefore] 
         * @param {number} [limit] 
         * @param {SortOrdering} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChatChannelMessagesChatChannelsChatChannelIdMessagesGet(chatChannelId: string, sentAfter?: string | null, sentBefore?: string | null, limit?: number, order?: SortOrdering, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatMessagesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChatChannelMessagesChatChannelsChatChannelIdMessagesGet(chatChannelId, sentAfter, sentBefore, limit, order, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatApi.getChatChannelMessagesChatChannelsChatChannelIdMessagesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetches a list of chat channels for the current user.  It is ordered by last activity time first (e.g. message sent), created at second. NOTE: this is not a stable ordering. An acceptable design tradeoff.  If provider_id is provided, filter the channels for a matching provider in the conversation participants. If patient_id is provided, filter the channels for a matching patient in the conversation participants. If updated_after is provided, fetch channels updated after the provided time. If updated_before is provided, fetch channels updated before the provided time.  If more results than the limit are available, the response provides a cursor to be used for pagination. - `latest_updated_at` is returned if no `updated_` or `updated_after` was set. - `earliest_updated_at` is returned if `updated_before` was set.
         * @summary Get Chat Channels
         * @param {string | null} [providerId] 
         * @param {string | null} [patientId] 
         * @param {string | null} [updatedAfter] 
         * @param {string | null} [updatedBefore] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChatChannelsChatChannelsGet(providerId?: string | null, patientId?: string | null, updatedAfter?: string | null, updatedBefore?: string | null, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatChannelsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChatChannelsChatChannelsGet(providerId, patientId, updatedAfter, updatedBefore, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatApi.getChatChannelsChatChannelsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetches the count of unread chat channels for the current user.
         * @summary Get Chat Metadata Channels Unread
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChatMetadataChannelsUnreadChatMetadataChannelsUnreadGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatChannelsUnreadCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChatMetadataChannelsUnreadChatMetadataChannelsUnreadGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatApi.getChatMetadataChannelsUnreadChatMetadataChannelsUnreadGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Post Chat Channel Message
         * @param {string} chatChannelId 
         * @param {SendChatMessagePayload} sendChatMessagePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postChatChannelMessageChatChannelsChatChannelIdMessagesPost(chatChannelId: string, sendChatMessagePayload: SendChatMessagePayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postChatChannelMessageChatChannelsChatChannelIdMessagesPost(chatChannelId, sendChatMessagePayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatApi.postChatChannelMessageChatChannelsChatChannelIdMessagesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Marks all messages in the `chat_channel` read before `read_sent_at`.  Only marks messages for which the user was not the sender.
         * @summary Post Chat Channel Read
         * @param {string} chatChannelId 
         * @param {string} readSentAt 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postChatChannelReadChatChannelsChatChannelIdReadPost(chatChannelId: string, readSentAt: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postChatChannelReadChatChannelsChatChannelIdReadPost(chatChannelId, readSentAt, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatApi.postChatChannelReadChatChannelsChatChannelIdReadPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Store Messages From Chime
         * @param {ChimeBridgePayload} chimeBridgePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeMessagesFromChimeChatChimeBridgePost(chimeBridgePayload: ChimeBridgePayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: boolean; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeMessagesFromChimeChatChimeBridgePost(chimeBridgePayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatApi.storeMessagesFromChimeChatChimeBridgePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Suggest Chat Response
         * @param {SuggestChatResponsePayload} suggestChatResponsePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suggestChatResponseChatSuggestionPost(suggestChatResponsePayload: SuggestChatResponsePayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuggestChatResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.suggestChatResponseChatSuggestionPost(suggestChatResponsePayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatApi.suggestChatResponseChatSuggestionPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ChatApi - factory interface
 * @export
 */
export const ChatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatApiFp(configuration)
    return {
        /**
         * Fetches a chat channel for the current user.
         * @summary Get Chat Channel
         * @param {string} chatChannelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatChannelChatChannelsChatChannelIdGet(chatChannelId: string, options?: RawAxiosRequestConfig): AxiosPromise<ChatChannelResponse> {
            return localVarFp.getChatChannelChatChannelsChatChannelIdGet(chatChannelId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sorted by sent_at descending (most recent first). If sent_after is provided, fetch messages sent after the provided time. If sent_before is provided, fetch messages sent before the provided time.  The earliest and latest sent_at times are returned for pagination.
         * @summary Get Chat Channel Messages
         * @param {string} chatChannelId 
         * @param {string | null} [sentAfter] 
         * @param {string | null} [sentBefore] 
         * @param {number} [limit] 
         * @param {SortOrdering} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatChannelMessagesChatChannelsChatChannelIdMessagesGet(chatChannelId: string, sentAfter?: string | null, sentBefore?: string | null, limit?: number, order?: SortOrdering, options?: RawAxiosRequestConfig): AxiosPromise<ChatMessagesResponse> {
            return localVarFp.getChatChannelMessagesChatChannelsChatChannelIdMessagesGet(chatChannelId, sentAfter, sentBefore, limit, order, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches a list of chat channels for the current user.  It is ordered by last activity time first (e.g. message sent), created at second. NOTE: this is not a stable ordering. An acceptable design tradeoff.  If provider_id is provided, filter the channels for a matching provider in the conversation participants. If patient_id is provided, filter the channels for a matching patient in the conversation participants. If updated_after is provided, fetch channels updated after the provided time. If updated_before is provided, fetch channels updated before the provided time.  If more results than the limit are available, the response provides a cursor to be used for pagination. - `latest_updated_at` is returned if no `updated_` or `updated_after` was set. - `earliest_updated_at` is returned if `updated_before` was set.
         * @summary Get Chat Channels
         * @param {string | null} [providerId] 
         * @param {string | null} [patientId] 
         * @param {string | null} [updatedAfter] 
         * @param {string | null} [updatedBefore] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatChannelsChatChannelsGet(providerId?: string | null, patientId?: string | null, updatedAfter?: string | null, updatedBefore?: string | null, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<ChatChannelsResponse> {
            return localVarFp.getChatChannelsChatChannelsGet(providerId, patientId, updatedAfter, updatedBefore, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches the count of unread chat channels for the current user.
         * @summary Get Chat Metadata Channels Unread
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatMetadataChannelsUnreadChatMetadataChannelsUnreadGet(options?: RawAxiosRequestConfig): AxiosPromise<ChatChannelsUnreadCountResponse> {
            return localVarFp.getChatMetadataChannelsUnreadChatMetadataChannelsUnreadGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post Chat Channel Message
         * @param {string} chatChannelId 
         * @param {SendChatMessagePayload} sendChatMessagePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postChatChannelMessageChatChannelsChatChannelIdMessagesPost(chatChannelId: string, sendChatMessagePayload: SendChatMessagePayload, options?: RawAxiosRequestConfig): AxiosPromise<ChatMessageResponse> {
            return localVarFp.postChatChannelMessageChatChannelsChatChannelIdMessagesPost(chatChannelId, sendChatMessagePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Marks all messages in the `chat_channel` read before `read_sent_at`.  Only marks messages for which the user was not the sender.
         * @summary Post Chat Channel Read
         * @param {string} chatChannelId 
         * @param {string} readSentAt 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postChatChannelReadChatChannelsChatChannelIdReadPost(chatChannelId: string, readSentAt: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.postChatChannelReadChatChannelsChatChannelIdReadPost(chatChannelId, readSentAt, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Store Messages From Chime
         * @param {ChimeBridgePayload} chimeBridgePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeMessagesFromChimeChatChimeBridgePost(chimeBridgePayload: ChimeBridgePayload, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: boolean; }> {
            return localVarFp.storeMessagesFromChimeChatChimeBridgePost(chimeBridgePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Suggest Chat Response
         * @param {SuggestChatResponsePayload} suggestChatResponsePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestChatResponseChatSuggestionPost(suggestChatResponsePayload: SuggestChatResponsePayload, options?: RawAxiosRequestConfig): AxiosPromise<SuggestChatResponseResponse> {
            return localVarFp.suggestChatResponseChatSuggestionPost(suggestChatResponsePayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChatApi - object-oriented interface
 * @export
 * @class ChatApi
 * @extends {BaseAPI}
 */
export class ChatApi extends BaseAPI {
    /**
     * Fetches a chat channel for the current user.
     * @summary Get Chat Channel
     * @param {string} chatChannelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public getChatChannelChatChannelsChatChannelIdGet(chatChannelId: string, options?: RawAxiosRequestConfig) {
        return ChatApiFp(this.configuration).getChatChannelChatChannelsChatChannelIdGet(chatChannelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sorted by sent_at descending (most recent first). If sent_after is provided, fetch messages sent after the provided time. If sent_before is provided, fetch messages sent before the provided time.  The earliest and latest sent_at times are returned for pagination.
     * @summary Get Chat Channel Messages
     * @param {string} chatChannelId 
     * @param {string | null} [sentAfter] 
     * @param {string | null} [sentBefore] 
     * @param {number} [limit] 
     * @param {SortOrdering} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public getChatChannelMessagesChatChannelsChatChannelIdMessagesGet(chatChannelId: string, sentAfter?: string | null, sentBefore?: string | null, limit?: number, order?: SortOrdering, options?: RawAxiosRequestConfig) {
        return ChatApiFp(this.configuration).getChatChannelMessagesChatChannelsChatChannelIdMessagesGet(chatChannelId, sentAfter, sentBefore, limit, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches a list of chat channels for the current user.  It is ordered by last activity time first (e.g. message sent), created at second. NOTE: this is not a stable ordering. An acceptable design tradeoff.  If provider_id is provided, filter the channels for a matching provider in the conversation participants. If patient_id is provided, filter the channels for a matching patient in the conversation participants. If updated_after is provided, fetch channels updated after the provided time. If updated_before is provided, fetch channels updated before the provided time.  If more results than the limit are available, the response provides a cursor to be used for pagination. - `latest_updated_at` is returned if no `updated_` or `updated_after` was set. - `earliest_updated_at` is returned if `updated_before` was set.
     * @summary Get Chat Channels
     * @param {string | null} [providerId] 
     * @param {string | null} [patientId] 
     * @param {string | null} [updatedAfter] 
     * @param {string | null} [updatedBefore] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public getChatChannelsChatChannelsGet(providerId?: string | null, patientId?: string | null, updatedAfter?: string | null, updatedBefore?: string | null, limit?: number, options?: RawAxiosRequestConfig) {
        return ChatApiFp(this.configuration).getChatChannelsChatChannelsGet(providerId, patientId, updatedAfter, updatedBefore, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches the count of unread chat channels for the current user.
     * @summary Get Chat Metadata Channels Unread
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public getChatMetadataChannelsUnreadChatMetadataChannelsUnreadGet(options?: RawAxiosRequestConfig) {
        return ChatApiFp(this.configuration).getChatMetadataChannelsUnreadChatMetadataChannelsUnreadGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post Chat Channel Message
     * @param {string} chatChannelId 
     * @param {SendChatMessagePayload} sendChatMessagePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public postChatChannelMessageChatChannelsChatChannelIdMessagesPost(chatChannelId: string, sendChatMessagePayload: SendChatMessagePayload, options?: RawAxiosRequestConfig) {
        return ChatApiFp(this.configuration).postChatChannelMessageChatChannelsChatChannelIdMessagesPost(chatChannelId, sendChatMessagePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Marks all messages in the `chat_channel` read before `read_sent_at`.  Only marks messages for which the user was not the sender.
     * @summary Post Chat Channel Read
     * @param {string} chatChannelId 
     * @param {string} readSentAt 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public postChatChannelReadChatChannelsChatChannelIdReadPost(chatChannelId: string, readSentAt: string, options?: RawAxiosRequestConfig) {
        return ChatApiFp(this.configuration).postChatChannelReadChatChannelsChatChannelIdReadPost(chatChannelId, readSentAt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Store Messages From Chime
     * @param {ChimeBridgePayload} chimeBridgePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public storeMessagesFromChimeChatChimeBridgePost(chimeBridgePayload: ChimeBridgePayload, options?: RawAxiosRequestConfig) {
        return ChatApiFp(this.configuration).storeMessagesFromChimeChatChimeBridgePost(chimeBridgePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Suggest Chat Response
     * @param {SuggestChatResponsePayload} suggestChatResponsePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public suggestChatResponseChatSuggestionPost(suggestChatResponsePayload: SuggestChatResponsePayload, options?: RawAxiosRequestConfig) {
        return ChatApiFp(this.configuration).suggestChatResponseChatSuggestionPost(suggestChatResponsePayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ClaimsApi - axios parameter creator
 * @export
 */
export const ClaimsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel Client Care
         * @param {string} careId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelClientCareMyClientCareCareIdCancelPost: async (careId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'careId' is not null or undefined
            assertParamExists('cancelClientCareMyClientCareCareIdCancelPost', 'careId', careId)
            const localVarPath = `/my-client-care/{care_id}/cancel`
                .replace(`{${"care_id"}}`, encodeURIComponent(String(careId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Claim
         * @param {CreateClaimPayload} createClaimPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClaimClaimsPost: async (createClaimPayload: CreateClaimPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createClaimPayload' is not null or undefined
            assertParamExists('createClaimClaimsPost', 'createClaimPayload', createClaimPayload)
            const localVarPath = `/claims`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createClaimPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dispute Claim
         * @param {string} encounterId 
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disputeClaimBillingPatientIdChatItemsEncounterIdCancelPost: async (encounterId: string, patientId: PatientId, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'encounterId' is not null or undefined
            assertParamExists('disputeClaimBillingPatientIdChatItemsEncounterIdCancelPost', 'encounterId', encounterId)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('disputeClaimBillingPatientIdChatItemsEncounterIdCancelPost', 'patientId', patientId)
            const localVarPath = `/billing/{patient_id}/chat-items/{encounter_id}/cancel`
                .replace(`{${"encounter_id"}}`, encodeURIComponent(String(encounterId)))
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Patient Billing Chat Items
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientBillingChatItemsBillingPatientIdChatItemsGet: async (patientId: PatientId, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientBillingChatItemsBillingPatientIdChatItemsGet', 'patientId', patientId)
            const localVarPath = `/billing/{patient_id}/chat-items`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary My Client Care Details
         * @param {string} careId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myClientCareDetailsMyClientCareCareIdGet: async (careId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'careId' is not null or undefined
            assertParamExists('myClientCareDetailsMyClientCareCareIdGet', 'careId', careId)
            const localVarPath = `/my-client-care/{care_id}`
                .replace(`{${"care_id"}}`, encodeURIComponent(String(careId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary My Client Care
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myClientCareMyClientCareGet: async (page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/my-client-care`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update My Client Care
         * @param {string} careId 
         * @param {UpdateClientDetailsPayload} updateClientDetailsPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyClientCareMyClientCareCareIdPatch: async (careId: string, updateClientDetailsPayload: UpdateClientDetailsPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'careId' is not null or undefined
            assertParamExists('updateMyClientCareMyClientCareCareIdPatch', 'careId', careId)
            // verify required parameter 'updateClientDetailsPayload' is not null or undefined
            assertParamExists('updateMyClientCareMyClientCareCareIdPatch', 'updateClientDetailsPayload', updateClientDetailsPayload)
            const localVarPath = `/my-client-care/{care_id}`
                .replace(`{${"care_id"}}`, encodeURIComponent(String(careId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateClientDetailsPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClaimsApi - functional programming interface
 * @export
 */
export const ClaimsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClaimsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel Client Care
         * @param {string} careId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelClientCareMyClientCareCareIdCancelPost(careId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCareDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelClientCareMyClientCareCareIdCancelPost(careId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClaimsApi.cancelClientCareMyClientCareCareIdCancelPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Claim
         * @param {CreateClaimPayload} createClaimPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClaimClaimsPost(createClaimPayload: CreateClaimPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateClaimResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClaimClaimsPost(createClaimPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClaimsApi.createClaimClaimsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Dispute Claim
         * @param {string} encounterId 
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disputeClaimBillingPatientIdChatItemsEncounterIdCancelPost(encounterId: string, patientId: PatientId, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientRemoteEncounterDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disputeClaimBillingPatientIdChatItemsEncounterIdCancelPost(encounterId, patientId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClaimsApi.disputeClaimBillingPatientIdChatItemsEncounterIdCancelPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Patient Billing Chat Items
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientBillingChatItemsBillingPatientIdChatItemsGet(patientId: PatientId, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientRemoteEncounterDetailsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientBillingChatItemsBillingPatientIdChatItemsGet(patientId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClaimsApi.getPatientBillingChatItemsBillingPatientIdChatItemsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary My Client Care Details
         * @param {string} careId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myClientCareDetailsMyClientCareCareIdGet(careId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCareDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.myClientCareDetailsMyClientCareCareIdGet(careId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClaimsApi.myClientCareDetailsMyClientCareCareIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary My Client Care
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myClientCareMyClientCareGet(page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DateRangePaginatedClientCareResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.myClientCareMyClientCareGet(page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClaimsApi.myClientCareMyClientCareGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update My Client Care
         * @param {string} careId 
         * @param {UpdateClientDetailsPayload} updateClientDetailsPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMyClientCareMyClientCareCareIdPatch(careId: string, updateClientDetailsPayload: UpdateClientDetailsPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCareDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMyClientCareMyClientCareCareIdPatch(careId, updateClientDetailsPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClaimsApi.updateMyClientCareMyClientCareCareIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ClaimsApi - factory interface
 * @export
 */
export const ClaimsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClaimsApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel Client Care
         * @param {string} careId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelClientCareMyClientCareCareIdCancelPost(careId: string, options?: RawAxiosRequestConfig): AxiosPromise<ClientCareDetailsResponse> {
            return localVarFp.cancelClientCareMyClientCareCareIdCancelPost(careId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Claim
         * @param {CreateClaimPayload} createClaimPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClaimClaimsPost(createClaimPayload: CreateClaimPayload, options?: RawAxiosRequestConfig): AxiosPromise<CreateClaimResponse> {
            return localVarFp.createClaimClaimsPost(createClaimPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dispute Claim
         * @param {string} encounterId 
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disputeClaimBillingPatientIdChatItemsEncounterIdCancelPost(encounterId: string, patientId: PatientId, options?: RawAxiosRequestConfig): AxiosPromise<PatientRemoteEncounterDetailsResponse> {
            return localVarFp.disputeClaimBillingPatientIdChatItemsEncounterIdCancelPost(encounterId, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Patient Billing Chat Items
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientBillingChatItemsBillingPatientIdChatItemsGet(patientId: PatientId, options?: RawAxiosRequestConfig): AxiosPromise<Array<PatientRemoteEncounterDetailsResponse>> {
            return localVarFp.getPatientBillingChatItemsBillingPatientIdChatItemsGet(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary My Client Care Details
         * @param {string} careId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myClientCareDetailsMyClientCareCareIdGet(careId: string, options?: RawAxiosRequestConfig): AxiosPromise<ClientCareDetailsResponse> {
            return localVarFp.myClientCareDetailsMyClientCareCareIdGet(careId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary My Client Care
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myClientCareMyClientCareGet(page?: number, options?: RawAxiosRequestConfig): AxiosPromise<DateRangePaginatedClientCareResponse> {
            return localVarFp.myClientCareMyClientCareGet(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update My Client Care
         * @param {string} careId 
         * @param {UpdateClientDetailsPayload} updateClientDetailsPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyClientCareMyClientCareCareIdPatch(careId: string, updateClientDetailsPayload: UpdateClientDetailsPayload, options?: RawAxiosRequestConfig): AxiosPromise<ClientCareDetailsResponse> {
            return localVarFp.updateMyClientCareMyClientCareCareIdPatch(careId, updateClientDetailsPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClaimsApi - object-oriented interface
 * @export
 * @class ClaimsApi
 * @extends {BaseAPI}
 */
export class ClaimsApi extends BaseAPI {
    /**
     * 
     * @summary Cancel Client Care
     * @param {string} careId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimsApi
     */
    public cancelClientCareMyClientCareCareIdCancelPost(careId: string, options?: RawAxiosRequestConfig) {
        return ClaimsApiFp(this.configuration).cancelClientCareMyClientCareCareIdCancelPost(careId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Claim
     * @param {CreateClaimPayload} createClaimPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimsApi
     */
    public createClaimClaimsPost(createClaimPayload: CreateClaimPayload, options?: RawAxiosRequestConfig) {
        return ClaimsApiFp(this.configuration).createClaimClaimsPost(createClaimPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dispute Claim
     * @param {string} encounterId 
     * @param {PatientId} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimsApi
     */
    public disputeClaimBillingPatientIdChatItemsEncounterIdCancelPost(encounterId: string, patientId: PatientId, options?: RawAxiosRequestConfig) {
        return ClaimsApiFp(this.configuration).disputeClaimBillingPatientIdChatItemsEncounterIdCancelPost(encounterId, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Patient Billing Chat Items
     * @param {PatientId} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimsApi
     */
    public getPatientBillingChatItemsBillingPatientIdChatItemsGet(patientId: PatientId, options?: RawAxiosRequestConfig) {
        return ClaimsApiFp(this.configuration).getPatientBillingChatItemsBillingPatientIdChatItemsGet(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary My Client Care Details
     * @param {string} careId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimsApi
     */
    public myClientCareDetailsMyClientCareCareIdGet(careId: string, options?: RawAxiosRequestConfig) {
        return ClaimsApiFp(this.configuration).myClientCareDetailsMyClientCareCareIdGet(careId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary My Client Care
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimsApi
     */
    public myClientCareMyClientCareGet(page?: number, options?: RawAxiosRequestConfig) {
        return ClaimsApiFp(this.configuration).myClientCareMyClientCareGet(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update My Client Care
     * @param {string} careId 
     * @param {UpdateClientDetailsPayload} updateClientDetailsPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimsApi
     */
    public updateMyClientCareMyClientCareCareIdPatch(careId: string, updateClientDetailsPayload: UpdateClientDetailsPayload, options?: RawAxiosRequestConfig) {
        return ClaimsApiFp(this.configuration).updateMyClientCareMyClientCareCareIdPatch(careId, updateClientDetailsPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CommerceApi - axios parameter creator
 * @export
 */
export const CommerceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add To Cart
         * @param {AddToCartPayload} addToCartPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToCartCommerceCartPost: async (addToCartPayload: AddToCartPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addToCartPayload' is not null or undefined
            assertParamExists('addToCartCommerceCartPost', 'addToCartPayload', addToCartPayload)
            const localVarPath = `/commerce/cart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addToCartPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartCommerceCartGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/commerce/cart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the catalog of products.
         * @summary Get Catalog
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {CatalogSortOptions} [sort] 
         * @param {string | null} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCatalogCommerceCatalogGet: async (page?: number, limit?: number, sort?: CatalogSortOptions, category?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/commerce/catalog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the list of product categories.
         * @summary Get Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoriesCommerceCategoriesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/commerce/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get product details by product ID.
         * @summary Get Product
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductCommerceCatalogProductIdGet: async (productId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductCommerceCatalogProductIdGet', 'productId', productId)
            const localVarPath = `/commerce/catalog/{product_id}`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove From Cart
         * @param {string} cartItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromCartCommerceCartCartItemIdDelete: async (cartItemId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cartItemId' is not null or undefined
            assertParamExists('removeFromCartCommerceCartCartItemIdDelete', 'cartItemId', cartItemId)
            const localVarPath = `/commerce/cart/{cart_item_id}`
                .replace(`{${"cart_item_id"}}`, encodeURIComponent(String(cartItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Cart
         * @param {string} cartItemId 
         * @param {UpdateCartItemPayload} updateCartItemPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCartCommerceCartCartItemIdPatch: async (cartItemId: string, updateCartItemPayload: UpdateCartItemPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cartItemId' is not null or undefined
            assertParamExists('updateCartCommerceCartCartItemIdPatch', 'cartItemId', cartItemId)
            // verify required parameter 'updateCartItemPayload' is not null or undefined
            assertParamExists('updateCartCommerceCartCartItemIdPatch', 'updateCartItemPayload', updateCartItemPayload)
            const localVarPath = `/commerce/cart/{cart_item_id}`
                .replace(`{${"cart_item_id"}}`, encodeURIComponent(String(cartItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCartItemPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommerceApi - functional programming interface
 * @export
 */
export const CommerceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommerceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add To Cart
         * @param {AddToCartPayload} addToCartPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addToCartCommerceCartPost(addToCartPayload: AddToCartPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addToCartCommerceCartPost(addToCartPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommerceApi.addToCartCommerceCartPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCartCommerceCartGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCartCommerceCartGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommerceApi.getCartCommerceCartGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the catalog of products.
         * @summary Get Catalog
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {CatalogSortOptions} [sort] 
         * @param {string | null} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCatalogCommerceCatalogGet(page?: number, limit?: number, sort?: CatalogSortOptions, category?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCatalogCommerceCatalogGet(page, limit, sort, category, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommerceApi.getCatalogCommerceCatalogGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the list of product categories.
         * @summary Get Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategoriesCommerceCategoriesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryDetailsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoriesCommerceCategoriesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommerceApi.getCategoriesCommerceCategoriesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get product details by product ID.
         * @summary Get Product
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductCommerceCatalogProductIdGet(productId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductCommerceCatalogProductIdGet(productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommerceApi.getProductCommerceCatalogProductIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove From Cart
         * @param {string} cartItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeFromCartCommerceCartCartItemIdDelete(cartItemId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeFromCartCommerceCartCartItemIdDelete(cartItemId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommerceApi.removeFromCartCommerceCartCartItemIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Cart
         * @param {string} cartItemId 
         * @param {UpdateCartItemPayload} updateCartItemPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCartCommerceCartCartItemIdPatch(cartItemId: string, updateCartItemPayload: UpdateCartItemPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCartCommerceCartCartItemIdPatch(cartItemId, updateCartItemPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommerceApi.updateCartCommerceCartCartItemIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CommerceApi - factory interface
 * @export
 */
export const CommerceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommerceApiFp(configuration)
    return {
        /**
         * 
         * @summary Add To Cart
         * @param {AddToCartPayload} addToCartPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToCartCommerceCartPost(addToCartPayload: AddToCartPayload, options?: RawAxiosRequestConfig): AxiosPromise<CartDetailsResponse> {
            return localVarFp.addToCartCommerceCartPost(addToCartPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartCommerceCartGet(options?: RawAxiosRequestConfig): AxiosPromise<CartDetailsResponse> {
            return localVarFp.getCartCommerceCartGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the catalog of products.
         * @summary Get Catalog
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {CatalogSortOptions} [sort] 
         * @param {string | null} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCatalogCommerceCatalogGet(page?: number, limit?: number, sort?: CatalogSortOptions, category?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<CatalogResponse> {
            return localVarFp.getCatalogCommerceCatalogGet(page, limit, sort, category, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the list of product categories.
         * @summary Get Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoriesCommerceCategoriesGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<CategoryDetailsResponse>> {
            return localVarFp.getCategoriesCommerceCategoriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get product details by product ID.
         * @summary Get Product
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductCommerceCatalogProductIdGet(productId: string, options?: RawAxiosRequestConfig): AxiosPromise<ProductDetailsResponse> {
            return localVarFp.getProductCommerceCatalogProductIdGet(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove From Cart
         * @param {string} cartItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromCartCommerceCartCartItemIdDelete(cartItemId: string, options?: RawAxiosRequestConfig): AxiosPromise<CartDetailsResponse> {
            return localVarFp.removeFromCartCommerceCartCartItemIdDelete(cartItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Cart
         * @param {string} cartItemId 
         * @param {UpdateCartItemPayload} updateCartItemPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCartCommerceCartCartItemIdPatch(cartItemId: string, updateCartItemPayload: UpdateCartItemPayload, options?: RawAxiosRequestConfig): AxiosPromise<CartDetailsResponse> {
            return localVarFp.updateCartCommerceCartCartItemIdPatch(cartItemId, updateCartItemPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommerceApi - object-oriented interface
 * @export
 * @class CommerceApi
 * @extends {BaseAPI}
 */
export class CommerceApi extends BaseAPI {
    /**
     * 
     * @summary Add To Cart
     * @param {AddToCartPayload} addToCartPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommerceApi
     */
    public addToCartCommerceCartPost(addToCartPayload: AddToCartPayload, options?: RawAxiosRequestConfig) {
        return CommerceApiFp(this.configuration).addToCartCommerceCartPost(addToCartPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Cart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommerceApi
     */
    public getCartCommerceCartGet(options?: RawAxiosRequestConfig) {
        return CommerceApiFp(this.configuration).getCartCommerceCartGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the catalog of products.
     * @summary Get Catalog
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {CatalogSortOptions} [sort] 
     * @param {string | null} [category] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommerceApi
     */
    public getCatalogCommerceCatalogGet(page?: number, limit?: number, sort?: CatalogSortOptions, category?: string | null, options?: RawAxiosRequestConfig) {
        return CommerceApiFp(this.configuration).getCatalogCommerceCatalogGet(page, limit, sort, category, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the list of product categories.
     * @summary Get Categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommerceApi
     */
    public getCategoriesCommerceCategoriesGet(options?: RawAxiosRequestConfig) {
        return CommerceApiFp(this.configuration).getCategoriesCommerceCategoriesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get product details by product ID.
     * @summary Get Product
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommerceApi
     */
    public getProductCommerceCatalogProductIdGet(productId: string, options?: RawAxiosRequestConfig) {
        return CommerceApiFp(this.configuration).getProductCommerceCatalogProductIdGet(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove From Cart
     * @param {string} cartItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommerceApi
     */
    public removeFromCartCommerceCartCartItemIdDelete(cartItemId: string, options?: RawAxiosRequestConfig) {
        return CommerceApiFp(this.configuration).removeFromCartCommerceCartCartItemIdDelete(cartItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Cart
     * @param {string} cartItemId 
     * @param {UpdateCartItemPayload} updateCartItemPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommerceApi
     */
    public updateCartCommerceCartCartItemIdPatch(cartItemId: string, updateCartItemPayload: UpdateCartItemPayload, options?: RawAxiosRequestConfig) {
        return CommerceApiFp(this.configuration).updateCartCommerceCartCartItemIdPatch(cartItemId, updateCartItemPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a list of all the event and command types that are currently being handled by the bus.
         * @summary Admin Get Bus Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetBusTypesAdminBusGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/bus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to resubmit Events and/or Commands to EventBridge for payloads that didn\'t make it to EventBridge due to PutEvents errors in Datadog.
         * @summary Admin Resubmit Event Or Command To Bus
         * @param {BusResubmissionPayload} busResubmissionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminResubmitEventOrCommandToBusAdminBusPost: async (busResubmissionPayload: BusResubmissionPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'busResubmissionPayload' is not null or undefined
            assertParamExists('adminResubmitEventOrCommandToBusAdminBusPost', 'busResubmissionPayload', busResubmissionPayload)
            const localVarPath = `/admin/bus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(busResubmissionPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Background Jobs Handler
         * @param {BackgroundJobPayload} backgroundJobPayload 
         * @param {string} [xApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backgroundJobsHandlerBackgroundJobsPost: async (backgroundJobPayload: BackgroundJobPayload, xApiKey?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'backgroundJobPayload' is not null or undefined
            assertParamExists('backgroundJobsHandlerBackgroundJobsPost', 'backgroundJobPayload', backgroundJobPayload)
            const localVarPath = `/background-jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (xApiKey != null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(backgroundJobPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Commands
         * @param {object} body 
         * @param {string} [xApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commandsCommandsPost: async (body: object, xApiKey?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('commandsCommandsPost', 'body', body)
            const localVarPath = `/commands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (xApiKey != null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Events
         * @param {object} body 
         * @param {string} [xApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventsEventsPost: async (body: object, xApiKey?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('eventsEventsPost', 'body', body)
            const localVarPath = `/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (xApiKey != null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Healthcheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthcheckHealthGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Npi Database
         * @param {string} query 
         * @param {number} limit 
         * @param {USState | null} [state] 
         * @param {ExternalProviderType | null} [providerType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchNpiDatabaseNpiGet: async (query: string, limit: number, state?: USState | null, providerType?: ExternalProviderType | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('searchNpiDatabaseNpiGet', 'query', query)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('searchNpiDatabaseNpiGet', 'limit', limit)
            const localVarPath = `/npi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (providerType !== undefined) {
                localVarQueryParameter['provider_type'] = providerType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Test Cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testCacheTestCacheGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/_test_cache`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a list of all the event and command types that are currently being handled by the bus.
         * @summary Admin Get Bus Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetBusTypesAdminBusGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandsAndEventsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetBusTypesAdminBusGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.adminGetBusTypesAdminBusGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Used to resubmit Events and/or Commands to EventBridge for payloads that didn\'t make it to EventBridge due to PutEvents errors in Datadog.
         * @summary Admin Resubmit Event Or Command To Bus
         * @param {BusResubmissionPayload} busResubmissionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminResubmitEventOrCommandToBusAdminBusPost(busResubmissionPayload: BusResubmissionPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusResubmissionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminResubmitEventOrCommandToBusAdminBusPost(busResubmissionPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.adminResubmitEventOrCommandToBusAdminBusPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Background Jobs Handler
         * @param {BackgroundJobPayload} backgroundJobPayload 
         * @param {string} [xApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backgroundJobsHandlerBackgroundJobsPost(backgroundJobPayload: BackgroundJobPayload, xApiKey?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backgroundJobsHandlerBackgroundJobsPost(backgroundJobPayload, xApiKey, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.backgroundJobsHandlerBackgroundJobsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Commands
         * @param {object} body 
         * @param {string} [xApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commandsCommandsPost(body: object, xApiKey?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commandsCommandsPost(body, xApiKey, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.commandsCommandsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Events
         * @param {object} body 
         * @param {string} [xApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventsEventsPost(body: object, xApiKey?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventsEventsPost(body, xApiKey, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.eventsEventsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Healthcheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthcheckHealthGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthcheckHealthGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.healthcheckHealthGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Search Npi Database
         * @param {string} query 
         * @param {number} limit 
         * @param {USState | null} [state] 
         * @param {ExternalProviderType | null} [providerType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchNpiDatabaseNpiGet(query: string, limit: number, state?: USState | null, providerType?: ExternalProviderType | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NPIProviderResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchNpiDatabaseNpiGet(query, limit, state, providerType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.searchNpiDatabaseNpiGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Test Cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testCacheTestCacheGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string | null>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testCacheTestCacheGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.testCacheTestCacheGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Returns a list of all the event and command types that are currently being handled by the bus.
         * @summary Admin Get Bus Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetBusTypesAdminBusGet(options?: RawAxiosRequestConfig): AxiosPromise<CommandsAndEventsResponse> {
            return localVarFp.adminGetBusTypesAdminBusGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Used to resubmit Events and/or Commands to EventBridge for payloads that didn\'t make it to EventBridge due to PutEvents errors in Datadog.
         * @summary Admin Resubmit Event Or Command To Bus
         * @param {BusResubmissionPayload} busResubmissionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminResubmitEventOrCommandToBusAdminBusPost(busResubmissionPayload: BusResubmissionPayload, options?: RawAxiosRequestConfig): AxiosPromise<BusResubmissionResponse> {
            return localVarFp.adminResubmitEventOrCommandToBusAdminBusPost(busResubmissionPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Background Jobs Handler
         * @param {BackgroundJobPayload} backgroundJobPayload 
         * @param {string} [xApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backgroundJobsHandlerBackgroundJobsPost(backgroundJobPayload: BackgroundJobPayload, xApiKey?: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.backgroundJobsHandlerBackgroundJobsPost(backgroundJobPayload, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Commands
         * @param {object} body 
         * @param {string} [xApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commandsCommandsPost(body: object, xApiKey?: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.commandsCommandsPost(body, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Events
         * @param {object} body 
         * @param {string} [xApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventsEventsPost(body: object, xApiKey?: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.eventsEventsPost(body, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Healthcheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthcheckHealthGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.healthcheckHealthGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search Npi Database
         * @param {string} query 
         * @param {number} limit 
         * @param {USState | null} [state] 
         * @param {ExternalProviderType | null} [providerType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchNpiDatabaseNpiGet(query: string, limit: number, state?: USState | null, providerType?: ExternalProviderType | null, options?: RawAxiosRequestConfig): AxiosPromise<Array<NPIProviderResponse>> {
            return localVarFp.searchNpiDatabaseNpiGet(query, limit, state, providerType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Test Cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testCacheTestCacheGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<string | null>> {
            return localVarFp.testCacheTestCacheGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Returns a list of all the event and command types that are currently being handled by the bus.
     * @summary Admin Get Bus Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminGetBusTypesAdminBusGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminGetBusTypesAdminBusGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Used to resubmit Events and/or Commands to EventBridge for payloads that didn\'t make it to EventBridge due to PutEvents errors in Datadog.
     * @summary Admin Resubmit Event Or Command To Bus
     * @param {BusResubmissionPayload} busResubmissionPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminResubmitEventOrCommandToBusAdminBusPost(busResubmissionPayload: BusResubmissionPayload, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminResubmitEventOrCommandToBusAdminBusPost(busResubmissionPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Background Jobs Handler
     * @param {BackgroundJobPayload} backgroundJobPayload 
     * @param {string} [xApiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public backgroundJobsHandlerBackgroundJobsPost(backgroundJobPayload: BackgroundJobPayload, xApiKey?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).backgroundJobsHandlerBackgroundJobsPost(backgroundJobPayload, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Commands
     * @param {object} body 
     * @param {string} [xApiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public commandsCommandsPost(body: object, xApiKey?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).commandsCommandsPost(body, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Events
     * @param {object} body 
     * @param {string} [xApiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public eventsEventsPost(body: object, xApiKey?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).eventsEventsPost(body, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Healthcheck
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthcheckHealthGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).healthcheckHealthGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search Npi Database
     * @param {string} query 
     * @param {number} limit 
     * @param {USState | null} [state] 
     * @param {ExternalProviderType | null} [providerType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public searchNpiDatabaseNpiGet(query: string, limit: number, state?: USState | null, providerType?: ExternalProviderType | null, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).searchNpiDatabaseNpiGet(query, limit, state, providerType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Test Cache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public testCacheTestCacheGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).testCacheTestCacheGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DonationsApi - axios parameter creator
 * @export
 */
export const DonationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Donation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDonationDonationGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/donation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Donation
         * @param {UpdateRecurringDonationPayload} updateRecurringDonationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDonationDonationPut: async (updateRecurringDonationPayload: UpdateRecurringDonationPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateRecurringDonationPayload' is not null or undefined
            assertParamExists('updateDonationDonationPut', 'updateRecurringDonationPayload', updateRecurringDonationPayload)
            const localVarPath = `/donation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRecurringDonationPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DonationsApi - functional programming interface
 * @export
 */
export const DonationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DonationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Donation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDonationDonationGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecurringDonationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDonationDonationGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DonationsApi.getDonationDonationGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Donation
         * @param {UpdateRecurringDonationPayload} updateRecurringDonationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDonationDonationPut(updateRecurringDonationPayload: UpdateRecurringDonationPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecurringDonationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDonationDonationPut(updateRecurringDonationPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DonationsApi.updateDonationDonationPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DonationsApi - factory interface
 * @export
 */
export const DonationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DonationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Donation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDonationDonationGet(options?: RawAxiosRequestConfig): AxiosPromise<RecurringDonationResponse> {
            return localVarFp.getDonationDonationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Donation
         * @param {UpdateRecurringDonationPayload} updateRecurringDonationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDonationDonationPut(updateRecurringDonationPayload: UpdateRecurringDonationPayload, options?: RawAxiosRequestConfig): AxiosPromise<RecurringDonationResponse> {
            return localVarFp.updateDonationDonationPut(updateRecurringDonationPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DonationsApi - object-oriented interface
 * @export
 * @class DonationsApi
 * @extends {BaseAPI}
 */
export class DonationsApi extends BaseAPI {
    /**
     * 
     * @summary Get Donation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonationsApi
     */
    public getDonationDonationGet(options?: RawAxiosRequestConfig) {
        return DonationsApiFp(this.configuration).getDonationDonationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Donation
     * @param {UpdateRecurringDonationPayload} updateRecurringDonationPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonationsApi
     */
    public updateDonationDonationPut(updateRecurringDonationPayload: UpdateRecurringDonationPayload, options?: RawAxiosRequestConfig) {
        return DonationsApiFp(this.configuration).updateDonationDonationPut(updateRecurringDonationPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EmployerApi - axios parameter creator
 * @export
 */
export const EmployerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Eligibility File
         * @param {CreateEligibilityFilePayload} createEligibilityFilePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEligibilityFileEmployerEligibilityFilesPost: async (createEligibilityFilePayload: CreateEligibilityFilePayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEligibilityFilePayload' is not null or undefined
            assertParamExists('createEligibilityFileEmployerEligibilityFilesPost', 'createEligibilityFilePayload', createEligibilityFilePayload)
            const localVarPath = `/employer-eligibility-files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEligibilityFilePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployerApi - functional programming interface
 * @export
 */
export const EmployerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Eligibility File
         * @param {CreateEligibilityFilePayload} createEligibilityFilePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEligibilityFileEmployerEligibilityFilesPost(createEligibilityFilePayload: CreateEligibilityFilePayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EligibilityFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEligibilityFileEmployerEligibilityFilesPost(createEligibilityFilePayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployerApi.createEligibilityFileEmployerEligibilityFilesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EmployerApi - factory interface
 * @export
 */
export const EmployerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Eligibility File
         * @param {CreateEligibilityFilePayload} createEligibilityFilePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEligibilityFileEmployerEligibilityFilesPost(createEligibilityFilePayload: CreateEligibilityFilePayload, options?: RawAxiosRequestConfig): AxiosPromise<EligibilityFileResponse> {
            return localVarFp.createEligibilityFileEmployerEligibilityFilesPost(createEligibilityFilePayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployerApi - object-oriented interface
 * @export
 * @class EmployerApi
 * @extends {BaseAPI}
 */
export class EmployerApi extends BaseAPI {
    /**
     * 
     * @summary Create Eligibility File
     * @param {CreateEligibilityFilePayload} createEligibilityFilePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployerApi
     */
    public createEligibilityFileEmployerEligibilityFilesPost(createEligibilityFilePayload: CreateEligibilityFilePayload, options?: RawAxiosRequestConfig) {
        return EmployerApiFp(this.configuration).createEligibilityFileEmployerEligibilityFilesPost(createEligibilityFilePayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InsuranceCheckingApi - axios parameter creator
 * @export
 */
export const InsuranceCheckingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Estimate Price
         * @param {PricePayload} pricePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        estimatePriceInsuranceCheckingEstimatePricePost: async (pricePayload: PricePayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pricePayload' is not null or undefined
            assertParamExists('estimatePriceInsuranceCheckingEstimatePricePost', 'pricePayload', pricePayload)
            const localVarPath = `/insurance-checking/estimate-price`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pricePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate Insurance
         * @param {InsuranceValidationPayload} insuranceValidationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateInsuranceInsuranceCheckingValidateInsurancePost: async (insuranceValidationPayload: InsuranceValidationPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insuranceValidationPayload' is not null or undefined
            assertParamExists('validateInsuranceInsuranceCheckingValidateInsurancePost', 'insuranceValidationPayload', insuranceValidationPayload)
            const localVarPath = `/insurance-checking/validate-insurance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insuranceValidationPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InsuranceCheckingApi - functional programming interface
 * @export
 */
export const InsuranceCheckingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InsuranceCheckingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Estimate Price
         * @param {PricePayload} pricePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async estimatePriceInsuranceCheckingEstimatePricePost(pricePayload: PricePayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.estimatePriceInsuranceCheckingEstimatePricePost(pricePayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsuranceCheckingApi.estimatePriceInsuranceCheckingEstimatePricePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Validate Insurance
         * @param {InsuranceValidationPayload} insuranceValidationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateInsuranceInsuranceCheckingValidateInsurancePost(insuranceValidationPayload: InsuranceValidationPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsuranceValidationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateInsuranceInsuranceCheckingValidateInsurancePost(insuranceValidationPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsuranceCheckingApi.validateInsuranceInsuranceCheckingValidateInsurancePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InsuranceCheckingApi - factory interface
 * @export
 */
export const InsuranceCheckingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InsuranceCheckingApiFp(configuration)
    return {
        /**
         * 
         * @summary Estimate Price
         * @param {PricePayload} pricePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        estimatePriceInsuranceCheckingEstimatePricePost(pricePayload: PricePayload, options?: RawAxiosRequestConfig): AxiosPromise<PriceResponse> {
            return localVarFp.estimatePriceInsuranceCheckingEstimatePricePost(pricePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate Insurance
         * @param {InsuranceValidationPayload} insuranceValidationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateInsuranceInsuranceCheckingValidateInsurancePost(insuranceValidationPayload: InsuranceValidationPayload, options?: RawAxiosRequestConfig): AxiosPromise<InsuranceValidationResponse> {
            return localVarFp.validateInsuranceInsuranceCheckingValidateInsurancePost(insuranceValidationPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InsuranceCheckingApi - object-oriented interface
 * @export
 * @class InsuranceCheckingApi
 * @extends {BaseAPI}
 */
export class InsuranceCheckingApi extends BaseAPI {
    /**
     * 
     * @summary Estimate Price
     * @param {PricePayload} pricePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceCheckingApi
     */
    public estimatePriceInsuranceCheckingEstimatePricePost(pricePayload: PricePayload, options?: RawAxiosRequestConfig) {
        return InsuranceCheckingApiFp(this.configuration).estimatePriceInsuranceCheckingEstimatePricePost(pricePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate Insurance
     * @param {InsuranceValidationPayload} insuranceValidationPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceCheckingApi
     */
    public validateInsuranceInsuranceCheckingValidateInsurancePost(insuranceValidationPayload: InsuranceValidationPayload, options?: RawAxiosRequestConfig) {
        return InsuranceCheckingApiFp(this.configuration).validateInsuranceInsuranceCheckingValidateInsurancePost(insuranceValidationPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MealPlanningApi - axios parameter creator
 * @export
 */
export const MealPlanningApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate Meal Plan
         * @param {GenerateMealPlanPayload} generateMealPlanPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateMealPlanMealPlanPost: async (generateMealPlanPayload: GenerateMealPlanPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generateMealPlanPayload' is not null or undefined
            assertParamExists('generateMealPlanMealPlanPost', 'generateMealPlanPayload', generateMealPlanPayload)
            const localVarPath = `/meal-plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateMealPlanPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Meal Plan
         * @param {string} mealPlanId The ID of the meal plan to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealPlanMealPlanMealPlanIdGet: async (mealPlanId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mealPlanId' is not null or undefined
            assertParamExists('getMealPlanMealPlanMealPlanIdGet', 'mealPlanId', mealPlanId)
            const localVarPath = `/meal-plan/{meal_plan_id}`
                .replace(`{${"meal_plan_id"}}`, encodeURIComponent(String(mealPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send Meal Plan
         * @param {string} mealPlanId The ID of the meal plan to send
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMealPlanMealPlanMealPlanIdSendPost: async (mealPlanId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mealPlanId' is not null or undefined
            assertParamExists('sendMealPlanMealPlanMealPlanIdSendPost', 'mealPlanId', mealPlanId)
            const localVarPath = `/meal-plan/{meal_plan_id}/send`
                .replace(`{${"meal_plan_id"}}`, encodeURIComponent(String(mealPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Meal
         * @param {string} mealPlanId The ID of the meal plan to update
         * @param {string} mealId The ID of the meal to update
         * @param {UpdateMealPayload} updateMealPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMealMealPlanMealPlanIdMealsMealIdPut: async (mealPlanId: string, mealId: string, updateMealPayload: UpdateMealPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mealPlanId' is not null or undefined
            assertParamExists('updateMealMealPlanMealPlanIdMealsMealIdPut', 'mealPlanId', mealPlanId)
            // verify required parameter 'mealId' is not null or undefined
            assertParamExists('updateMealMealPlanMealPlanIdMealsMealIdPut', 'mealId', mealId)
            // verify required parameter 'updateMealPayload' is not null or undefined
            assertParamExists('updateMealMealPlanMealPlanIdMealsMealIdPut', 'updateMealPayload', updateMealPayload)
            const localVarPath = `/meal-plan/{meal_plan_id}/meals/{meal_id}`
                .replace(`{${"meal_plan_id"}}`, encodeURIComponent(String(mealPlanId)))
                .replace(`{${"meal_id"}}`, encodeURIComponent(String(mealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMealPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MealPlanningApi - functional programming interface
 * @export
 */
export const MealPlanningApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MealPlanningApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generate Meal Plan
         * @param {GenerateMealPlanPayload} generateMealPlanPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateMealPlanMealPlanPost(generateMealPlanPayload: GenerateMealPlanPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateMealPlanMealPlanPost(generateMealPlanPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MealPlanningApi.generateMealPlanMealPlanPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Meal Plan
         * @param {string} mealPlanId The ID of the meal plan to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMealPlanMealPlanMealPlanIdGet(mealPlanId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMealPlanMealPlanMealPlanIdGet(mealPlanId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MealPlanningApi.getMealPlanMealPlanMealPlanIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Send Meal Plan
         * @param {string} mealPlanId The ID of the meal plan to send
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMealPlanMealPlanMealPlanIdSendPost(mealPlanId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendMealPlanMealPlanMealPlanIdSendPost(mealPlanId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MealPlanningApi.sendMealPlanMealPlanMealPlanIdSendPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Meal
         * @param {string} mealPlanId The ID of the meal plan to update
         * @param {string} mealId The ID of the meal to update
         * @param {UpdateMealPayload} updateMealPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMealMealPlanMealPlanIdMealsMealIdPut(mealPlanId: string, mealId: string, updateMealPayload: UpdateMealPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMealMealPlanMealPlanIdMealsMealIdPut(mealPlanId, mealId, updateMealPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MealPlanningApi.updateMealMealPlanMealPlanIdMealsMealIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MealPlanningApi - factory interface
 * @export
 */
export const MealPlanningApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MealPlanningApiFp(configuration)
    return {
        /**
         * 
         * @summary Generate Meal Plan
         * @param {GenerateMealPlanPayload} generateMealPlanPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateMealPlanMealPlanPost(generateMealPlanPayload: GenerateMealPlanPayload, options?: RawAxiosRequestConfig): AxiosPromise<MealPlanResponse> {
            return localVarFp.generateMealPlanMealPlanPost(generateMealPlanPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Meal Plan
         * @param {string} mealPlanId The ID of the meal plan to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealPlanMealPlanMealPlanIdGet(mealPlanId: string, options?: RawAxiosRequestConfig): AxiosPromise<MealPlanResponse> {
            return localVarFp.getMealPlanMealPlanMealPlanIdGet(mealPlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send Meal Plan
         * @param {string} mealPlanId The ID of the meal plan to send
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMealPlanMealPlanMealPlanIdSendPost(mealPlanId: string, options?: RawAxiosRequestConfig): AxiosPromise<MealPlanResponse> {
            return localVarFp.sendMealPlanMealPlanMealPlanIdSendPost(mealPlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Meal
         * @param {string} mealPlanId The ID of the meal plan to update
         * @param {string} mealId The ID of the meal to update
         * @param {UpdateMealPayload} updateMealPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMealMealPlanMealPlanIdMealsMealIdPut(mealPlanId: string, mealId: string, updateMealPayload: UpdateMealPayload, options?: RawAxiosRequestConfig): AxiosPromise<MealPlanResponse> {
            return localVarFp.updateMealMealPlanMealPlanIdMealsMealIdPut(mealPlanId, mealId, updateMealPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MealPlanningApi - object-oriented interface
 * @export
 * @class MealPlanningApi
 * @extends {BaseAPI}
 */
export class MealPlanningApi extends BaseAPI {
    /**
     * 
     * @summary Generate Meal Plan
     * @param {GenerateMealPlanPayload} generateMealPlanPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealPlanningApi
     */
    public generateMealPlanMealPlanPost(generateMealPlanPayload: GenerateMealPlanPayload, options?: RawAxiosRequestConfig) {
        return MealPlanningApiFp(this.configuration).generateMealPlanMealPlanPost(generateMealPlanPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Meal Plan
     * @param {string} mealPlanId The ID of the meal plan to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealPlanningApi
     */
    public getMealPlanMealPlanMealPlanIdGet(mealPlanId: string, options?: RawAxiosRequestConfig) {
        return MealPlanningApiFp(this.configuration).getMealPlanMealPlanMealPlanIdGet(mealPlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send Meal Plan
     * @param {string} mealPlanId The ID of the meal plan to send
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealPlanningApi
     */
    public sendMealPlanMealPlanMealPlanIdSendPost(mealPlanId: string, options?: RawAxiosRequestConfig) {
        return MealPlanningApiFp(this.configuration).sendMealPlanMealPlanMealPlanIdSendPost(mealPlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Meal
     * @param {string} mealPlanId The ID of the meal plan to update
     * @param {string} mealId The ID of the meal to update
     * @param {UpdateMealPayload} updateMealPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealPlanningApi
     */
    public updateMealMealPlanMealPlanIdMealsMealIdPut(mealPlanId: string, mealId: string, updateMealPayload: UpdateMealPayload, options?: RawAxiosRequestConfig) {
        return MealPlanningApiFp(this.configuration).updateMealMealPlanMealPlanIdMealsMealIdPut(mealPlanId, mealId, updateMealPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Twilio Inbound Sms Webhook
         * @param {string} from 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        twilioInboundSmsWebhookWebhooksTwilioInboundSmsPost: async (from: string, body: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('twilioInboundSmsWebhookWebhooksTwilioInboundSmsPost', 'from', from)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('twilioInboundSmsWebhookWebhooksTwilioInboundSmsPost', 'body', body)
            const localVarPath = `/webhooks/twilio/inbound_sms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (from !== undefined) { 
                localVarFormParams.set('from_', from as any);
            }
    
            if (body !== undefined) { 
                localVarFormParams.set('body', body as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Twilio Inbound Sms Webhook
         * @param {string} from 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async twilioInboundSmsWebhookWebhooksTwilioInboundSmsPost(from: string, body: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.twilioInboundSmsWebhookWebhooksTwilioInboundSmsPost(from, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationApi.twilioInboundSmsWebhookWebhooksTwilioInboundSmsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * 
         * @summary Twilio Inbound Sms Webhook
         * @param {string} from 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        twilioInboundSmsWebhookWebhooksTwilioInboundSmsPost(from: string, body: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.twilioInboundSmsWebhookWebhooksTwilioInboundSmsPost(from, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @summary Twilio Inbound Sms Webhook
     * @param {string} from 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public twilioInboundSmsWebhookWebhooksTwilioInboundSmsPost(from: string, body: string, options?: RawAxiosRequestConfig) {
        return NotificationApiFp(this.configuration).twilioInboundSmsWebhookWebhooksTwilioInboundSmsPost(from, body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NutritionResearchApi - axios parameter creator
 * @export
 */
export const NutritionResearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Nutrition Research Thread
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNutritionResearchThreadNutritionResearchThreadsPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/nutrition-research/threads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Nutrition Research Thread Messages
         * @param {string} threadId 
         * @param {number} [pageSize] The number of messages to return
         * @param {number} [offset] The offset or cursor used in pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNutritionResearchThreadMessagesNutritionResearchThreadsThreadIdMessagesGet: async (threadId: string, pageSize?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('getNutritionResearchThreadMessagesNutritionResearchThreadsThreadIdMessagesGet', 'threadId', threadId)
            const localVarPath = `/nutrition-research/threads/{thread_id}/messages`
                .replace(`{${"thread_id"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Nutrition Research Thread
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNutritionResearchThreadNutritionResearchThreadsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/nutrition-research/threads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Nutrition Research Feedback
         * @param {NutritionResearchFeedbackPayload} nutritionResearchFeedbackPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nutritionResearchFeedbackNutritionResearchFeedbackPost: async (nutritionResearchFeedbackPayload: NutritionResearchFeedbackPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nutritionResearchFeedbackPayload' is not null or undefined
            assertParamExists('nutritionResearchFeedbackNutritionResearchFeedbackPost', 'nutritionResearchFeedbackPayload', nutritionResearchFeedbackPayload)
            const localVarPath = `/nutrition-research/feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nutritionResearchFeedbackPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Run Nutrition Research Thread
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runNutritionResearchThreadNutritionResearchThreadsRunThreadIdPost: async (threadId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('runNutritionResearchThreadNutritionResearchThreadsRunThreadIdPost', 'threadId', threadId)
            const localVarPath = `/nutrition-research/threads/run/{thread_id}`
                .replace(`{${"thread_id"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send Nutrition Research Message
         * @param {NutritionResearchSendMessagePayload} nutritionResearchSendMessagePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNutritionResearchMessageNutritionResearchMessagePost: async (nutritionResearchSendMessagePayload: NutritionResearchSendMessagePayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nutritionResearchSendMessagePayload' is not null or undefined
            assertParamExists('sendNutritionResearchMessageNutritionResearchMessagePost', 'nutritionResearchSendMessagePayload', nutritionResearchSendMessagePayload)
            const localVarPath = `/nutrition-research/message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nutritionResearchSendMessagePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stream Nutrition Research Thread
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamNutritionResearchThreadNutritionResearchThreadsStreamThreadIdPost: async (threadId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('streamNutritionResearchThreadNutritionResearchThreadsStreamThreadIdPost', 'threadId', threadId)
            const localVarPath = `/nutrition-research/threads/stream/{thread_id}`
                .replace(`{${"thread_id"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NutritionResearchApi - functional programming interface
 * @export
 */
export const NutritionResearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NutritionResearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Nutrition Research Thread
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNutritionResearchThreadNutritionResearchThreadsPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateNutritionResearchThreadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNutritionResearchThreadNutritionResearchThreadsPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NutritionResearchApi.createNutritionResearchThreadNutritionResearchThreadsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Nutrition Research Thread Messages
         * @param {string} threadId 
         * @param {number} [pageSize] The number of messages to return
         * @param {number} [offset] The offset or cursor used in pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNutritionResearchThreadMessagesNutritionResearchThreadsThreadIdMessagesGet(threadId: string, pageSize?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNutritionResearchThreadMessagesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNutritionResearchThreadMessagesNutritionResearchThreadsThreadIdMessagesGet(threadId, pageSize, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NutritionResearchApi.getNutritionResearchThreadMessagesNutritionResearchThreadsThreadIdMessagesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Nutrition Research Thread
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNutritionResearchThreadNutritionResearchThreadsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNutritionResearchThreadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNutritionResearchThreadNutritionResearchThreadsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NutritionResearchApi.getNutritionResearchThreadNutritionResearchThreadsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Nutrition Research Feedback
         * @param {NutritionResearchFeedbackPayload} nutritionResearchFeedbackPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nutritionResearchFeedbackNutritionResearchFeedbackPost(nutritionResearchFeedbackPayload: NutritionResearchFeedbackPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nutritionResearchFeedbackNutritionResearchFeedbackPost(nutritionResearchFeedbackPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NutritionResearchApi.nutritionResearchFeedbackNutritionResearchFeedbackPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Run Nutrition Research Thread
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runNutritionResearchThreadNutritionResearchThreadsRunThreadIdPost(threadId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NutritionResearchRunThreadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runNutritionResearchThreadNutritionResearchThreadsRunThreadIdPost(threadId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NutritionResearchApi.runNutritionResearchThreadNutritionResearchThreadsRunThreadIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Send Nutrition Research Message
         * @param {NutritionResearchSendMessagePayload} nutritionResearchSendMessagePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNutritionResearchMessageNutritionResearchMessagePost(nutritionResearchSendMessagePayload: NutritionResearchSendMessagePayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendNutritionResearchMessageNutritionResearchMessagePost(nutritionResearchSendMessagePayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NutritionResearchApi.sendNutritionResearchMessageNutritionResearchMessagePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Stream Nutrition Research Thread
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async streamNutritionResearchThreadNutritionResearchThreadsStreamThreadIdPost(threadId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.streamNutritionResearchThreadNutritionResearchThreadsStreamThreadIdPost(threadId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NutritionResearchApi.streamNutritionResearchThreadNutritionResearchThreadsStreamThreadIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NutritionResearchApi - factory interface
 * @export
 */
export const NutritionResearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NutritionResearchApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Nutrition Research Thread
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNutritionResearchThreadNutritionResearchThreadsPost(options?: RawAxiosRequestConfig): AxiosPromise<CreateNutritionResearchThreadResponse> {
            return localVarFp.createNutritionResearchThreadNutritionResearchThreadsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Nutrition Research Thread Messages
         * @param {string} threadId 
         * @param {number} [pageSize] The number of messages to return
         * @param {number} [offset] The offset or cursor used in pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNutritionResearchThreadMessagesNutritionResearchThreadsThreadIdMessagesGet(threadId: string, pageSize?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<GetNutritionResearchThreadMessagesResponse> {
            return localVarFp.getNutritionResearchThreadMessagesNutritionResearchThreadsThreadIdMessagesGet(threadId, pageSize, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Nutrition Research Thread
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNutritionResearchThreadNutritionResearchThreadsGet(options?: RawAxiosRequestConfig): AxiosPromise<GetNutritionResearchThreadResponse> {
            return localVarFp.getNutritionResearchThreadNutritionResearchThreadsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Nutrition Research Feedback
         * @param {NutritionResearchFeedbackPayload} nutritionResearchFeedbackPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nutritionResearchFeedbackNutritionResearchFeedbackPost(nutritionResearchFeedbackPayload: NutritionResearchFeedbackPayload, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.nutritionResearchFeedbackNutritionResearchFeedbackPost(nutritionResearchFeedbackPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Run Nutrition Research Thread
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runNutritionResearchThreadNutritionResearchThreadsRunThreadIdPost(threadId: string, options?: RawAxiosRequestConfig): AxiosPromise<NutritionResearchRunThreadResponse> {
            return localVarFp.runNutritionResearchThreadNutritionResearchThreadsRunThreadIdPost(threadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send Nutrition Research Message
         * @param {NutritionResearchSendMessagePayload} nutritionResearchSendMessagePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNutritionResearchMessageNutritionResearchMessagePost(nutritionResearchSendMessagePayload: NutritionResearchSendMessagePayload, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.sendNutritionResearchMessageNutritionResearchMessagePost(nutritionResearchSendMessagePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stream Nutrition Research Thread
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamNutritionResearchThreadNutritionResearchThreadsStreamThreadIdPost(threadId: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.streamNutritionResearchThreadNutritionResearchThreadsStreamThreadIdPost(threadId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NutritionResearchApi - object-oriented interface
 * @export
 * @class NutritionResearchApi
 * @extends {BaseAPI}
 */
export class NutritionResearchApi extends BaseAPI {
    /**
     * 
     * @summary Create Nutrition Research Thread
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NutritionResearchApi
     */
    public createNutritionResearchThreadNutritionResearchThreadsPost(options?: RawAxiosRequestConfig) {
        return NutritionResearchApiFp(this.configuration).createNutritionResearchThreadNutritionResearchThreadsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Nutrition Research Thread Messages
     * @param {string} threadId 
     * @param {number} [pageSize] The number of messages to return
     * @param {number} [offset] The offset or cursor used in pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NutritionResearchApi
     */
    public getNutritionResearchThreadMessagesNutritionResearchThreadsThreadIdMessagesGet(threadId: string, pageSize?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return NutritionResearchApiFp(this.configuration).getNutritionResearchThreadMessagesNutritionResearchThreadsThreadIdMessagesGet(threadId, pageSize, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Nutrition Research Thread
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NutritionResearchApi
     */
    public getNutritionResearchThreadNutritionResearchThreadsGet(options?: RawAxiosRequestConfig) {
        return NutritionResearchApiFp(this.configuration).getNutritionResearchThreadNutritionResearchThreadsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Nutrition Research Feedback
     * @param {NutritionResearchFeedbackPayload} nutritionResearchFeedbackPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NutritionResearchApi
     */
    public nutritionResearchFeedbackNutritionResearchFeedbackPost(nutritionResearchFeedbackPayload: NutritionResearchFeedbackPayload, options?: RawAxiosRequestConfig) {
        return NutritionResearchApiFp(this.configuration).nutritionResearchFeedbackNutritionResearchFeedbackPost(nutritionResearchFeedbackPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Run Nutrition Research Thread
     * @param {string} threadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NutritionResearchApi
     */
    public runNutritionResearchThreadNutritionResearchThreadsRunThreadIdPost(threadId: string, options?: RawAxiosRequestConfig) {
        return NutritionResearchApiFp(this.configuration).runNutritionResearchThreadNutritionResearchThreadsRunThreadIdPost(threadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send Nutrition Research Message
     * @param {NutritionResearchSendMessagePayload} nutritionResearchSendMessagePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NutritionResearchApi
     */
    public sendNutritionResearchMessageNutritionResearchMessagePost(nutritionResearchSendMessagePayload: NutritionResearchSendMessagePayload, options?: RawAxiosRequestConfig) {
        return NutritionResearchApiFp(this.configuration).sendNutritionResearchMessageNutritionResearchMessagePost(nutritionResearchSendMessagePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stream Nutrition Research Thread
     * @param {string} threadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NutritionResearchApi
     */
    public streamNutritionResearchThreadNutritionResearchThreadsStreamThreadIdPost(threadId: string, options?: RawAxiosRequestConfig) {
        return NutritionResearchApiFp(this.configuration).streamNutritionResearchThreadNutritionResearchThreadsStreamThreadIdPost(threadId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PartnerBookingApi - axios parameter creator
 * @export
 */
export const PartnerBookingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Complete Partner Booking
         * @param {string} bookingCode 
         * @param {CompletePartnerBookingPayload} completePartnerBookingPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePartnerBookingPartnersBookingBookingCodePost: async (bookingCode: string, completePartnerBookingPayload: CompletePartnerBookingPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingCode' is not null or undefined
            assertParamExists('completePartnerBookingPartnersBookingBookingCodePost', 'bookingCode', bookingCode)
            // verify required parameter 'completePartnerBookingPayload' is not null or undefined
            assertParamExists('completePartnerBookingPartnersBookingBookingCodePost', 'completePartnerBookingPayload', completePartnerBookingPayload)
            const localVarPath = `/partners/booking/{booking_code}`
                .replace(`{${"booking_code"}}`, encodeURIComponent(String(bookingCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completePartnerBookingPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Partner Booking Info
         * @param {string} bookingCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerBookingInfoPartnersBookingBookingCodeGet: async (bookingCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingCode' is not null or undefined
            assertParamExists('getPartnerBookingInfoPartnersBookingBookingCodeGet', 'bookingCode', bookingCode)
            const localVarPath = `/partners/booking/{booking_code}`
                .replace(`{${"booking_code"}}`, encodeURIComponent(String(bookingCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerBookingApi - functional programming interface
 * @export
 */
export const PartnerBookingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerBookingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Complete Partner Booking
         * @param {string} bookingCode 
         * @param {CompletePartnerBookingPayload} completePartnerBookingPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completePartnerBookingPartnersBookingBookingCodePost(bookingCode: string, completePartnerBookingPayload: CompletePartnerBookingPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompletePartnerBookingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completePartnerBookingPartnersBookingBookingCodePost(bookingCode, completePartnerBookingPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartnerBookingApi.completePartnerBookingPartnersBookingBookingCodePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Partner Booking Info
         * @param {string} bookingCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPartnerBookingInfoPartnersBookingBookingCodeGet(bookingCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPartnerBookingInfoPartnersBookingBookingCodeGet(bookingCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartnerBookingApi.getPartnerBookingInfoPartnersBookingBookingCodeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PartnerBookingApi - factory interface
 * @export
 */
export const PartnerBookingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerBookingApiFp(configuration)
    return {
        /**
         * 
         * @summary Complete Partner Booking
         * @param {string} bookingCode 
         * @param {CompletePartnerBookingPayload} completePartnerBookingPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePartnerBookingPartnersBookingBookingCodePost(bookingCode: string, completePartnerBookingPayload: CompletePartnerBookingPayload, options?: RawAxiosRequestConfig): AxiosPromise<CompletePartnerBookingResponse> {
            return localVarFp.completePartnerBookingPartnersBookingBookingCodePost(bookingCode, completePartnerBookingPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Partner Booking Info
         * @param {string} bookingCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerBookingInfoPartnersBookingBookingCodeGet(bookingCode: string, options?: RawAxiosRequestConfig): AxiosPromise<BookingInfoResponse> {
            return localVarFp.getPartnerBookingInfoPartnersBookingBookingCodeGet(bookingCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerBookingApi - object-oriented interface
 * @export
 * @class PartnerBookingApi
 * @extends {BaseAPI}
 */
export class PartnerBookingApi extends BaseAPI {
    /**
     * 
     * @summary Complete Partner Booking
     * @param {string} bookingCode 
     * @param {CompletePartnerBookingPayload} completePartnerBookingPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerBookingApi
     */
    public completePartnerBookingPartnersBookingBookingCodePost(bookingCode: string, completePartnerBookingPayload: CompletePartnerBookingPayload, options?: RawAxiosRequestConfig) {
        return PartnerBookingApiFp(this.configuration).completePartnerBookingPartnersBookingBookingCodePost(bookingCode, completePartnerBookingPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Partner Booking Info
     * @param {string} bookingCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerBookingApi
     */
    public getPartnerBookingInfoPartnersBookingBookingCodeGet(bookingCode: string, options?: RawAxiosRequestConfig) {
        return PartnerBookingApiFp(this.configuration).getPartnerBookingInfoPartnersBookingBookingCodeGet(bookingCode, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PatientApi - axios parameter creator
 * @export
 */
export const PatientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Patient Lab Feedback
         * @param {string} labId 
         * @param {PatientId} patientId 
         * @param {AddPatientLabFeedbackPayload} addPatientLabFeedbackPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPatientLabFeedbackPatientPatientIdLabsLabIdFeedbackPost: async (labId: string, patientId: PatientId, addPatientLabFeedbackPayload: AddPatientLabFeedbackPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('addPatientLabFeedbackPatientPatientIdLabsLabIdFeedbackPost', 'labId', labId)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('addPatientLabFeedbackPatientPatientIdLabsLabIdFeedbackPost', 'patientId', patientId)
            // verify required parameter 'addPatientLabFeedbackPayload' is not null or undefined
            assertParamExists('addPatientLabFeedbackPatientPatientIdLabsLabIdFeedbackPost', 'addPatientLabFeedbackPayload', addPatientLabFeedbackPayload)
            const localVarPath = `/patient/{patient_id}/labs/{lab_id}/feedback`
                .replace(`{${"lab_id"}}`, encodeURIComponent(String(labId)))
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addPatientLabFeedbackPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Journal Entry
         * @param {CreateJournalEntryPayload} createJournalEntryPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createJournalEntryPatientJournalEntriesPost: async (createJournalEntryPayload: CreateJournalEntryPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createJournalEntryPayload' is not null or undefined
            assertParamExists('createJournalEntryPatientJournalEntriesPost', 'createJournalEntryPayload', createJournalEntryPayload)
            const localVarPath = `/patient/journal/entries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createJournalEntryPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Journal Entry
         * @param {string} journalEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJournalEntryPatientJournalEntriesJournalEntryIdDelete: async (journalEntryId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'journalEntryId' is not null or undefined
            assertParamExists('deleteJournalEntryPatientJournalEntriesJournalEntryIdDelete', 'journalEntryId', journalEntryId)
            const localVarPath = `/patient/journal/entries/{journal_entry_id}`
                .replace(`{${"journal_entry_id"}}`, encodeURIComponent(String(journalEntryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External Facing Details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalFacingDetailsPatientExternalFacingDetailsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patient/external_facing_details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Journal Entry
         * @param {string} journalEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalEntryPatientJournalEntriesJournalEntryIdGet: async (journalEntryId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'journalEntryId' is not null or undefined
            assertParamExists('getJournalEntryPatientJournalEntriesJournalEntryIdGet', 'journalEntryId', journalEntryId)
            const localVarPath = `/patient/journal/entries/{journal_entry_id}`
                .replace(`{${"journal_entry_id"}}`, encodeURIComponent(String(journalEntryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Journal Streak
         * @param {string | null} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalStreakPatientJournalStreakGet: async (timezone?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patient/journal/streak`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Patient Confirmation Status By Onboarding Code
         * @param {string} onboardingCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientConfirmationStatusByOnboardingCodePatientConfirmCodeOnboardingCodeGet: async (onboardingCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'onboardingCode' is not null or undefined
            assertParamExists('getPatientConfirmationStatusByOnboardingCodePatientConfirmCodeOnboardingCodeGet', 'onboardingCode', onboardingCode)
            const localVarPath = `/patient/confirm/code/{onboarding_code}`
                .replace(`{${"onboarding_code"}}`, encodeURIComponent(String(onboardingCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Patient Confirmation Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientConfirmationStatusPatientConfirmGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patient/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Patient Details
         * @param {PatientId} patientId 
         * @param {Array<PatientDetailsInclusions> | null} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientDetailsPatientsPatientIdGet: async (patientId: PatientId, include?: Array<PatientDetailsInclusions> | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientDetailsPatientsPatientIdGet', 'patientId', patientId)
            const localVarPath = `/patients/{patient_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (include) {
                localVarQueryParameter['include'] = include;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Patient Intake Form Answers
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientIntakeFormAnswersPatientsPatientIdIntakeGet: async (patientId: PatientId, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientIntakeFormAnswersPatientsPatientIdIntakeGet', 'patientId', patientId)
            const localVarPath = `/patients/{patient_id}/intake`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve paginated journal entries for the specified patient.  The entries are returned in descending order based on their creation date, with the most recent entries appearing first.  Args:     patient_id (PatientIdAllowingMe): The ID of the patient or \'me\' for the current user.     cursor (str | None): Pagination cursor for fetching the next set of results.     limit (int): Number of entries to return per page (default: 20, max: 100).  Returns:     CursorPaginatedJournalEntriesResponse: A paginated list of journal entries     and the next pagination cursor.
         * @summary Get Patient Journal Entries
         * @param {PatientId} patientId 
         * @param {string | null} [cursor] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientJournalEntriesPatientPatientIdJournalEntriesGet: async (patientId: PatientId, cursor?: string | null, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientJournalEntriesPatientPatientIdJournalEntriesGet', 'patientId', patientId)
            const localVarPath = `/patient/{patient_id}/journal/entries`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Patient Lab
         * @param {string} labId 
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientLabPatientPatientIdLabsLabIdGet: async (labId: string, patientId: PatientId, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('getPatientLabPatientPatientIdLabsLabIdGet', 'labId', labId)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientLabPatientPatientIdLabsLabIdGet', 'patientId', patientId)
            const localVarPath = `/patient/{patient_id}/labs/{lab_id}`
                .replace(`{${"lab_id"}}`, encodeURIComponent(String(labId)))
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Patient Labs
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientLabsPatientPatientIdLabsGet: async (patientId: PatientId, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientLabsPatientPatientIdLabsGet', 'patientId', patientId)
            const localVarPath = `/patient/{patient_id}/labs`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Patients For Provider
         * @param {Array<PatientDetailsInclusions> | null} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientsForProviderPatientsGet: async (include?: Array<PatientDetailsInclusions> | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (include) {
                localVarQueryParameter['include'] = include;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Release Form
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReleaseFormPatientsPatientIdReleaseFormPdfGet: async (patientId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getReleaseFormPatientsPatientIdReleaseFormPdfGet', 'patientId', patientId)
            const localVarPath = `/patients/{patient_id}/release_form.pdf`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Release Form Preview Url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReleaseFormPreviewUrlPatientsFilesReleaseFormPreviewUrlGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patients/files/release_form_preview_url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patient Confirmation By Code
         * @param {PatientConfirmationByCodePayload} patientConfirmationByCodePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientConfirmationByCodePatientConfirmCodePost: async (patientConfirmationByCodePayload: PatientConfirmationByCodePayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientConfirmationByCodePayload' is not null or undefined
            assertParamExists('patientConfirmationByCodePatientConfirmCodePost', 'patientConfirmationByCodePayload', patientConfirmationByCodePayload)
            const localVarPath = `/patient/confirm/code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientConfirmationByCodePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patient Confirmation
         * @param {PatientConfirmationPayload} patientConfirmationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientConfirmationPatientConfirmPost: async (patientConfirmationPayload: PatientConfirmationPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientConfirmationPayload' is not null or undefined
            assertParamExists('patientConfirmationPatientConfirmPost', 'patientConfirmationPayload', patientConfirmationPayload)
            const localVarPath = `/patient/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientConfirmationPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register Patient
         * @param {RegisterPatientPayload} registerPatientPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerPatientAdminPatientsPost: async (registerPatientPayload: RegisterPatientPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerPatientPayload' is not null or undefined
            assertParamExists('registerPatientAdminPatientsPost', 'registerPatientPayload', registerPatientPayload)
            const localVarPath = `/admin/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerPatientPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Patient Intake Form
         * @param {PatientId} patientId 
         * @param {UpdateIntakePayload} updateIntakePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPatientIntakeFormPatientsPatientIdIntakePost: async (patientId: PatientId, updateIntakePayload: UpdateIntakePayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('setPatientIntakeFormPatientsPatientIdIntakePost', 'patientId', patientId)
            // verify required parameter 'updateIntakePayload' is not null or undefined
            assertParamExists('setPatientIntakeFormPatientsPatientIdIntakePost', 'updateIntakePayload', updateIntakePayload)
            const localVarPath = `/patients/{patient_id}/intake`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateIntakePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit Booking Email
         * @param {PatientBookingSubmitEmailPayload} patientBookingSubmitEmailPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitBookingEmailPatientsBookingSubmitEmailPost: async (patientBookingSubmitEmailPayload: PatientBookingSubmitEmailPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientBookingSubmitEmailPayload' is not null or undefined
            assertParamExists('submitBookingEmailPatientsBookingSubmitEmailPost', 'patientBookingSubmitEmailPayload', patientBookingSubmitEmailPayload)
            const localVarPath = `/patients/booking-submit-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientBookingSubmitEmailPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Journal Entry
         * @param {string} journalEntryId 
         * @param {UpdateJournalEntryPayload} updateJournalEntryPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJournalEntryPatientJournalEntriesJournalEntryIdPatch: async (journalEntryId: string, updateJournalEntryPayload: UpdateJournalEntryPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'journalEntryId' is not null or undefined
            assertParamExists('updateJournalEntryPatientJournalEntriesJournalEntryIdPatch', 'journalEntryId', journalEntryId)
            // verify required parameter 'updateJournalEntryPayload' is not null or undefined
            assertParamExists('updateJournalEntryPatientJournalEntriesJournalEntryIdPatch', 'updateJournalEntryPayload', updateJournalEntryPayload)
            const localVarPath = `/patient/journal/entries/{journal_entry_id}`
                .replace(`{${"journal_entry_id"}}`, encodeURIComponent(String(journalEntryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateJournalEntryPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Patient
         * @param {PatientId} patientId 
         * @param {UpdatePatientPayload} updatePatientPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientPatientsPatientIdPatch: async (patientId: PatientId, updatePatientPayload: UpdatePatientPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('updatePatientPatientsPatientIdPatch', 'patientId', patientId)
            // verify required parameter 'updatePatientPayload' is not null or undefined
            assertParamExists('updatePatientPatientsPatientIdPatch', 'updatePatientPayload', updatePatientPayload)
            const localVarPath = `/patients/{patient_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePatientPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Release Of Information
         * @param {PatientId} patientId 
         * @param {ReleaseOfInformationPayload} releaseOfInformationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReleaseOfInformationPatientsPatientIdReleaseOfInformationPost: async (patientId: PatientId, releaseOfInformationPayload: ReleaseOfInformationPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('updateReleaseOfInformationPatientsPatientIdReleaseOfInformationPost', 'patientId', patientId)
            // verify required parameter 'releaseOfInformationPayload' is not null or undefined
            assertParamExists('updateReleaseOfInformationPatientsPatientIdReleaseOfInformationPost', 'releaseOfInformationPayload', releaseOfInformationPayload)
            const localVarPath = `/patients/{patient_id}/release_of_information`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(releaseOfInformationPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Patient Lab
         * @param {PatientId} patientId 
         * @param {UploadPatientLabPayload} uploadPatientLabPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPatientLabPatientPatientIdLabsPost: async (patientId: PatientId, uploadPatientLabPayload: UploadPatientLabPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('uploadPatientLabPatientPatientIdLabsPost', 'patientId', patientId)
            // verify required parameter 'uploadPatientLabPayload' is not null or undefined
            assertParamExists('uploadPatientLabPatientPatientIdLabsPost', 'uploadPatientLabPayload', uploadPatientLabPayload)
            const localVarPath = `/patient/{patient_id}/labs`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadPatientLabPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientApi - functional programming interface
 * @export
 */
export const PatientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Patient Lab Feedback
         * @param {string} labId 
         * @param {PatientId} patientId 
         * @param {AddPatientLabFeedbackPayload} addPatientLabFeedbackPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPatientLabFeedbackPatientPatientIdLabsLabIdFeedbackPost(labId: string, patientId: PatientId, addPatientLabFeedbackPayload: AddPatientLabFeedbackPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientLabResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPatientLabFeedbackPatientPatientIdLabsLabIdFeedbackPost(labId, patientId, addPatientLabFeedbackPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientApi.addPatientLabFeedbackPatientPatientIdLabsLabIdFeedbackPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Journal Entry
         * @param {CreateJournalEntryPayload} createJournalEntryPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createJournalEntryPatientJournalEntriesPost(createJournalEntryPayload: CreateJournalEntryPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JournalEntryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createJournalEntryPatientJournalEntriesPost(createJournalEntryPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientApi.createJournalEntryPatientJournalEntriesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Journal Entry
         * @param {string} journalEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteJournalEntryPatientJournalEntriesJournalEntryIdDelete(journalEntryId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteJournalEntryPatientJournalEntriesJournalEntryIdDelete(journalEntryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientApi.deleteJournalEntryPatientJournalEntriesJournalEntryIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary External Facing Details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalFacingDetailsPatientExternalFacingDetailsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientExternalFacingDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalFacingDetailsPatientExternalFacingDetailsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientApi.externalFacingDetailsPatientExternalFacingDetailsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Journal Entry
         * @param {string} journalEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJournalEntryPatientJournalEntriesJournalEntryIdGet(journalEntryId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JournalEntryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJournalEntryPatientJournalEntriesJournalEntryIdGet(journalEntryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientApi.getJournalEntryPatientJournalEntriesJournalEntryIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Journal Streak
         * @param {string | null} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJournalStreakPatientJournalStreakGet(timezone?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JournalStreakResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJournalStreakPatientJournalStreakGet(timezone, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientApi.getJournalStreakPatientJournalStreakGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Patient Confirmation Status By Onboarding Code
         * @param {string} onboardingCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientConfirmationStatusByOnboardingCodePatientConfirmCodeOnboardingCodeGet(onboardingCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientConfirmationPreflightResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientConfirmationStatusByOnboardingCodePatientConfirmCodeOnboardingCodeGet(onboardingCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientApi.getPatientConfirmationStatusByOnboardingCodePatientConfirmCodeOnboardingCodeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Patient Confirmation Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientConfirmationStatusPatientConfirmGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientConfirmationPreflightResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientConfirmationStatusPatientConfirmGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientApi.getPatientConfirmationStatusPatientConfirmGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Patient Details
         * @param {PatientId} patientId 
         * @param {Array<PatientDetailsInclusions> | null} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientDetailsPatientsPatientIdGet(patientId: PatientId, include?: Array<PatientDetailsInclusions> | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientDetailsPatientsPatientIdGet(patientId, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientApi.getPatientDetailsPatientsPatientIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Patient Intake Form Answers
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientIntakeFormAnswersPatientsPatientIdIntakeGet(patientId: PatientId, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntakeFormResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientIntakeFormAnswersPatientsPatientIdIntakeGet(patientId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientApi.getPatientIntakeFormAnswersPatientsPatientIdIntakeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve paginated journal entries for the specified patient.  The entries are returned in descending order based on their creation date, with the most recent entries appearing first.  Args:     patient_id (PatientIdAllowingMe): The ID of the patient or \'me\' for the current user.     cursor (str | None): Pagination cursor for fetching the next set of results.     limit (int): Number of entries to return per page (default: 20, max: 100).  Returns:     CursorPaginatedJournalEntriesResponse: A paginated list of journal entries     and the next pagination cursor.
         * @summary Get Patient Journal Entries
         * @param {PatientId} patientId 
         * @param {string | null} [cursor] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientJournalEntriesPatientPatientIdJournalEntriesGet(patientId: PatientId, cursor?: string | null, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CursorPaginatedJournalEntriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientJournalEntriesPatientPatientIdJournalEntriesGet(patientId, cursor, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientApi.getPatientJournalEntriesPatientPatientIdJournalEntriesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Patient Lab
         * @param {string} labId 
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientLabPatientPatientIdLabsLabIdGet(labId: string, patientId: PatientId, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientLabResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientLabPatientPatientIdLabsLabIdGet(labId, patientId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientApi.getPatientLabPatientPatientIdLabsLabIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Patient Labs
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientLabsPatientPatientIdLabsGet(patientId: PatientId, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientLabsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientLabsPatientPatientIdLabsGet(patientId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientApi.getPatientLabsPatientPatientIdLabsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Patients For Provider
         * @param {Array<PatientDetailsInclusions> | null} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientsForProviderPatientsGet(include?: Array<PatientDetailsInclusions> | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientDetailsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientsForProviderPatientsGet(include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientApi.getPatientsForProviderPatientsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Release Form
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReleaseFormPatientsPatientIdReleaseFormPdfGet(patientId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReleaseFormPatientsPatientIdReleaseFormPdfGet(patientId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientApi.getReleaseFormPatientsPatientIdReleaseFormPdfGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Release Form Preview Url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReleaseFormPreviewUrlPatientsFilesReleaseFormPreviewUrlGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientReleaseFormPreviewUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReleaseFormPreviewUrlPatientsFilesReleaseFormPreviewUrlGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientApi.getReleaseFormPreviewUrlPatientsFilesReleaseFormPreviewUrlGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Patient Confirmation By Code
         * @param {PatientConfirmationByCodePayload} patientConfirmationByCodePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientConfirmationByCodePatientConfirmCodePost(patientConfirmationByCodePayload: PatientConfirmationByCodePayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientConfirmationByCodePatientConfirmCodePost(patientConfirmationByCodePayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientApi.patientConfirmationByCodePatientConfirmCodePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Patient Confirmation
         * @param {PatientConfirmationPayload} patientConfirmationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientConfirmationPatientConfirmPost(patientConfirmationPayload: PatientConfirmationPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientConfirmationPatientConfirmPost(patientConfirmationPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientApi.patientConfirmationPatientConfirmPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Register Patient
         * @param {RegisterPatientPayload} registerPatientPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerPatientAdminPatientsPost(registerPatientPayload: RegisterPatientPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerPatientAdminPatientsPost(registerPatientPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientApi.registerPatientAdminPatientsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Set Patient Intake Form
         * @param {PatientId} patientId 
         * @param {UpdateIntakePayload} updateIntakePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPatientIntakeFormPatientsPatientIdIntakePost(patientId: PatientId, updateIntakePayload: UpdateIntakePayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntakeFormResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPatientIntakeFormPatientsPatientIdIntakePost(patientId, updateIntakePayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientApi.setPatientIntakeFormPatientsPatientIdIntakePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Submit Booking Email
         * @param {PatientBookingSubmitEmailPayload} patientBookingSubmitEmailPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitBookingEmailPatientsBookingSubmitEmailPost(patientBookingSubmitEmailPayload: PatientBookingSubmitEmailPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientBookingSubmitEmailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitBookingEmailPatientsBookingSubmitEmailPost(patientBookingSubmitEmailPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientApi.submitBookingEmailPatientsBookingSubmitEmailPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Journal Entry
         * @param {string} journalEntryId 
         * @param {UpdateJournalEntryPayload} updateJournalEntryPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateJournalEntryPatientJournalEntriesJournalEntryIdPatch(journalEntryId: string, updateJournalEntryPayload: UpdateJournalEntryPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JournalEntryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateJournalEntryPatientJournalEntriesJournalEntryIdPatch(journalEntryId, updateJournalEntryPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientApi.updateJournalEntryPatientJournalEntriesJournalEntryIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Patient
         * @param {PatientId} patientId 
         * @param {UpdatePatientPayload} updatePatientPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePatientPatientsPatientIdPatch(patientId: PatientId, updatePatientPayload: UpdatePatientPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePatientPatientsPatientIdPatch(patientId, updatePatientPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientApi.updatePatientPatientsPatientIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Release Of Information
         * @param {PatientId} patientId 
         * @param {ReleaseOfInformationPayload} releaseOfInformationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReleaseOfInformationPatientsPatientIdReleaseOfInformationPost(patientId: PatientId, releaseOfInformationPayload: ReleaseOfInformationPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReleaseOfInformationPatientsPatientIdReleaseOfInformationPost(patientId, releaseOfInformationPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientApi.updateReleaseOfInformationPatientsPatientIdReleaseOfInformationPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Upload Patient Lab
         * @param {PatientId} patientId 
         * @param {UploadPatientLabPayload} uploadPatientLabPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadPatientLabPatientPatientIdLabsPost(patientId: PatientId, uploadPatientLabPayload: UploadPatientLabPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientLabResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadPatientLabPatientPatientIdLabsPost(patientId, uploadPatientLabPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientApi.uploadPatientLabPatientPatientIdLabsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PatientApi - factory interface
 * @export
 */
export const PatientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Patient Lab Feedback
         * @param {string} labId 
         * @param {PatientId} patientId 
         * @param {AddPatientLabFeedbackPayload} addPatientLabFeedbackPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPatientLabFeedbackPatientPatientIdLabsLabIdFeedbackPost(labId: string, patientId: PatientId, addPatientLabFeedbackPayload: AddPatientLabFeedbackPayload, options?: RawAxiosRequestConfig): AxiosPromise<PatientLabResponse> {
            return localVarFp.addPatientLabFeedbackPatientPatientIdLabsLabIdFeedbackPost(labId, patientId, addPatientLabFeedbackPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Journal Entry
         * @param {CreateJournalEntryPayload} createJournalEntryPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createJournalEntryPatientJournalEntriesPost(createJournalEntryPayload: CreateJournalEntryPayload, options?: RawAxiosRequestConfig): AxiosPromise<JournalEntryResponse> {
            return localVarFp.createJournalEntryPatientJournalEntriesPost(createJournalEntryPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Journal Entry
         * @param {string} journalEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJournalEntryPatientJournalEntriesJournalEntryIdDelete(journalEntryId: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.deleteJournalEntryPatientJournalEntriesJournalEntryIdDelete(journalEntryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External Facing Details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalFacingDetailsPatientExternalFacingDetailsGet(options?: RawAxiosRequestConfig): AxiosPromise<PatientExternalFacingDetailsResponse> {
            return localVarFp.externalFacingDetailsPatientExternalFacingDetailsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Journal Entry
         * @param {string} journalEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalEntryPatientJournalEntriesJournalEntryIdGet(journalEntryId: string, options?: RawAxiosRequestConfig): AxiosPromise<JournalEntryResponse> {
            return localVarFp.getJournalEntryPatientJournalEntriesJournalEntryIdGet(journalEntryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Journal Streak
         * @param {string | null} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalStreakPatientJournalStreakGet(timezone?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<JournalStreakResponse> {
            return localVarFp.getJournalStreakPatientJournalStreakGet(timezone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Patient Confirmation Status By Onboarding Code
         * @param {string} onboardingCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientConfirmationStatusByOnboardingCodePatientConfirmCodeOnboardingCodeGet(onboardingCode: string, options?: RawAxiosRequestConfig): AxiosPromise<PatientConfirmationPreflightResponse> {
            return localVarFp.getPatientConfirmationStatusByOnboardingCodePatientConfirmCodeOnboardingCodeGet(onboardingCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Patient Confirmation Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientConfirmationStatusPatientConfirmGet(options?: RawAxiosRequestConfig): AxiosPromise<PatientConfirmationPreflightResponse> {
            return localVarFp.getPatientConfirmationStatusPatientConfirmGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Patient Details
         * @param {PatientId} patientId 
         * @param {Array<PatientDetailsInclusions> | null} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientDetailsPatientsPatientIdGet(patientId: PatientId, include?: Array<PatientDetailsInclusions> | null, options?: RawAxiosRequestConfig): AxiosPromise<PatientDetailsResponse> {
            return localVarFp.getPatientDetailsPatientsPatientIdGet(patientId, include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Patient Intake Form Answers
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientIntakeFormAnswersPatientsPatientIdIntakeGet(patientId: PatientId, options?: RawAxiosRequestConfig): AxiosPromise<IntakeFormResponse> {
            return localVarFp.getPatientIntakeFormAnswersPatientsPatientIdIntakeGet(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve paginated journal entries for the specified patient.  The entries are returned in descending order based on their creation date, with the most recent entries appearing first.  Args:     patient_id (PatientIdAllowingMe): The ID of the patient or \'me\' for the current user.     cursor (str | None): Pagination cursor for fetching the next set of results.     limit (int): Number of entries to return per page (default: 20, max: 100).  Returns:     CursorPaginatedJournalEntriesResponse: A paginated list of journal entries     and the next pagination cursor.
         * @summary Get Patient Journal Entries
         * @param {PatientId} patientId 
         * @param {string | null} [cursor] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientJournalEntriesPatientPatientIdJournalEntriesGet(patientId: PatientId, cursor?: string | null, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<CursorPaginatedJournalEntriesResponse> {
            return localVarFp.getPatientJournalEntriesPatientPatientIdJournalEntriesGet(patientId, cursor, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Patient Lab
         * @param {string} labId 
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientLabPatientPatientIdLabsLabIdGet(labId: string, patientId: PatientId, options?: RawAxiosRequestConfig): AxiosPromise<PatientLabResponse> {
            return localVarFp.getPatientLabPatientPatientIdLabsLabIdGet(labId, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Patient Labs
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientLabsPatientPatientIdLabsGet(patientId: PatientId, options?: RawAxiosRequestConfig): AxiosPromise<PatientLabsResponse> {
            return localVarFp.getPatientLabsPatientPatientIdLabsGet(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Patients For Provider
         * @param {Array<PatientDetailsInclusions> | null} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientsForProviderPatientsGet(include?: Array<PatientDetailsInclusions> | null, options?: RawAxiosRequestConfig): AxiosPromise<PatientDetailsListResponse> {
            return localVarFp.getPatientsForProviderPatientsGet(include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Release Form
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReleaseFormPatientsPatientIdReleaseFormPdfGet(patientId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.getReleaseFormPatientsPatientIdReleaseFormPdfGet(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Release Form Preview Url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReleaseFormPreviewUrlPatientsFilesReleaseFormPreviewUrlGet(options?: RawAxiosRequestConfig): AxiosPromise<PatientReleaseFormPreviewUrlResponse> {
            return localVarFp.getReleaseFormPreviewUrlPatientsFilesReleaseFormPreviewUrlGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patient Confirmation By Code
         * @param {PatientConfirmationByCodePayload} patientConfirmationByCodePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientConfirmationByCodePatientConfirmCodePost(patientConfirmationByCodePayload: PatientConfirmationByCodePayload, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.patientConfirmationByCodePatientConfirmCodePost(patientConfirmationByCodePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patient Confirmation
         * @param {PatientConfirmationPayload} patientConfirmationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientConfirmationPatientConfirmPost(patientConfirmationPayload: PatientConfirmationPayload, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.patientConfirmationPatientConfirmPost(patientConfirmationPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register Patient
         * @param {RegisterPatientPayload} registerPatientPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerPatientAdminPatientsPost(registerPatientPayload: RegisterPatientPayload, options?: RawAxiosRequestConfig): AxiosPromise<PatientDetailsResponse> {
            return localVarFp.registerPatientAdminPatientsPost(registerPatientPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Patient Intake Form
         * @param {PatientId} patientId 
         * @param {UpdateIntakePayload} updateIntakePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPatientIntakeFormPatientsPatientIdIntakePost(patientId: PatientId, updateIntakePayload: UpdateIntakePayload, options?: RawAxiosRequestConfig): AxiosPromise<IntakeFormResponse> {
            return localVarFp.setPatientIntakeFormPatientsPatientIdIntakePost(patientId, updateIntakePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submit Booking Email
         * @param {PatientBookingSubmitEmailPayload} patientBookingSubmitEmailPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitBookingEmailPatientsBookingSubmitEmailPost(patientBookingSubmitEmailPayload: PatientBookingSubmitEmailPayload, options?: RawAxiosRequestConfig): AxiosPromise<PatientBookingSubmitEmailResponse> {
            return localVarFp.submitBookingEmailPatientsBookingSubmitEmailPost(patientBookingSubmitEmailPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Journal Entry
         * @param {string} journalEntryId 
         * @param {UpdateJournalEntryPayload} updateJournalEntryPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJournalEntryPatientJournalEntriesJournalEntryIdPatch(journalEntryId: string, updateJournalEntryPayload: UpdateJournalEntryPayload, options?: RawAxiosRequestConfig): AxiosPromise<JournalEntryResponse> {
            return localVarFp.updateJournalEntryPatientJournalEntriesJournalEntryIdPatch(journalEntryId, updateJournalEntryPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Patient
         * @param {PatientId} patientId 
         * @param {UpdatePatientPayload} updatePatientPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientPatientsPatientIdPatch(patientId: PatientId, updatePatientPayload: UpdatePatientPayload, options?: RawAxiosRequestConfig): AxiosPromise<PatientDetailsResponse> {
            return localVarFp.updatePatientPatientsPatientIdPatch(patientId, updatePatientPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Release Of Information
         * @param {PatientId} patientId 
         * @param {ReleaseOfInformationPayload} releaseOfInformationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReleaseOfInformationPatientsPatientIdReleaseOfInformationPost(patientId: PatientId, releaseOfInformationPayload: ReleaseOfInformationPayload, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.updateReleaseOfInformationPatientsPatientIdReleaseOfInformationPost(patientId, releaseOfInformationPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Patient Lab
         * @param {PatientId} patientId 
         * @param {UploadPatientLabPayload} uploadPatientLabPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPatientLabPatientPatientIdLabsPost(patientId: PatientId, uploadPatientLabPayload: UploadPatientLabPayload, options?: RawAxiosRequestConfig): AxiosPromise<PatientLabResponse> {
            return localVarFp.uploadPatientLabPatientPatientIdLabsPost(patientId, uploadPatientLabPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientApi - object-oriented interface
 * @export
 * @class PatientApi
 * @extends {BaseAPI}
 */
export class PatientApi extends BaseAPI {
    /**
     * 
     * @summary Add Patient Lab Feedback
     * @param {string} labId 
     * @param {PatientId} patientId 
     * @param {AddPatientLabFeedbackPayload} addPatientLabFeedbackPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public addPatientLabFeedbackPatientPatientIdLabsLabIdFeedbackPost(labId: string, patientId: PatientId, addPatientLabFeedbackPayload: AddPatientLabFeedbackPayload, options?: RawAxiosRequestConfig) {
        return PatientApiFp(this.configuration).addPatientLabFeedbackPatientPatientIdLabsLabIdFeedbackPost(labId, patientId, addPatientLabFeedbackPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Journal Entry
     * @param {CreateJournalEntryPayload} createJournalEntryPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public createJournalEntryPatientJournalEntriesPost(createJournalEntryPayload: CreateJournalEntryPayload, options?: RawAxiosRequestConfig) {
        return PatientApiFp(this.configuration).createJournalEntryPatientJournalEntriesPost(createJournalEntryPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Journal Entry
     * @param {string} journalEntryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public deleteJournalEntryPatientJournalEntriesJournalEntryIdDelete(journalEntryId: string, options?: RawAxiosRequestConfig) {
        return PatientApiFp(this.configuration).deleteJournalEntryPatientJournalEntriesJournalEntryIdDelete(journalEntryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary External Facing Details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public externalFacingDetailsPatientExternalFacingDetailsGet(options?: RawAxiosRequestConfig) {
        return PatientApiFp(this.configuration).externalFacingDetailsPatientExternalFacingDetailsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Journal Entry
     * @param {string} journalEntryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getJournalEntryPatientJournalEntriesJournalEntryIdGet(journalEntryId: string, options?: RawAxiosRequestConfig) {
        return PatientApiFp(this.configuration).getJournalEntryPatientJournalEntriesJournalEntryIdGet(journalEntryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Journal Streak
     * @param {string | null} [timezone] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getJournalStreakPatientJournalStreakGet(timezone?: string | null, options?: RawAxiosRequestConfig) {
        return PatientApiFp(this.configuration).getJournalStreakPatientJournalStreakGet(timezone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Patient Confirmation Status By Onboarding Code
     * @param {string} onboardingCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getPatientConfirmationStatusByOnboardingCodePatientConfirmCodeOnboardingCodeGet(onboardingCode: string, options?: RawAxiosRequestConfig) {
        return PatientApiFp(this.configuration).getPatientConfirmationStatusByOnboardingCodePatientConfirmCodeOnboardingCodeGet(onboardingCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Patient Confirmation Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getPatientConfirmationStatusPatientConfirmGet(options?: RawAxiosRequestConfig) {
        return PatientApiFp(this.configuration).getPatientConfirmationStatusPatientConfirmGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Patient Details
     * @param {PatientId} patientId 
     * @param {Array<PatientDetailsInclusions> | null} [include] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getPatientDetailsPatientsPatientIdGet(patientId: PatientId, include?: Array<PatientDetailsInclusions> | null, options?: RawAxiosRequestConfig) {
        return PatientApiFp(this.configuration).getPatientDetailsPatientsPatientIdGet(patientId, include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Patient Intake Form Answers
     * @param {PatientId} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getPatientIntakeFormAnswersPatientsPatientIdIntakeGet(patientId: PatientId, options?: RawAxiosRequestConfig) {
        return PatientApiFp(this.configuration).getPatientIntakeFormAnswersPatientsPatientIdIntakeGet(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve paginated journal entries for the specified patient.  The entries are returned in descending order based on their creation date, with the most recent entries appearing first.  Args:     patient_id (PatientIdAllowingMe): The ID of the patient or \'me\' for the current user.     cursor (str | None): Pagination cursor for fetching the next set of results.     limit (int): Number of entries to return per page (default: 20, max: 100).  Returns:     CursorPaginatedJournalEntriesResponse: A paginated list of journal entries     and the next pagination cursor.
     * @summary Get Patient Journal Entries
     * @param {PatientId} patientId 
     * @param {string | null} [cursor] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getPatientJournalEntriesPatientPatientIdJournalEntriesGet(patientId: PatientId, cursor?: string | null, limit?: number, options?: RawAxiosRequestConfig) {
        return PatientApiFp(this.configuration).getPatientJournalEntriesPatientPatientIdJournalEntriesGet(patientId, cursor, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Patient Lab
     * @param {string} labId 
     * @param {PatientId} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getPatientLabPatientPatientIdLabsLabIdGet(labId: string, patientId: PatientId, options?: RawAxiosRequestConfig) {
        return PatientApiFp(this.configuration).getPatientLabPatientPatientIdLabsLabIdGet(labId, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Patient Labs
     * @param {PatientId} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getPatientLabsPatientPatientIdLabsGet(patientId: PatientId, options?: RawAxiosRequestConfig) {
        return PatientApiFp(this.configuration).getPatientLabsPatientPatientIdLabsGet(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Patients For Provider
     * @param {Array<PatientDetailsInclusions> | null} [include] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getPatientsForProviderPatientsGet(include?: Array<PatientDetailsInclusions> | null, options?: RawAxiosRequestConfig) {
        return PatientApiFp(this.configuration).getPatientsForProviderPatientsGet(include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Release Form
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getReleaseFormPatientsPatientIdReleaseFormPdfGet(patientId: string, options?: RawAxiosRequestConfig) {
        return PatientApiFp(this.configuration).getReleaseFormPatientsPatientIdReleaseFormPdfGet(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Release Form Preview Url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getReleaseFormPreviewUrlPatientsFilesReleaseFormPreviewUrlGet(options?: RawAxiosRequestConfig) {
        return PatientApiFp(this.configuration).getReleaseFormPreviewUrlPatientsFilesReleaseFormPreviewUrlGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patient Confirmation By Code
     * @param {PatientConfirmationByCodePayload} patientConfirmationByCodePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientConfirmationByCodePatientConfirmCodePost(patientConfirmationByCodePayload: PatientConfirmationByCodePayload, options?: RawAxiosRequestConfig) {
        return PatientApiFp(this.configuration).patientConfirmationByCodePatientConfirmCodePost(patientConfirmationByCodePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patient Confirmation
     * @param {PatientConfirmationPayload} patientConfirmationPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientConfirmationPatientConfirmPost(patientConfirmationPayload: PatientConfirmationPayload, options?: RawAxiosRequestConfig) {
        return PatientApiFp(this.configuration).patientConfirmationPatientConfirmPost(patientConfirmationPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register Patient
     * @param {RegisterPatientPayload} registerPatientPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public registerPatientAdminPatientsPost(registerPatientPayload: RegisterPatientPayload, options?: RawAxiosRequestConfig) {
        return PatientApiFp(this.configuration).registerPatientAdminPatientsPost(registerPatientPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Patient Intake Form
     * @param {PatientId} patientId 
     * @param {UpdateIntakePayload} updateIntakePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public setPatientIntakeFormPatientsPatientIdIntakePost(patientId: PatientId, updateIntakePayload: UpdateIntakePayload, options?: RawAxiosRequestConfig) {
        return PatientApiFp(this.configuration).setPatientIntakeFormPatientsPatientIdIntakePost(patientId, updateIntakePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Submit Booking Email
     * @param {PatientBookingSubmitEmailPayload} patientBookingSubmitEmailPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public submitBookingEmailPatientsBookingSubmitEmailPost(patientBookingSubmitEmailPayload: PatientBookingSubmitEmailPayload, options?: RawAxiosRequestConfig) {
        return PatientApiFp(this.configuration).submitBookingEmailPatientsBookingSubmitEmailPost(patientBookingSubmitEmailPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Journal Entry
     * @param {string} journalEntryId 
     * @param {UpdateJournalEntryPayload} updateJournalEntryPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public updateJournalEntryPatientJournalEntriesJournalEntryIdPatch(journalEntryId: string, updateJournalEntryPayload: UpdateJournalEntryPayload, options?: RawAxiosRequestConfig) {
        return PatientApiFp(this.configuration).updateJournalEntryPatientJournalEntriesJournalEntryIdPatch(journalEntryId, updateJournalEntryPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Patient
     * @param {PatientId} patientId 
     * @param {UpdatePatientPayload} updatePatientPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public updatePatientPatientsPatientIdPatch(patientId: PatientId, updatePatientPayload: UpdatePatientPayload, options?: RawAxiosRequestConfig) {
        return PatientApiFp(this.configuration).updatePatientPatientsPatientIdPatch(patientId, updatePatientPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Release Of Information
     * @param {PatientId} patientId 
     * @param {ReleaseOfInformationPayload} releaseOfInformationPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public updateReleaseOfInformationPatientsPatientIdReleaseOfInformationPost(patientId: PatientId, releaseOfInformationPayload: ReleaseOfInformationPayload, options?: RawAxiosRequestConfig) {
        return PatientApiFp(this.configuration).updateReleaseOfInformationPatientsPatientIdReleaseOfInformationPost(patientId, releaseOfInformationPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Patient Lab
     * @param {PatientId} patientId 
     * @param {UploadPatientLabPayload} uploadPatientLabPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public uploadPatientLabPatientPatientIdLabsPost(patientId: PatientId, uploadPatientLabPayload: UploadPatientLabPayload, options?: RawAxiosRequestConfig) {
        return PatientApiFp(this.configuration).uploadPatientLabPatientPatientIdLabsPost(patientId, uploadPatientLabPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PatientBillingApi - axios parameter creator
 * @export
 */
export const PatientBillingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Patient Payment Instrument
         * @param {PatientId} patientId 
         * @param {CreatePaymentInstrumentPayload} createPaymentInstrumentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPatientPaymentInstrumentPatientsPatientIdPaymentPost: async (patientId: PatientId, createPaymentInstrumentPayload: CreatePaymentInstrumentPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('createPatientPaymentInstrumentPatientsPatientIdPaymentPost', 'patientId', patientId)
            // verify required parameter 'createPaymentInstrumentPayload' is not null or undefined
            assertParamExists('createPatientPaymentInstrumentPatientsPatientIdPaymentPost', 'createPaymentInstrumentPayload', createPaymentInstrumentPayload)
            const localVarPath = `/patients/{patient_id}/payment`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPaymentInstrumentPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Patient Billing Items
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientBillingItemsBillingPatientIdBillingItemsGet: async (patientId: PatientId, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientBillingItemsBillingPatientIdBillingItemsGet', 'patientId', patientId)
            const localVarPath = `/billing/{patient_id}/billing-items`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Patient Payment Instrument
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientPaymentInstrumentPatientsPatientIdPaymentGet: async (patientId: PatientId, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientPaymentInstrumentPatientsPatientIdPaymentGet', 'patientId', patientId)
            const localVarPath = `/patients/{patient_id}/payment`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Partner Booking Verify Payment Method
         * @param {PartnerBookingVerifyPaymentMethodPayload} partnerBookingVerifyPaymentMethodPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerBookingVerifyPaymentMethodPartnersVerifyPaymentMethodPost: async (partnerBookingVerifyPaymentMethodPayload: PartnerBookingVerifyPaymentMethodPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerBookingVerifyPaymentMethodPayload' is not null or undefined
            assertParamExists('partnerBookingVerifyPaymentMethodPartnersVerifyPaymentMethodPost', 'partnerBookingVerifyPaymentMethodPayload', partnerBookingVerifyPaymentMethodPayload)
            const localVarPath = `/partners/verify-payment-method`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partnerBookingVerifyPaymentMethodPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refund Patient Charge
         * @param {string} chargeId 
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundPatientChargeBillingPatientIdChargeChargeIdRefundPost: async (chargeId: string, patientId: PatientId, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chargeId' is not null or undefined
            assertParamExists('refundPatientChargeBillingPatientIdChargeChargeIdRefundPost', 'chargeId', chargeId)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('refundPatientChargeBillingPatientIdChargeChargeIdRefundPost', 'patientId', patientId)
            const localVarPath = `/billing/{patient_id}/charge/{charge_id}/refund`
                .replace(`{${"charge_id"}}`, encodeURIComponent(String(chargeId)))
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify Payment Method
         * @param {VerifyPaymentMethodPayload} verifyPaymentMethodPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyPaymentMethodBookingVerifyPaymentMethodPost: async (verifyPaymentMethodPayload: VerifyPaymentMethodPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyPaymentMethodPayload' is not null or undefined
            assertParamExists('verifyPaymentMethodBookingVerifyPaymentMethodPost', 'verifyPaymentMethodPayload', verifyPaymentMethodPayload)
            const localVarPath = `/booking/verify-payment-method`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyPaymentMethodPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientBillingApi - functional programming interface
 * @export
 */
export const PatientBillingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientBillingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Patient Payment Instrument
         * @param {PatientId} patientId 
         * @param {CreatePaymentInstrumentPayload} createPaymentInstrumentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPatientPaymentInstrumentPatientsPatientIdPaymentPost(patientId: PatientId, createPaymentInstrumentPayload: CreatePaymentInstrumentPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentInstrumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPatientPaymentInstrumentPatientsPatientIdPaymentPost(patientId, createPaymentInstrumentPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientBillingApi.createPatientPaymentInstrumentPatientsPatientIdPaymentPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Patient Billing Items
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientBillingItemsBillingPatientIdBillingItemsGet(patientId: PatientId, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientBillingItemDetailsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientBillingItemsBillingPatientIdBillingItemsGet(patientId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientBillingApi.getPatientBillingItemsBillingPatientIdBillingItemsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Patient Payment Instrument
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientPaymentInstrumentPatientsPatientIdPaymentGet(patientId: PatientId, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentInstrumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientPaymentInstrumentPatientsPatientIdPaymentGet(patientId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientBillingApi.getPatientPaymentInstrumentPatientsPatientIdPaymentGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Partner Booking Verify Payment Method
         * @param {PartnerBookingVerifyPaymentMethodPayload} partnerBookingVerifyPaymentMethodPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerBookingVerifyPaymentMethodPartnersVerifyPaymentMethodPost(partnerBookingVerifyPaymentMethodPayload: PartnerBookingVerifyPaymentMethodPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyPaymentMethodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerBookingVerifyPaymentMethodPartnersVerifyPaymentMethodPost(partnerBookingVerifyPaymentMethodPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientBillingApi.partnerBookingVerifyPaymentMethodPartnersVerifyPaymentMethodPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Refund Patient Charge
         * @param {string} chargeId 
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refundPatientChargeBillingPatientIdChargeChargeIdRefundPost(chargeId: string, patientId: PatientId, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refundPatientChargeBillingPatientIdChargeChargeIdRefundPost(chargeId, patientId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientBillingApi.refundPatientChargeBillingPatientIdChargeChargeIdRefundPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Verify Payment Method
         * @param {VerifyPaymentMethodPayload} verifyPaymentMethodPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyPaymentMethodBookingVerifyPaymentMethodPost(verifyPaymentMethodPayload: VerifyPaymentMethodPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyPaymentMethodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyPaymentMethodBookingVerifyPaymentMethodPost(verifyPaymentMethodPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientBillingApi.verifyPaymentMethodBookingVerifyPaymentMethodPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PatientBillingApi - factory interface
 * @export
 */
export const PatientBillingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientBillingApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Patient Payment Instrument
         * @param {PatientId} patientId 
         * @param {CreatePaymentInstrumentPayload} createPaymentInstrumentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPatientPaymentInstrumentPatientsPatientIdPaymentPost(patientId: PatientId, createPaymentInstrumentPayload: CreatePaymentInstrumentPayload, options?: RawAxiosRequestConfig): AxiosPromise<PaymentInstrumentResponse> {
            return localVarFp.createPatientPaymentInstrumentPatientsPatientIdPaymentPost(patientId, createPaymentInstrumentPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Patient Billing Items
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientBillingItemsBillingPatientIdBillingItemsGet(patientId: PatientId, options?: RawAxiosRequestConfig): AxiosPromise<Array<PatientBillingItemDetailsResponse>> {
            return localVarFp.getPatientBillingItemsBillingPatientIdBillingItemsGet(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Patient Payment Instrument
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientPaymentInstrumentPatientsPatientIdPaymentGet(patientId: PatientId, options?: RawAxiosRequestConfig): AxiosPromise<PaymentInstrumentResponse> {
            return localVarFp.getPatientPaymentInstrumentPatientsPatientIdPaymentGet(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Partner Booking Verify Payment Method
         * @param {PartnerBookingVerifyPaymentMethodPayload} partnerBookingVerifyPaymentMethodPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerBookingVerifyPaymentMethodPartnersVerifyPaymentMethodPost(partnerBookingVerifyPaymentMethodPayload: PartnerBookingVerifyPaymentMethodPayload, options?: RawAxiosRequestConfig): AxiosPromise<VerifyPaymentMethodResponse> {
            return localVarFp.partnerBookingVerifyPaymentMethodPartnersVerifyPaymentMethodPost(partnerBookingVerifyPaymentMethodPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refund Patient Charge
         * @param {string} chargeId 
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundPatientChargeBillingPatientIdChargeChargeIdRefundPost(chargeId: string, patientId: PatientId, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.refundPatientChargeBillingPatientIdChargeChargeIdRefundPost(chargeId, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify Payment Method
         * @param {VerifyPaymentMethodPayload} verifyPaymentMethodPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyPaymentMethodBookingVerifyPaymentMethodPost(verifyPaymentMethodPayload: VerifyPaymentMethodPayload, options?: RawAxiosRequestConfig): AxiosPromise<VerifyPaymentMethodResponse> {
            return localVarFp.verifyPaymentMethodBookingVerifyPaymentMethodPost(verifyPaymentMethodPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientBillingApi - object-oriented interface
 * @export
 * @class PatientBillingApi
 * @extends {BaseAPI}
 */
export class PatientBillingApi extends BaseAPI {
    /**
     * 
     * @summary Create Patient Payment Instrument
     * @param {PatientId} patientId 
     * @param {CreatePaymentInstrumentPayload} createPaymentInstrumentPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientBillingApi
     */
    public createPatientPaymentInstrumentPatientsPatientIdPaymentPost(patientId: PatientId, createPaymentInstrumentPayload: CreatePaymentInstrumentPayload, options?: RawAxiosRequestConfig) {
        return PatientBillingApiFp(this.configuration).createPatientPaymentInstrumentPatientsPatientIdPaymentPost(patientId, createPaymentInstrumentPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Patient Billing Items
     * @param {PatientId} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientBillingApi
     */
    public getPatientBillingItemsBillingPatientIdBillingItemsGet(patientId: PatientId, options?: RawAxiosRequestConfig) {
        return PatientBillingApiFp(this.configuration).getPatientBillingItemsBillingPatientIdBillingItemsGet(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Patient Payment Instrument
     * @param {PatientId} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientBillingApi
     */
    public getPatientPaymentInstrumentPatientsPatientIdPaymentGet(patientId: PatientId, options?: RawAxiosRequestConfig) {
        return PatientBillingApiFp(this.configuration).getPatientPaymentInstrumentPatientsPatientIdPaymentGet(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Partner Booking Verify Payment Method
     * @param {PartnerBookingVerifyPaymentMethodPayload} partnerBookingVerifyPaymentMethodPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientBillingApi
     */
    public partnerBookingVerifyPaymentMethodPartnersVerifyPaymentMethodPost(partnerBookingVerifyPaymentMethodPayload: PartnerBookingVerifyPaymentMethodPayload, options?: RawAxiosRequestConfig) {
        return PatientBillingApiFp(this.configuration).partnerBookingVerifyPaymentMethodPartnersVerifyPaymentMethodPost(partnerBookingVerifyPaymentMethodPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refund Patient Charge
     * @param {string} chargeId 
     * @param {PatientId} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientBillingApi
     */
    public refundPatientChargeBillingPatientIdChargeChargeIdRefundPost(chargeId: string, patientId: PatientId, options?: RawAxiosRequestConfig) {
        return PatientBillingApiFp(this.configuration).refundPatientChargeBillingPatientIdChargeChargeIdRefundPost(chargeId, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify Payment Method
     * @param {VerifyPaymentMethodPayload} verifyPaymentMethodPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientBillingApi
     */
    public verifyPaymentMethodBookingVerifyPaymentMethodPost(verifyPaymentMethodPayload: VerifyPaymentMethodPayload, options?: RawAxiosRequestConfig) {
        return PatientBillingApiFp(this.configuration).verifyPaymentMethodBookingVerifyPaymentMethodPost(verifyPaymentMethodPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PatientRecapApi - axios parameter creator
 * @export
 */
export const PatientRecapApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Patient Recap
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientRecapPatientRecapPatientIdGet: async (patientId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientRecapPatientRecapPatientIdGet', 'patientId', patientId)
            const localVarPath = `/patient-recap/{patient_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientRecapApi - functional programming interface
 * @export
 */
export const PatientRecapApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientRecapApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Patient Recap
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientRecapPatientRecapPatientIdGet(patientId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientRecapResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientRecapPatientRecapPatientIdGet(patientId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientRecapApi.getPatientRecapPatientRecapPatientIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PatientRecapApi - factory interface
 * @export
 */
export const PatientRecapApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientRecapApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Patient Recap
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientRecapPatientRecapPatientIdGet(patientId: string, options?: RawAxiosRequestConfig): AxiosPromise<PatientRecapResponse> {
            return localVarFp.getPatientRecapPatientRecapPatientIdGet(patientId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientRecapApi - object-oriented interface
 * @export
 * @class PatientRecapApi
 * @extends {BaseAPI}
 */
export class PatientRecapApi extends BaseAPI {
    /**
     * 
     * @summary Get Patient Recap
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientRecapApi
     */
    public getPatientRecapPatientRecapPatientIdGet(patientId: string, options?: RawAxiosRequestConfig) {
        return PatientRecapApiFp(this.configuration).getPatientRecapPatientRecapPatientIdGet(patientId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PayoutApi - axios parameter creator
 * @export
 */
export const PayoutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Account Session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountSessionProviderStripeAccountSessionPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/provider/stripe/account-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Finalize Payouts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizePayoutsAdminPayoutsFinalizePost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/payouts/finalize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Payouts For Provider
         * @param {string | null} [providerId] Provider ID to get payouts for, leave empty for currently-authenticated provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayoutsForProviderPayoutsGet: async (providerId?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payouts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (providerId !== undefined) {
                localVarQueryParameter['provider_id'] = providerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PayoutApi - functional programming interface
 * @export
 */
export const PayoutApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PayoutApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Account Session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccountSessionProviderStripeAccountSessionPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountSessionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAccountSessionProviderStripeAccountSessionPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PayoutApi.createAccountSessionProviderStripeAccountSessionPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Finalize Payouts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finalizePayoutsAdminPayoutsFinalizePost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finalizePayoutsAdminPayoutsFinalizePost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PayoutApi.finalizePayoutsAdminPayoutsFinalizePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Payouts For Provider
         * @param {string | null} [providerId] Provider ID to get payouts for, leave empty for currently-authenticated provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPayoutsForProviderPayoutsGet(providerId?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PayoutsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPayoutsForProviderPayoutsGet(providerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PayoutApi.getPayoutsForProviderPayoutsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PayoutApi - factory interface
 * @export
 */
export const PayoutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PayoutApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Account Session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountSessionProviderStripeAccountSessionPost(options?: RawAxiosRequestConfig): AxiosPromise<AccountSessionResponse> {
            return localVarFp.createAccountSessionProviderStripeAccountSessionPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Finalize Payouts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizePayoutsAdminPayoutsFinalizePost(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.finalizePayoutsAdminPayoutsFinalizePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Payouts For Provider
         * @param {string | null} [providerId] Provider ID to get payouts for, leave empty for currently-authenticated provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayoutsForProviderPayoutsGet(providerId?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<PayoutsListResponse> {
            return localVarFp.getPayoutsForProviderPayoutsGet(providerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PayoutApi - object-oriented interface
 * @export
 * @class PayoutApi
 * @extends {BaseAPI}
 */
export class PayoutApi extends BaseAPI {
    /**
     * 
     * @summary Create Account Session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutApi
     */
    public createAccountSessionProviderStripeAccountSessionPost(options?: RawAxiosRequestConfig) {
        return PayoutApiFp(this.configuration).createAccountSessionProviderStripeAccountSessionPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Finalize Payouts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutApi
     */
    public finalizePayoutsAdminPayoutsFinalizePost(options?: RawAxiosRequestConfig) {
        return PayoutApiFp(this.configuration).finalizePayoutsAdminPayoutsFinalizePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Payouts For Provider
     * @param {string | null} [providerId] Provider ID to get payouts for, leave empty for currently-authenticated provider
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutApi
     */
    public getPayoutsForProviderPayoutsGet(providerId?: string | null, options?: RawAxiosRequestConfig) {
        return PayoutApiFp(this.configuration).getPayoutsForProviderPayoutsGet(providerId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProviderApi - axios parameter creator
 * @export
 */
export const ProviderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary All Onboardable Providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allOnboardableProvidersAdminOnboardableProvidersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/onboardable-providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Google Calendar Oauth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGoogleCalendarOauthProvidersMeGoogleDelete: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/providers/me/google`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Google Business Review Link
         * @param {string} providerSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoogleBusinessReviewLinkProvidersProviderSlugGoogleBusinessReviewLinkGet: async (providerSlug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerSlug' is not null or undefined
            assertParamExists('getGoogleBusinessReviewLinkProvidersProviderSlugGoogleBusinessReviewLinkGet', 'providerSlug', providerSlug)
            const localVarPath = `/providers/{provider_slug}/google-business-review-link`
                .replace(`{${"provider_slug"}}`, encodeURIComponent(String(providerSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Google Calendar Oauth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoogleCalendarOauthProvidersMeGoogleGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/providers/me/google`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of available slots for the given provider between the given start and end times. The slots returned are the start times of the available slots in 15 minute increments.
         * @summary Get Provider Available Slots
         * @param {string} providerId 
         * @param {string} start 
         * @param {string} end 
         * @param {string | null} [pcrit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderAvailableSlotsProvidersProviderIdAvailabilitySlotsGet: async (providerId: string, start: string, end: string, pcrit?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getProviderAvailableSlotsProvidersProviderIdAvailabilitySlotsGet', 'providerId', providerId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getProviderAvailableSlotsProvidersProviderIdAvailabilitySlotsGet', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getProviderAvailableSlotsProvidersProviderIdAvailabilitySlotsGet', 'end', end)
            const localVarPath = `/providers/{provider_id}/availability-slots`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (pcrit !== undefined) {
                localVarQueryParameter['pcrit'] = pcrit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Provider Client Referrals
         * @param {ProviderId} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderClientReferralsProvidersProviderIdClientReferralsGet: async (providerId: ProviderId, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getProviderClientReferralsProvidersProviderIdClientReferralsGet', 'providerId', providerId)
            const localVarPath = `/providers/{provider_id}/client-referrals`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Provider Confirmation Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderConfirmationStatusProviderConfirmGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/provider/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Provider Details Basic
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderDetailsBasicProvidersProviderIdBasicGet: async (providerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getProviderDetailsBasicProvidersProviderIdBasicGet', 'providerId', providerId)
            const localVarPath = `/providers/{provider_id}/basic`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Provider Details
         * @param {ProviderId} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderDetailsProvidersProviderIdGet: async (providerId: ProviderId, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getProviderDetailsProvidersProviderIdGet', 'providerId', providerId)
            const localVarPath = `/providers/{provider_id}`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Provider Identity
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderIdentityProvidersProviderIdIdentityGet: async (providerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getProviderIdentityProvidersProviderIdIdentityGet', 'providerId', providerId)
            const localVarPath = `/providers/{provider_id}/identity`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Provider Practice Metrics
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderPracticeMetricsProvidersProviderIdPracticeMetricsGet: async (providerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getProviderPracticeMetricsProvidersProviderIdPracticeMetricsGet', 'providerId', providerId)
            const localVarPath = `/providers/{provider_id}/practice-metrics`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Provider Rates
         * @param {ProviderId} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderRatesProvidersProviderIdRatesGet: async (providerId: ProviderId, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getProviderRatesProvidersProviderIdRatesGet', 'providerId', providerId)
            const localVarPath = `/providers/{provider_id}/rates`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Provider Reputation
         * @param {ProviderId} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderReputationProvidersProviderIdReputationGet: async (providerId: ProviderId, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getProviderReputationProvidersProviderIdReputationGet', 'providerId', providerId)
            const localVarPath = `/providers/{provider_id}/reputation`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Provider Review Link
         * @param {string} providerId 
         * @param {string | null} [appointmentId] 
         * @param {number | null} [rating] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderReviewLinkProvidersProviderIdReviewGet: async (providerId: string, appointmentId?: string | null, rating?: number | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getProviderReviewLinkProvidersProviderIdReviewGet', 'providerId', providerId)
            const localVarPath = `/providers/{provider_id}/review`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (appointmentId !== undefined) {
                localVarQueryParameter['appointment_id'] = appointmentId;
            }

            if (rating !== undefined) {
                localVarQueryParameter['rating'] = rating;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Providers Admin
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string | null} [searchQuery] Search for providers by name or email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvidersAdminAdminProvidersGet: async (limit?: number, offset?: number, searchQuery?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (searchQuery !== undefined) {
                localVarQueryParameter['search_query'] = searchQuery;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Providers For Patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvidersForPatientProvidersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Offboard Provider
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offboardProviderAdminProvidersProviderIdOffboardPost: async (providerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('offboardProviderAdminProvidersProviderIdOffboardPost', 'providerId', providerId)
            const localVarPath = `/admin/providers/{provider_id}/offboard`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provider Confirmation
         * @param {ProviderConfirmationPayload} providerConfirmationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerConfirmationProviderConfirmPost: async (providerConfirmationPayload: ProviderConfirmationPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerConfirmationPayload' is not null or undefined
            assertParamExists('providerConfirmationProviderConfirmPost', 'providerConfirmationPayload', providerConfirmationPayload)
            const localVarPath = `/provider/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(providerConfirmationPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * We should really be careful with this endpoint as it can be used indiscriminately. If an attacker wants to mess with our platform, they could overwhelm our database with fake impressions. We should consider rate limiting this endpoint with a platform-wide rate limiter, probably backed by Redis.
         * @summary Record Client Referral Impression
         * @param {ClientReferralImpressionPayload} clientReferralImpressionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordClientReferralImpressionProvidersClientReferralsImpressionPost: async (clientReferralImpressionPayload: ClientReferralImpressionPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientReferralImpressionPayload' is not null or undefined
            assertParamExists('recordClientReferralImpressionProvidersClientReferralsImpressionPost', 'clientReferralImpressionPayload', clientReferralImpressionPayload)
            const localVarPath = `/providers/client-referrals/impression`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientReferralImpressionPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register Provider
         * @param {RegisterProviderPayload} registerProviderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerProviderProvidersPost: async (registerProviderPayload: RegisterProviderPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerProviderPayload' is not null or undefined
            assertParamExists('registerProviderProvidersPost', 'registerProviderPayload', registerProviderPayload)
            const localVarPath = `/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerProviderPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset Provider Password
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetProviderPasswordProvidersProviderIdPasswordResetPost: async (providerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('resetProviderPasswordProvidersProviderIdPasswordResetPost', 'providerId', providerId)
            const localVarPath = `/providers/{provider_id}/password/reset`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send Provider Client Referral Sms
         * @param {ProviderId} providerId 
         * @param {SendClientReferralSMSPayload} sendClientReferralSMSPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendProviderClientReferralSmsProvidersProviderIdClientReferralsSmsPost: async (providerId: ProviderId, sendClientReferralSMSPayload: SendClientReferralSMSPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('sendProviderClientReferralSmsProvidersProviderIdClientReferralsSmsPost', 'providerId', providerId)
            // verify required parameter 'sendClientReferralSMSPayload' is not null or undefined
            assertParamExists('sendProviderClientReferralSmsProvidersProviderIdClientReferralsSmsPost', 'sendClientReferralSMSPayload', sendClientReferralSMSPayload)
            const localVarPath = `/providers/{provider_id}/client-referrals/sms`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendClientReferralSMSPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Google Calendar Oauth
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGoogleCalendarOauthProvidersMeGooglePost: async (code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('setGoogleCalendarOauthProvidersMeGooglePost', 'code', code)
            const localVarPath = `/providers/me/google`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Solicit Provider Review
         * @param {string} providerId 
         * @param {SolicitProviderReviewPayload} solicitProviderReviewPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solicitProviderReviewProvidersProviderIdReviewSolicitationPost: async (providerId: string, solicitProviderReviewPayload: SolicitProviderReviewPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('solicitProviderReviewProvidersProviderIdReviewSolicitationPost', 'providerId', providerId)
            // verify required parameter 'solicitProviderReviewPayload' is not null or undefined
            assertParamExists('solicitProviderReviewProvidersProviderIdReviewSolicitationPost', 'solicitProviderReviewPayload', solicitProviderReviewPayload)
            const localVarPath = `/providers/{provider_id}/review-solicitation`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitProviderReviewPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Provider Feedback
         * @param {string} providerId 
         * @param {string} reviewId 
         * @param {ProviderFeedbackPayload} providerFeedbackPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProviderFeedbackProvidersProviderIdReviewReviewIdPut: async (providerId: string, reviewId: string, providerFeedbackPayload: ProviderFeedbackPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('updateProviderFeedbackProvidersProviderIdReviewReviewIdPut', 'providerId', providerId)
            // verify required parameter 'reviewId' is not null or undefined
            assertParamExists('updateProviderFeedbackProvidersProviderIdReviewReviewIdPut', 'reviewId', reviewId)
            // verify required parameter 'providerFeedbackPayload' is not null or undefined
            assertParamExists('updateProviderFeedbackProvidersProviderIdReviewReviewIdPut', 'providerFeedbackPayload', providerFeedbackPayload)
            const localVarPath = `/providers/{provider_id}/review/{review_id}`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)))
                .replace(`{${"review_id"}}`, encodeURIComponent(String(reviewId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(providerFeedbackPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Provider
         * @param {ProviderId} providerId 
         * @param {UpdateProviderPayload} updateProviderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProviderProvidersProviderIdPatch: async (providerId: ProviderId, updateProviderPayload: UpdateProviderPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('updateProviderProvidersProviderIdPatch', 'providerId', providerId)
            // verify required parameter 'updateProviderPayload' is not null or undefined
            assertParamExists('updateProviderProvidersProviderIdPatch', 'updateProviderPayload', updateProviderPayload)
            const localVarPath = `/providers/{provider_id}`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProviderPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify Provider Client Referral Token
         * @param {string} providerId 
         * @param {VerifyTokenPayload} verifyTokenPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyProviderClientReferralTokenProvidersProviderIdClientReferralsVerifyPost: async (providerId: string, verifyTokenPayload: VerifyTokenPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('verifyProviderClientReferralTokenProvidersProviderIdClientReferralsVerifyPost', 'providerId', providerId)
            // verify required parameter 'verifyTokenPayload' is not null or undefined
            assertParamExists('verifyProviderClientReferralTokenProvidersProviderIdClientReferralsVerifyPost', 'verifyTokenPayload', verifyTokenPayload)
            const localVarPath = `/providers/{provider_id}/client-referrals/verify`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyTokenPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProviderApi - functional programming interface
 * @export
 */
export const ProviderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProviderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary All Onboardable Providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allOnboardableProvidersAdminOnboardableProvidersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OnboardableProviderResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allOnboardableProvidersAdminOnboardableProvidersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.allOnboardableProvidersAdminOnboardableProvidersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Google Calendar Oauth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGoogleCalendarOauthProvidersMeGoogleDelete(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGoogleCalendarOauthProvidersMeGoogleDelete(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.deleteGoogleCalendarOauthProvidersMeGoogleDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Google Business Review Link
         * @param {string} providerSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGoogleBusinessReviewLinkProvidersProviderSlugGoogleBusinessReviewLinkGet(providerSlug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderGoogleBusinessReviewLinkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGoogleBusinessReviewLinkProvidersProviderSlugGoogleBusinessReviewLinkGet(providerSlug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.getGoogleBusinessReviewLinkProvidersProviderSlugGoogleBusinessReviewLinkGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Google Calendar Oauth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGoogleCalendarOauthProvidersMeGoogleGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGoogleCalendarOauthProvidersMeGoogleGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.getGoogleCalendarOauthProvidersMeGoogleGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a list of available slots for the given provider between the given start and end times. The slots returned are the start times of the available slots in 15 minute increments.
         * @summary Get Provider Available Slots
         * @param {string} providerId 
         * @param {string} start 
         * @param {string} end 
         * @param {string | null} [pcrit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderAvailableSlotsProvidersProviderIdAvailabilitySlotsGet(providerId: string, start: string, end: string, pcrit?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailabilitySlotsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderAvailableSlotsProvidersProviderIdAvailabilitySlotsGet(providerId, start, end, pcrit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.getProviderAvailableSlotsProvidersProviderIdAvailabilitySlotsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Provider Client Referrals
         * @param {ProviderId} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderClientReferralsProvidersProviderIdClientReferralsGet(providerId: ProviderId, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientReferralResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderClientReferralsProvidersProviderIdClientReferralsGet(providerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.getProviderClientReferralsProvidersProviderIdClientReferralsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Provider Confirmation Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderConfirmationStatusProviderConfirmGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderConfirmationPreflightResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderConfirmationStatusProviderConfirmGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.getProviderConfirmationStatusProviderConfirmGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Provider Details Basic
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderDetailsBasicProvidersProviderIdBasicGet(providerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderDetailsBasicResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderDetailsBasicProvidersProviderIdBasicGet(providerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.getProviderDetailsBasicProvidersProviderIdBasicGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Provider Details
         * @param {ProviderId} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderDetailsProvidersProviderIdGet(providerId: ProviderId, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderDetailsProvidersProviderIdGet(providerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.getProviderDetailsProvidersProviderIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Provider Identity
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderIdentityProvidersProviderIdIdentityGet(providerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderIdentityDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderIdentityProvidersProviderIdIdentityGet(providerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.getProviderIdentityProvidersProviderIdIdentityGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Provider Practice Metrics
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderPracticeMetricsProvidersProviderIdPracticeMetricsGet(providerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeMetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderPracticeMetricsProvidersProviderIdPracticeMetricsGet(providerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.getProviderPracticeMetricsProvidersProviderIdPracticeMetricsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Provider Rates
         * @param {ProviderId} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderRatesProvidersProviderIdRatesGet(providerId: ProviderId, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderRatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderRatesProvidersProviderIdRatesGet(providerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.getProviderRatesProvidersProviderIdRatesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Provider Reputation
         * @param {ProviderId} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderReputationProvidersProviderIdReputationGet(providerId: ProviderId, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderReputationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderReputationProvidersProviderIdReputationGet(providerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.getProviderReputationProvidersProviderIdReputationGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Provider Review Link
         * @param {string} providerId 
         * @param {string | null} [appointmentId] 
         * @param {number | null} [rating] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderReviewLinkProvidersProviderIdReviewGet(providerId: string, appointmentId?: string | null, rating?: number | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderReviewLinkProvidersProviderIdReviewGet(providerId, appointmentId, rating, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.getProviderReviewLinkProvidersProviderIdReviewGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Providers Admin
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string | null} [searchQuery] Search for providers by name or email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProvidersAdminAdminProvidersGet(limit?: number, offset?: number, searchQuery?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminProviderDetailsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProvidersAdminAdminProvidersGet(limit, offset, searchQuery, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.getProvidersAdminAdminProvidersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Providers For Patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProvidersForPatientProvidersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProviderForPatientDetailsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProvidersForPatientProvidersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.getProvidersForPatientProvidersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Offboard Provider
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offboardProviderAdminProvidersProviderIdOffboardPost(providerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OffboardStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offboardProviderAdminProvidersProviderIdOffboardPost(providerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.offboardProviderAdminProvidersProviderIdOffboardPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Provider Confirmation
         * @param {ProviderConfirmationPayload} providerConfirmationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerConfirmationProviderConfirmPost(providerConfirmationPayload: ProviderConfirmationPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerConfirmationProviderConfirmPost(providerConfirmationPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.providerConfirmationProviderConfirmPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * We should really be careful with this endpoint as it can be used indiscriminately. If an attacker wants to mess with our platform, they could overwhelm our database with fake impressions. We should consider rate limiting this endpoint with a platform-wide rate limiter, probably backed by Redis.
         * @summary Record Client Referral Impression
         * @param {ClientReferralImpressionPayload} clientReferralImpressionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recordClientReferralImpressionProvidersClientReferralsImpressionPost(clientReferralImpressionPayload: ClientReferralImpressionPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientReferralImpressionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recordClientReferralImpressionProvidersClientReferralsImpressionPost(clientReferralImpressionPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.recordClientReferralImpressionProvidersClientReferralsImpressionPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Register Provider
         * @param {RegisterProviderPayload} registerProviderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerProviderProvidersPost(registerProviderPayload: RegisterProviderPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerProviderProvidersPost(registerProviderPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.registerProviderProvidersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Reset Provider Password
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetProviderPasswordProvidersProviderIdPasswordResetPost(providerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetProviderPasswordProvidersProviderIdPasswordResetPost(providerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.resetProviderPasswordProvidersProviderIdPasswordResetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Send Provider Client Referral Sms
         * @param {ProviderId} providerId 
         * @param {SendClientReferralSMSPayload} sendClientReferralSMSPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendProviderClientReferralSmsProvidersProviderIdClientReferralsSmsPost(providerId: ProviderId, sendClientReferralSMSPayload: SendClientReferralSMSPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendProviderClientReferralSmsProvidersProviderIdClientReferralsSmsPost(providerId, sendClientReferralSMSPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.sendProviderClientReferralSmsProvidersProviderIdClientReferralsSmsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Set Google Calendar Oauth
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setGoogleCalendarOauthProvidersMeGooglePost(code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setGoogleCalendarOauthProvidersMeGooglePost(code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.setGoogleCalendarOauthProvidersMeGooglePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Solicit Provider Review
         * @param {string} providerId 
         * @param {SolicitProviderReviewPayload} solicitProviderReviewPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async solicitProviderReviewProvidersProviderIdReviewSolicitationPost(providerId: string, solicitProviderReviewPayload: SolicitProviderReviewPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.solicitProviderReviewProvidersProviderIdReviewSolicitationPost(providerId, solicitProviderReviewPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.solicitProviderReviewProvidersProviderIdReviewSolicitationPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Provider Feedback
         * @param {string} providerId 
         * @param {string} reviewId 
         * @param {ProviderFeedbackPayload} providerFeedbackPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProviderFeedbackProvidersProviderIdReviewReviewIdPut(providerId: string, reviewId: string, providerFeedbackPayload: ProviderFeedbackPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProviderFeedbackProvidersProviderIdReviewReviewIdPut(providerId, reviewId, providerFeedbackPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.updateProviderFeedbackProvidersProviderIdReviewReviewIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Provider
         * @param {ProviderId} providerId 
         * @param {UpdateProviderPayload} updateProviderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProviderProvidersProviderIdPatch(providerId: ProviderId, updateProviderPayload: UpdateProviderPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProviderProvidersProviderIdPatch(providerId, updateProviderPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.updateProviderProvidersProviderIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Verify Provider Client Referral Token
         * @param {string} providerId 
         * @param {VerifyTokenPayload} verifyTokenPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyProviderClientReferralTokenProvidersProviderIdClientReferralsVerifyPost(providerId: string, verifyTokenPayload: VerifyTokenPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyProviderClientReferralTokenProvidersProviderIdClientReferralsVerifyPost(providerId, verifyTokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.verifyProviderClientReferralTokenProvidersProviderIdClientReferralsVerifyPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProviderApi - factory interface
 * @export
 */
export const ProviderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProviderApiFp(configuration)
    return {
        /**
         * 
         * @summary All Onboardable Providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allOnboardableProvidersAdminOnboardableProvidersGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<OnboardableProviderResponse>> {
            return localVarFp.allOnboardableProvidersAdminOnboardableProvidersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Google Calendar Oauth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGoogleCalendarOauthProvidersMeGoogleDelete(options?: RawAxiosRequestConfig): AxiosPromise<OAuthResponse> {
            return localVarFp.deleteGoogleCalendarOauthProvidersMeGoogleDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Google Business Review Link
         * @param {string} providerSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoogleBusinessReviewLinkProvidersProviderSlugGoogleBusinessReviewLinkGet(providerSlug: string, options?: RawAxiosRequestConfig): AxiosPromise<ProviderGoogleBusinessReviewLinkResponse> {
            return localVarFp.getGoogleBusinessReviewLinkProvidersProviderSlugGoogleBusinessReviewLinkGet(providerSlug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Google Calendar Oauth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoogleCalendarOauthProvidersMeGoogleGet(options?: RawAxiosRequestConfig): AxiosPromise<OAuthResponse> {
            return localVarFp.getGoogleCalendarOauthProvidersMeGoogleGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of available slots for the given provider between the given start and end times. The slots returned are the start times of the available slots in 15 minute increments.
         * @summary Get Provider Available Slots
         * @param {string} providerId 
         * @param {string} start 
         * @param {string} end 
         * @param {string | null} [pcrit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderAvailableSlotsProvidersProviderIdAvailabilitySlotsGet(providerId: string, start: string, end: string, pcrit?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<AvailabilitySlotsResponse> {
            return localVarFp.getProviderAvailableSlotsProvidersProviderIdAvailabilitySlotsGet(providerId, start, end, pcrit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Provider Client Referrals
         * @param {ProviderId} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderClientReferralsProvidersProviderIdClientReferralsGet(providerId: ProviderId, options?: RawAxiosRequestConfig): AxiosPromise<ClientReferralResponse> {
            return localVarFp.getProviderClientReferralsProvidersProviderIdClientReferralsGet(providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Provider Confirmation Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderConfirmationStatusProviderConfirmGet(options?: RawAxiosRequestConfig): AxiosPromise<ProviderConfirmationPreflightResponse> {
            return localVarFp.getProviderConfirmationStatusProviderConfirmGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Provider Details Basic
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderDetailsBasicProvidersProviderIdBasicGet(providerId: string, options?: RawAxiosRequestConfig): AxiosPromise<ProviderDetailsBasicResponse> {
            return localVarFp.getProviderDetailsBasicProvidersProviderIdBasicGet(providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Provider Details
         * @param {ProviderId} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderDetailsProvidersProviderIdGet(providerId: ProviderId, options?: RawAxiosRequestConfig): AxiosPromise<ProviderDetailsResponse> {
            return localVarFp.getProviderDetailsProvidersProviderIdGet(providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Provider Identity
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderIdentityProvidersProviderIdIdentityGet(providerId: string, options?: RawAxiosRequestConfig): AxiosPromise<ProviderIdentityDetailsResponse> {
            return localVarFp.getProviderIdentityProvidersProviderIdIdentityGet(providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Provider Practice Metrics
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderPracticeMetricsProvidersProviderIdPracticeMetricsGet(providerId: string, options?: RawAxiosRequestConfig): AxiosPromise<PracticeMetricsResponse> {
            return localVarFp.getProviderPracticeMetricsProvidersProviderIdPracticeMetricsGet(providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Provider Rates
         * @param {ProviderId} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderRatesProvidersProviderIdRatesGet(providerId: ProviderId, options?: RawAxiosRequestConfig): AxiosPromise<ProviderRatesResponse> {
            return localVarFp.getProviderRatesProvidersProviderIdRatesGet(providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Provider Reputation
         * @param {ProviderId} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderReputationProvidersProviderIdReputationGet(providerId: ProviderId, options?: RawAxiosRequestConfig): AxiosPromise<ProviderReputationResponse> {
            return localVarFp.getProviderReputationProvidersProviderIdReputationGet(providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Provider Review Link
         * @param {string} providerId 
         * @param {string | null} [appointmentId] 
         * @param {number | null} [rating] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderReviewLinkProvidersProviderIdReviewGet(providerId: string, appointmentId?: string | null, rating?: number | null, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getProviderReviewLinkProvidersProviderIdReviewGet(providerId, appointmentId, rating, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Providers Admin
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string | null} [searchQuery] Search for providers by name or email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvidersAdminAdminProvidersGet(limit?: number, offset?: number, searchQuery?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminProviderDetailsResponse>> {
            return localVarFp.getProvidersAdminAdminProvidersGet(limit, offset, searchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Providers For Patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvidersForPatientProvidersGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<ProviderForPatientDetailsResponse>> {
            return localVarFp.getProvidersForPatientProvidersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Offboard Provider
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offboardProviderAdminProvidersProviderIdOffboardPost(providerId: string, options?: RawAxiosRequestConfig): AxiosPromise<OffboardStatusResponse> {
            return localVarFp.offboardProviderAdminProvidersProviderIdOffboardPost(providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provider Confirmation
         * @param {ProviderConfirmationPayload} providerConfirmationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerConfirmationProviderConfirmPost(providerConfirmationPayload: ProviderConfirmationPayload, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.providerConfirmationProviderConfirmPost(providerConfirmationPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * We should really be careful with this endpoint as it can be used indiscriminately. If an attacker wants to mess with our platform, they could overwhelm our database with fake impressions. We should consider rate limiting this endpoint with a platform-wide rate limiter, probably backed by Redis.
         * @summary Record Client Referral Impression
         * @param {ClientReferralImpressionPayload} clientReferralImpressionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordClientReferralImpressionProvidersClientReferralsImpressionPost(clientReferralImpressionPayload: ClientReferralImpressionPayload, options?: RawAxiosRequestConfig): AxiosPromise<ClientReferralImpressionResponse> {
            return localVarFp.recordClientReferralImpressionProvidersClientReferralsImpressionPost(clientReferralImpressionPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register Provider
         * @param {RegisterProviderPayload} registerProviderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerProviderProvidersPost(registerProviderPayload: RegisterProviderPayload, options?: RawAxiosRequestConfig): AxiosPromise<ProviderDetailsResponse> {
            return localVarFp.registerProviderProvidersPost(registerProviderPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset Provider Password
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetProviderPasswordProvidersProviderIdPasswordResetPost(providerId: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.resetProviderPasswordProvidersProviderIdPasswordResetPost(providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send Provider Client Referral Sms
         * @param {ProviderId} providerId 
         * @param {SendClientReferralSMSPayload} sendClientReferralSMSPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendProviderClientReferralSmsProvidersProviderIdClientReferralsSmsPost(providerId: ProviderId, sendClientReferralSMSPayload: SendClientReferralSMSPayload, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.sendProviderClientReferralSmsProvidersProviderIdClientReferralsSmsPost(providerId, sendClientReferralSMSPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Google Calendar Oauth
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGoogleCalendarOauthProvidersMeGooglePost(code: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.setGoogleCalendarOauthProvidersMeGooglePost(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Solicit Provider Review
         * @param {string} providerId 
         * @param {SolicitProviderReviewPayload} solicitProviderReviewPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solicitProviderReviewProvidersProviderIdReviewSolicitationPost(providerId: string, solicitProviderReviewPayload: SolicitProviderReviewPayload, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.solicitProviderReviewProvidersProviderIdReviewSolicitationPost(providerId, solicitProviderReviewPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Provider Feedback
         * @param {string} providerId 
         * @param {string} reviewId 
         * @param {ProviderFeedbackPayload} providerFeedbackPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProviderFeedbackProvidersProviderIdReviewReviewIdPut(providerId: string, reviewId: string, providerFeedbackPayload: ProviderFeedbackPayload, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.updateProviderFeedbackProvidersProviderIdReviewReviewIdPut(providerId, reviewId, providerFeedbackPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Provider
         * @param {ProviderId} providerId 
         * @param {UpdateProviderPayload} updateProviderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProviderProvidersProviderIdPatch(providerId: ProviderId, updateProviderPayload: UpdateProviderPayload, options?: RawAxiosRequestConfig): AxiosPromise<ProviderDetailsResponse> {
            return localVarFp.updateProviderProvidersProviderIdPatch(providerId, updateProviderPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify Provider Client Referral Token
         * @param {string} providerId 
         * @param {VerifyTokenPayload} verifyTokenPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyProviderClientReferralTokenProvidersProviderIdClientReferralsVerifyPost(providerId: string, verifyTokenPayload: VerifyTokenPayload, options?: RawAxiosRequestConfig): AxiosPromise<VerifyTokenResponse> {
            return localVarFp.verifyProviderClientReferralTokenProvidersProviderIdClientReferralsVerifyPost(providerId, verifyTokenPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProviderApi - object-oriented interface
 * @export
 * @class ProviderApi
 * @extends {BaseAPI}
 */
export class ProviderApi extends BaseAPI {
    /**
     * 
     * @summary All Onboardable Providers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public allOnboardableProvidersAdminOnboardableProvidersGet(options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).allOnboardableProvidersAdminOnboardableProvidersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Google Calendar Oauth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public deleteGoogleCalendarOauthProvidersMeGoogleDelete(options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).deleteGoogleCalendarOauthProvidersMeGoogleDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Google Business Review Link
     * @param {string} providerSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getGoogleBusinessReviewLinkProvidersProviderSlugGoogleBusinessReviewLinkGet(providerSlug: string, options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getGoogleBusinessReviewLinkProvidersProviderSlugGoogleBusinessReviewLinkGet(providerSlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Google Calendar Oauth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getGoogleCalendarOauthProvidersMeGoogleGet(options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getGoogleCalendarOauthProvidersMeGoogleGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of available slots for the given provider between the given start and end times. The slots returned are the start times of the available slots in 15 minute increments.
     * @summary Get Provider Available Slots
     * @param {string} providerId 
     * @param {string} start 
     * @param {string} end 
     * @param {string | null} [pcrit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProviderAvailableSlotsProvidersProviderIdAvailabilitySlotsGet(providerId: string, start: string, end: string, pcrit?: string | null, options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProviderAvailableSlotsProvidersProviderIdAvailabilitySlotsGet(providerId, start, end, pcrit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Provider Client Referrals
     * @param {ProviderId} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProviderClientReferralsProvidersProviderIdClientReferralsGet(providerId: ProviderId, options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProviderClientReferralsProvidersProviderIdClientReferralsGet(providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Provider Confirmation Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProviderConfirmationStatusProviderConfirmGet(options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProviderConfirmationStatusProviderConfirmGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Provider Details Basic
     * @param {string} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProviderDetailsBasicProvidersProviderIdBasicGet(providerId: string, options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProviderDetailsBasicProvidersProviderIdBasicGet(providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Provider Details
     * @param {ProviderId} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProviderDetailsProvidersProviderIdGet(providerId: ProviderId, options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProviderDetailsProvidersProviderIdGet(providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Provider Identity
     * @param {string} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProviderIdentityProvidersProviderIdIdentityGet(providerId: string, options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProviderIdentityProvidersProviderIdIdentityGet(providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Provider Practice Metrics
     * @param {string} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProviderPracticeMetricsProvidersProviderIdPracticeMetricsGet(providerId: string, options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProviderPracticeMetricsProvidersProviderIdPracticeMetricsGet(providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Provider Rates
     * @param {ProviderId} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProviderRatesProvidersProviderIdRatesGet(providerId: ProviderId, options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProviderRatesProvidersProviderIdRatesGet(providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Provider Reputation
     * @param {ProviderId} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProviderReputationProvidersProviderIdReputationGet(providerId: ProviderId, options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProviderReputationProvidersProviderIdReputationGet(providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Provider Review Link
     * @param {string} providerId 
     * @param {string | null} [appointmentId] 
     * @param {number | null} [rating] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProviderReviewLinkProvidersProviderIdReviewGet(providerId: string, appointmentId?: string | null, rating?: number | null, options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProviderReviewLinkProvidersProviderIdReviewGet(providerId, appointmentId, rating, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Providers Admin
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {string | null} [searchQuery] Search for providers by name or email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProvidersAdminAdminProvidersGet(limit?: number, offset?: number, searchQuery?: string | null, options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProvidersAdminAdminProvidersGet(limit, offset, searchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Providers For Patient
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProvidersForPatientProvidersGet(options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProvidersForPatientProvidersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Offboard Provider
     * @param {string} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public offboardProviderAdminProvidersProviderIdOffboardPost(providerId: string, options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).offboardProviderAdminProvidersProviderIdOffboardPost(providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Provider Confirmation
     * @param {ProviderConfirmationPayload} providerConfirmationPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerConfirmationProviderConfirmPost(providerConfirmationPayload: ProviderConfirmationPayload, options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerConfirmationProviderConfirmPost(providerConfirmationPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * We should really be careful with this endpoint as it can be used indiscriminately. If an attacker wants to mess with our platform, they could overwhelm our database with fake impressions. We should consider rate limiting this endpoint with a platform-wide rate limiter, probably backed by Redis.
     * @summary Record Client Referral Impression
     * @param {ClientReferralImpressionPayload} clientReferralImpressionPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public recordClientReferralImpressionProvidersClientReferralsImpressionPost(clientReferralImpressionPayload: ClientReferralImpressionPayload, options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).recordClientReferralImpressionProvidersClientReferralsImpressionPost(clientReferralImpressionPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register Provider
     * @param {RegisterProviderPayload} registerProviderPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public registerProviderProvidersPost(registerProviderPayload: RegisterProviderPayload, options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).registerProviderProvidersPost(registerProviderPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset Provider Password
     * @param {string} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public resetProviderPasswordProvidersProviderIdPasswordResetPost(providerId: string, options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).resetProviderPasswordProvidersProviderIdPasswordResetPost(providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send Provider Client Referral Sms
     * @param {ProviderId} providerId 
     * @param {SendClientReferralSMSPayload} sendClientReferralSMSPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public sendProviderClientReferralSmsProvidersProviderIdClientReferralsSmsPost(providerId: ProviderId, sendClientReferralSMSPayload: SendClientReferralSMSPayload, options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).sendProviderClientReferralSmsProvidersProviderIdClientReferralsSmsPost(providerId, sendClientReferralSMSPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Google Calendar Oauth
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public setGoogleCalendarOauthProvidersMeGooglePost(code: string, options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).setGoogleCalendarOauthProvidersMeGooglePost(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Solicit Provider Review
     * @param {string} providerId 
     * @param {SolicitProviderReviewPayload} solicitProviderReviewPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public solicitProviderReviewProvidersProviderIdReviewSolicitationPost(providerId: string, solicitProviderReviewPayload: SolicitProviderReviewPayload, options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).solicitProviderReviewProvidersProviderIdReviewSolicitationPost(providerId, solicitProviderReviewPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Provider Feedback
     * @param {string} providerId 
     * @param {string} reviewId 
     * @param {ProviderFeedbackPayload} providerFeedbackPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public updateProviderFeedbackProvidersProviderIdReviewReviewIdPut(providerId: string, reviewId: string, providerFeedbackPayload: ProviderFeedbackPayload, options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).updateProviderFeedbackProvidersProviderIdReviewReviewIdPut(providerId, reviewId, providerFeedbackPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Provider
     * @param {ProviderId} providerId 
     * @param {UpdateProviderPayload} updateProviderPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public updateProviderProvidersProviderIdPatch(providerId: ProviderId, updateProviderPayload: UpdateProviderPayload, options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).updateProviderProvidersProviderIdPatch(providerId, updateProviderPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify Provider Client Referral Token
     * @param {string} providerId 
     * @param {VerifyTokenPayload} verifyTokenPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public verifyProviderClientReferralTokenProvidersProviderIdClientReferralsVerifyPost(providerId: string, verifyTokenPayload: VerifyTokenPayload, options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).verifyProviderClientReferralTokenProvidersProviderIdClientReferralsVerifyPost(providerId, verifyTokenPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StreakApi - axios parameter creator
 * @export
 */
export const StreakApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Claim Reward
         * @param {string} rewardId 
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimRewardPatientsPatientIdRewardsRewardIdClaimPost: async (rewardId: string, patientId: PatientId, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rewardId' is not null or undefined
            assertParamExists('claimRewardPatientsPatientIdRewardsRewardIdClaimPost', 'rewardId', rewardId)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('claimRewardPatientsPatientIdRewardsRewardIdClaimPost', 'patientId', patientId)
            const localVarPath = `/patients/{patient_id}/rewards/{reward_id}/claim`
                .replace(`{${"reward_id"}}`, encodeURIComponent(String(rewardId)))
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete Patient Goal
         * @param {string} goalId 
         * @param {PatientId} patientId 
         * @param {CompleteGoalPayload} completeGoalPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePatientGoalPatientsPatientIdGoalsGoalIdCompletePost: async (goalId: string, patientId: PatientId, completeGoalPayload: CompleteGoalPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'goalId' is not null or undefined
            assertParamExists('completePatientGoalPatientsPatientIdGoalsGoalIdCompletePost', 'goalId', goalId)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('completePatientGoalPatientsPatientIdGoalsGoalIdCompletePost', 'patientId', patientId)
            // verify required parameter 'completeGoalPayload' is not null or undefined
            assertParamExists('completePatientGoalPatientsPatientIdGoalsGoalIdCompletePost', 'completeGoalPayload', completeGoalPayload)
            const localVarPath = `/patients/{patient_id}/goals/{goal_id}/complete`
                .replace(`{${"goal_id"}}`, encodeURIComponent(String(goalId)))
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeGoalPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Goal
         * @param {PatientId} patientId 
         * @param {CreateGoalPayload} createGoalPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGoalPatientsPatientIdGoalsPost: async (patientId: PatientId, createGoalPayload: CreateGoalPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('createGoalPatientsPatientIdGoalsPost', 'patientId', patientId)
            // verify required parameter 'createGoalPayload' is not null or undefined
            assertParamExists('createGoalPatientsPatientIdGoalsPost', 'createGoalPayload', createGoalPayload)
            const localVarPath = `/patients/{patient_id}/goals`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGoalPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Patient Goal
         * @param {string} goalId 
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePatientGoalPatientsPatientIdGoalsGoalIdDelete: async (goalId: string, patientId: PatientId, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'goalId' is not null or undefined
            assertParamExists('deletePatientGoalPatientsPatientIdGoalsGoalIdDelete', 'goalId', goalId)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('deletePatientGoalPatientsPatientIdGoalsGoalIdDelete', 'patientId', patientId)
            const localVarPath = `/patients/{patient_id}/goals/{goal_id}`
                .replace(`{${"goal_id"}}`, encodeURIComponent(String(goalId)))
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Patient Goals
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientGoalsPatientsPatientIdGoalsGet: async (patientId: PatientId, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientGoalsPatientsPatientIdGoalsGet', 'patientId', patientId)
            const localVarPath = `/patients/{patient_id}/goals`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Patient Rewards
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientRewardsPatientsPatientIdRewardsGet: async (patientId: PatientId, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientRewardsPatientsPatientIdRewardsGet', 'patientId', patientId)
            const localVarPath = `/patients/{patient_id}/rewards`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Streaks
         * @param {string | null} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStreaksStreaksGet: async (timezone?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/streaks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get sweepstakes expiration date.
         * @summary Get Sweepstakes Expiration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSweepstakesExpirationSweepstakesExpirationGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sweepstakes-expiration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post App Open
         * @param {string | null} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppOpenStreaksAppOpenPost: async (timezone?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/streaks/app-open`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Patient Goal
         * @param {string} goalId 
         * @param {PatientId} patientId 
         * @param {UpdateGoalPayload} updateGoalPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientGoalPatientsPatientIdGoalsGoalIdPut: async (goalId: string, patientId: PatientId, updateGoalPayload: UpdateGoalPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'goalId' is not null or undefined
            assertParamExists('updatePatientGoalPatientsPatientIdGoalsGoalIdPut', 'goalId', goalId)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('updatePatientGoalPatientsPatientIdGoalsGoalIdPut', 'patientId', patientId)
            // verify required parameter 'updateGoalPayload' is not null or undefined
            assertParamExists('updatePatientGoalPatientsPatientIdGoalsGoalIdPut', 'updateGoalPayload', updateGoalPayload)
            const localVarPath = `/patients/{patient_id}/goals/{goal_id}`
                .replace(`{${"goal_id"}}`, encodeURIComponent(String(goalId)))
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGoalPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StreakApi - functional programming interface
 * @export
 */
export const StreakApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StreakApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Claim Reward
         * @param {string} rewardId 
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimRewardPatientsPatientIdRewardsRewardIdClaimPost(rewardId: string, patientId: PatientId, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimRewardPatientsPatientIdRewardsRewardIdClaimPost(rewardId, patientId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StreakApi.claimRewardPatientsPatientIdRewardsRewardIdClaimPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Complete Patient Goal
         * @param {string} goalId 
         * @param {PatientId} patientId 
         * @param {CompleteGoalPayload} completeGoalPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completePatientGoalPatientsPatientIdGoalsGoalIdCompletePost(goalId: string, patientId: PatientId, completeGoalPayload: CompleteGoalPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompletePatientGoalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completePatientGoalPatientsPatientIdGoalsGoalIdCompletePost(goalId, patientId, completeGoalPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StreakApi.completePatientGoalPatientsPatientIdGoalsGoalIdCompletePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Goal
         * @param {PatientId} patientId 
         * @param {CreateGoalPayload} createGoalPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGoalPatientsPatientIdGoalsPost(patientId: PatientId, createGoalPayload: CreateGoalPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientGoalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGoalPatientsPatientIdGoalsPost(patientId, createGoalPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StreakApi.createGoalPatientsPatientIdGoalsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Patient Goal
         * @param {string} goalId 
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePatientGoalPatientsPatientIdGoalsGoalIdDelete(goalId: string, patientId: PatientId, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePatientGoalPatientsPatientIdGoalsGoalIdDelete(goalId, patientId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StreakApi.deletePatientGoalPatientsPatientIdGoalsGoalIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Patient Goals
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientGoalsPatientsPatientIdGoalsGet(patientId: PatientId, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientGoalsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientGoalsPatientsPatientIdGoalsGet(patientId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StreakApi.getPatientGoalsPatientsPatientIdGoalsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Patient Rewards
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientRewardsPatientsPatientIdRewardsGet(patientId: PatientId, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientRewardsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientRewardsPatientsPatientIdRewardsGet(patientId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StreakApi.getPatientRewardsPatientsPatientIdRewardsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Streaks
         * @param {string | null} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStreaksStreaksGet(timezone?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StreakSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStreaksStreaksGet(timezone, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StreakApi.getStreaksStreaksGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get sweepstakes expiration date.
         * @summary Get Sweepstakes Expiration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSweepstakesExpirationSweepstakesExpirationGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SweepstakesExpirationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSweepstakesExpirationSweepstakesExpirationGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StreakApi.getSweepstakesExpirationSweepstakesExpirationGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Post App Open
         * @param {string | null} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAppOpenStreaksAppOpenPost(timezone?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpenAppResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAppOpenStreaksAppOpenPost(timezone, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StreakApi.postAppOpenStreaksAppOpenPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Patient Goal
         * @param {string} goalId 
         * @param {PatientId} patientId 
         * @param {UpdateGoalPayload} updateGoalPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePatientGoalPatientsPatientIdGoalsGoalIdPut(goalId: string, patientId: PatientId, updateGoalPayload: UpdateGoalPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientGoalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePatientGoalPatientsPatientIdGoalsGoalIdPut(goalId, patientId, updateGoalPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StreakApi.updatePatientGoalPatientsPatientIdGoalsGoalIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StreakApi - factory interface
 * @export
 */
export const StreakApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StreakApiFp(configuration)
    return {
        /**
         * 
         * @summary Claim Reward
         * @param {string} rewardId 
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimRewardPatientsPatientIdRewardsRewardIdClaimPost(rewardId: string, patientId: PatientId, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.claimRewardPatientsPatientIdRewardsRewardIdClaimPost(rewardId, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete Patient Goal
         * @param {string} goalId 
         * @param {PatientId} patientId 
         * @param {CompleteGoalPayload} completeGoalPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePatientGoalPatientsPatientIdGoalsGoalIdCompletePost(goalId: string, patientId: PatientId, completeGoalPayload: CompleteGoalPayload, options?: RawAxiosRequestConfig): AxiosPromise<CompletePatientGoalResponse> {
            return localVarFp.completePatientGoalPatientsPatientIdGoalsGoalIdCompletePost(goalId, patientId, completeGoalPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Goal
         * @param {PatientId} patientId 
         * @param {CreateGoalPayload} createGoalPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGoalPatientsPatientIdGoalsPost(patientId: PatientId, createGoalPayload: CreateGoalPayload, options?: RawAxiosRequestConfig): AxiosPromise<PatientGoalResponse> {
            return localVarFp.createGoalPatientsPatientIdGoalsPost(patientId, createGoalPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Patient Goal
         * @param {string} goalId 
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePatientGoalPatientsPatientIdGoalsGoalIdDelete(goalId: string, patientId: PatientId, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.deletePatientGoalPatientsPatientIdGoalsGoalIdDelete(goalId, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Patient Goals
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientGoalsPatientsPatientIdGoalsGet(patientId: PatientId, options?: RawAxiosRequestConfig): AxiosPromise<PatientGoalsResponse> {
            return localVarFp.getPatientGoalsPatientsPatientIdGoalsGet(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Patient Rewards
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientRewardsPatientsPatientIdRewardsGet(patientId: PatientId, options?: RawAxiosRequestConfig): AxiosPromise<PatientRewardsResponse> {
            return localVarFp.getPatientRewardsPatientsPatientIdRewardsGet(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Streaks
         * @param {string | null} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStreaksStreaksGet(timezone?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<StreakSummaryResponse> {
            return localVarFp.getStreaksStreaksGet(timezone, options).then((request) => request(axios, basePath));
        },
        /**
         * Get sweepstakes expiration date.
         * @summary Get Sweepstakes Expiration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSweepstakesExpirationSweepstakesExpirationGet(options?: RawAxiosRequestConfig): AxiosPromise<SweepstakesExpirationResponse> {
            return localVarFp.getSweepstakesExpirationSweepstakesExpirationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post App Open
         * @param {string | null} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppOpenStreaksAppOpenPost(timezone?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<OpenAppResponse> {
            return localVarFp.postAppOpenStreaksAppOpenPost(timezone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Patient Goal
         * @param {string} goalId 
         * @param {PatientId} patientId 
         * @param {UpdateGoalPayload} updateGoalPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientGoalPatientsPatientIdGoalsGoalIdPut(goalId: string, patientId: PatientId, updateGoalPayload: UpdateGoalPayload, options?: RawAxiosRequestConfig): AxiosPromise<PatientGoalResponse> {
            return localVarFp.updatePatientGoalPatientsPatientIdGoalsGoalIdPut(goalId, patientId, updateGoalPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StreakApi - object-oriented interface
 * @export
 * @class StreakApi
 * @extends {BaseAPI}
 */
export class StreakApi extends BaseAPI {
    /**
     * 
     * @summary Claim Reward
     * @param {string} rewardId 
     * @param {PatientId} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreakApi
     */
    public claimRewardPatientsPatientIdRewardsRewardIdClaimPost(rewardId: string, patientId: PatientId, options?: RawAxiosRequestConfig) {
        return StreakApiFp(this.configuration).claimRewardPatientsPatientIdRewardsRewardIdClaimPost(rewardId, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete Patient Goal
     * @param {string} goalId 
     * @param {PatientId} patientId 
     * @param {CompleteGoalPayload} completeGoalPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreakApi
     */
    public completePatientGoalPatientsPatientIdGoalsGoalIdCompletePost(goalId: string, patientId: PatientId, completeGoalPayload: CompleteGoalPayload, options?: RawAxiosRequestConfig) {
        return StreakApiFp(this.configuration).completePatientGoalPatientsPatientIdGoalsGoalIdCompletePost(goalId, patientId, completeGoalPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Goal
     * @param {PatientId} patientId 
     * @param {CreateGoalPayload} createGoalPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreakApi
     */
    public createGoalPatientsPatientIdGoalsPost(patientId: PatientId, createGoalPayload: CreateGoalPayload, options?: RawAxiosRequestConfig) {
        return StreakApiFp(this.configuration).createGoalPatientsPatientIdGoalsPost(patientId, createGoalPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Patient Goal
     * @param {string} goalId 
     * @param {PatientId} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreakApi
     */
    public deletePatientGoalPatientsPatientIdGoalsGoalIdDelete(goalId: string, patientId: PatientId, options?: RawAxiosRequestConfig) {
        return StreakApiFp(this.configuration).deletePatientGoalPatientsPatientIdGoalsGoalIdDelete(goalId, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Patient Goals
     * @param {PatientId} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreakApi
     */
    public getPatientGoalsPatientsPatientIdGoalsGet(patientId: PatientId, options?: RawAxiosRequestConfig) {
        return StreakApiFp(this.configuration).getPatientGoalsPatientsPatientIdGoalsGet(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Patient Rewards
     * @param {PatientId} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreakApi
     */
    public getPatientRewardsPatientsPatientIdRewardsGet(patientId: PatientId, options?: RawAxiosRequestConfig) {
        return StreakApiFp(this.configuration).getPatientRewardsPatientsPatientIdRewardsGet(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Streaks
     * @param {string | null} [timezone] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreakApi
     */
    public getStreaksStreaksGet(timezone?: string | null, options?: RawAxiosRequestConfig) {
        return StreakApiFp(this.configuration).getStreaksStreaksGet(timezone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get sweepstakes expiration date.
     * @summary Get Sweepstakes Expiration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreakApi
     */
    public getSweepstakesExpirationSweepstakesExpirationGet(options?: RawAxiosRequestConfig) {
        return StreakApiFp(this.configuration).getSweepstakesExpirationSweepstakesExpirationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post App Open
     * @param {string | null} [timezone] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreakApi
     */
    public postAppOpenStreaksAppOpenPost(timezone?: string | null, options?: RawAxiosRequestConfig) {
        return StreakApiFp(this.configuration).postAppOpenStreaksAppOpenPost(timezone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Patient Goal
     * @param {string} goalId 
     * @param {PatientId} patientId 
     * @param {UpdateGoalPayload} updateGoalPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreakApi
     */
    public updatePatientGoalPatientsPatientIdGoalsGoalIdPut(goalId: string, patientId: PatientId, updateGoalPayload: UpdateGoalPayload, options?: RawAxiosRequestConfig) {
        return StreakApiFp(this.configuration).updatePatientGoalPatientsPatientIdGoalsGoalIdPut(goalId, patientId, updateGoalPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VideoApi - axios parameter creator
 * @export
 */
export const VideoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Recording Bot To Meeting
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRecordingBotToMeetingAppointmentsAppointmentIdRecordPost: async (appointmentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('addRecordingBotToMeetingAppointmentsAppointmentIdRecordPost', 'appointmentId', appointmentId)
            const localVarPath = `/appointments/{appointment_id}/record`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Appointment Video Call
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentVideoCallAppointmentsAppointmentIdVideoCallGet: async (appointmentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('getAppointmentVideoCallAppointmentsAppointmentIdVideoCallGet', 'appointmentId', appointmentId)
            const localVarPath = `/appointments/{appointment_id}/video-call`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VideoApi - functional programming interface
 * @export
 */
export const VideoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VideoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Recording Bot To Meeting
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRecordingBotToMeetingAppointmentsAppointmentIdRecordPost(appointmentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRecordingBotToMeetingAppointmentsAppointmentIdRecordPost(appointmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VideoApi.addRecordingBotToMeetingAppointmentsAppointmentIdRecordPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Appointment Video Call
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentVideoCallAppointmentsAppointmentIdVideoCallGet(appointmentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentVideoCallResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentVideoCallAppointmentsAppointmentIdVideoCallGet(appointmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VideoApi.getAppointmentVideoCallAppointmentsAppointmentIdVideoCallGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VideoApi - factory interface
 * @export
 */
export const VideoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VideoApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Recording Bot To Meeting
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRecordingBotToMeetingAppointmentsAppointmentIdRecordPost(appointmentId: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.addRecordingBotToMeetingAppointmentsAppointmentIdRecordPost(appointmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Appointment Video Call
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentVideoCallAppointmentsAppointmentIdVideoCallGet(appointmentId: string, options?: RawAxiosRequestConfig): AxiosPromise<AppointmentVideoCallResponse> {
            return localVarFp.getAppointmentVideoCallAppointmentsAppointmentIdVideoCallGet(appointmentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VideoApi - object-oriented interface
 * @export
 * @class VideoApi
 * @extends {BaseAPI}
 */
export class VideoApi extends BaseAPI {
    /**
     * 
     * @summary Add Recording Bot To Meeting
     * @param {string} appointmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoApi
     */
    public addRecordingBotToMeetingAppointmentsAppointmentIdRecordPost(appointmentId: string, options?: RawAxiosRequestConfig) {
        return VideoApiFp(this.configuration).addRecordingBotToMeetingAppointmentsAppointmentIdRecordPost(appointmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Appointment Video Call
     * @param {string} appointmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoApi
     */
    public getAppointmentVideoCallAppointmentsAppointmentIdVideoCallGet(appointmentId: string, options?: RawAxiosRequestConfig) {
        return VideoApiFp(this.configuration).getAppointmentVideoCallAppointmentsAppointmentIdVideoCallGet(appointmentId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WebflowApi - axios parameter creator
 * @export
 */
export const WebflowApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Webflow Oauth Callback
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webflowOauthCallbackWebflowOauthCallbackGet: async (code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('webflowOauthCallbackWebflowOauthCallbackGet', 'code', code)
            const localVarPath = `/webflow/oauth-callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebflowApi - functional programming interface
 * @export
 */
export const WebflowApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebflowApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Webflow Oauth Callback
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webflowOauthCallbackWebflowOauthCallbackGet(code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webflowOauthCallbackWebflowOauthCallbackGet(code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebflowApi.webflowOauthCallbackWebflowOauthCallbackGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WebflowApi - factory interface
 * @export
 */
export const WebflowApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebflowApiFp(configuration)
    return {
        /**
         * 
         * @summary Webflow Oauth Callback
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webflowOauthCallbackWebflowOauthCallbackGet(code: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.webflowOauthCallbackWebflowOauthCallbackGet(code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebflowApi - object-oriented interface
 * @export
 * @class WebflowApi
 * @extends {BaseAPI}
 */
export class WebflowApi extends BaseAPI {
    /**
     * 
     * @summary Webflow Oauth Callback
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebflowApi
     */
    public webflowOauthCallbackWebflowOauthCallbackGet(code: string, options?: RawAxiosRequestConfig) {
        return WebflowApiFp(this.configuration).webflowOauthCallbackWebflowOauthCallbackGet(code, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ZocdocIntegrationApi - axios parameter creator
 * @export
 */
export const ZocdocIntegrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel Appointment
         * @param {ZocdocAppointmentIdPayload} zocdocAppointmentIdPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAppointmentPartnersZocdocAppointmentsCancelPost: async (zocdocAppointmentIdPayload: ZocdocAppointmentIdPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zocdocAppointmentIdPayload' is not null or undefined
            assertParamExists('cancelAppointmentPartnersZocdocAppointmentsCancelPost', 'zocdocAppointmentIdPayload', zocdocAppointmentIdPayload)
            const localVarPath = `/partners/zocdoc/appointments/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zocdocAppointmentIdPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Appointment
         * @param {ZocdocCreateAppointmentPayload} zocdocCreateAppointmentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppointmentPartnersZocdocAppointmentsPost: async (zocdocCreateAppointmentPayload: ZocdocCreateAppointmentPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zocdocCreateAppointmentPayload' is not null or undefined
            assertParamExists('createAppointmentPartnersZocdocAppointmentsPost', 'zocdocCreateAppointmentPayload', zocdocCreateAppointmentPayload)
            const localVarPath = `/partners/zocdoc/appointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zocdocCreateAppointmentPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Patient
         * @param {ZocdocCreatePatientPayload} zocdocCreatePatientPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPatientPartnersZocdocPatientsPost: async (zocdocCreatePatientPayload: ZocdocCreatePatientPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zocdocCreatePatientPayload' is not null or undefined
            assertParamExists('createPatientPartnersZocdocPatientsPost', 'zocdocCreatePatientPayload', zocdocCreatePatientPayload)
            const localVarPath = `/partners/zocdoc/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zocdocCreatePatientPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Appointment Cancellation Reasons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentCancellationReasonsPartnersZocdocAppointmentCancellationReasonsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners/zocdoc/appointment_cancellation_reasons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Appointment Statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentStatusesPartnersZocdocAppointmentStatusesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners/zocdoc/appointment_statuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Appointments By Ids
         * @param {Array<ZocdocAppointmentIdPayload>} zocdocAppointmentIdPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentsByIdsPartnersZocdocAppointmentsByIdsPost: async (zocdocAppointmentIdPayload: Array<ZocdocAppointmentIdPayload>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zocdocAppointmentIdPayload' is not null or undefined
            assertParamExists('getAppointmentsByIdsPartnersZocdocAppointmentsByIdsPost', 'zocdocAppointmentIdPayload', zocdocAppointmentIdPayload)
            const localVarPath = `/partners/zocdoc/appointments/by_ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zocdocAppointmentIdPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Available Slot Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableSlotTypesPartnersZocdocAvailableSlotTypesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners/zocdoc/available_slot_types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Available Slots By Provider
         * @param {string} providerId 
         * @param {StartDate} startDate 
         * @param {EndDate} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableSlotsByProviderPartnersZocdocAvailableSlotsByProviderGet: async (providerId: string, startDate: StartDate, endDate: EndDate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getAvailableSlotsByProviderPartnersZocdocAvailableSlotsByProviderGet', 'providerId', providerId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getAvailableSlotsByProviderPartnersZocdocAvailableSlotsByProviderGet', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getAvailableSlotsByProviderPartnersZocdocAvailableSlotsByProviderGet', 'endDate', endDate)
            const localVarPath = `/partners/zocdoc/available_slots/by_provider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (providerId !== undefined) {
                localVarQueryParameter['provider_id'] = providerId;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Locations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationsPartnersZocdocLocationsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners/zocdoc/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Provider
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderPartnersZocdocProvidersProviderIdGet: async (providerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getProviderPartnersZocdocProvidersProviderIdGet', 'providerId', providerId)
            const localVarPath = `/partners/zocdoc/providers/{provider_id}`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvidersPartnersZocdocProvidersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners/zocdoc/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Schedulable Resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedulableResourcesPartnersZocdocSchedulableResourcesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners/zocdoc/schedulable_resources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionPartnersZocdocVersionGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners/zocdoc/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Visit Reasons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitReasonsPartnersZocdocVisitReasonsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners/zocdoc/visit_reasons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The Zocdoc patient search is an OR-behavior search by a lot of information, but we only pay attention to email and phone number. We used to only search by email, but with phone number uniqueness [by way of has_phone_authentication_authority], we can now search by phone number as well.  Per Zocdoc\'s team, this search is an OR search, so a patient with a mismatched email but a matched phone number will still be returned, and vice versa. If a patient is found by both email and phone number, it should only appear once in the list, but ranked higher for a higher match.
         * @summary Search Patients
         * @param {ZocdocPatientSearchPayload} zocdocPatientSearchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPatientsPartnersZocdocPatientsSearchPost: async (zocdocPatientSearchPayload: ZocdocPatientSearchPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zocdocPatientSearchPayload' is not null or undefined
            assertParamExists('searchPatientsPartnersZocdocPatientsSearchPost', 'zocdocPatientSearchPayload', zocdocPatientSearchPayload)
            const localVarPath = `/partners/zocdoc/patients/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zocdocPatientSearchPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ZocdocIntegrationApi - functional programming interface
 * @export
 */
export const ZocdocIntegrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ZocdocIntegrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel Appointment
         * @param {ZocdocAppointmentIdPayload} zocdocAppointmentIdPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelAppointmentPartnersZocdocAppointmentsCancelPost(zocdocAppointmentIdPayload: ZocdocAppointmentIdPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZocdocAppointmentCancelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelAppointmentPartnersZocdocAppointmentsCancelPost(zocdocAppointmentIdPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ZocdocIntegrationApi.cancelAppointmentPartnersZocdocAppointmentsCancelPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Appointment
         * @param {ZocdocCreateAppointmentPayload} zocdocCreateAppointmentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAppointmentPartnersZocdocAppointmentsPost(zocdocCreateAppointmentPayload: ZocdocCreateAppointmentPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZocdocCreateAppointmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAppointmentPartnersZocdocAppointmentsPost(zocdocCreateAppointmentPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ZocdocIntegrationApi.createAppointmentPartnersZocdocAppointmentsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Patient
         * @param {ZocdocCreatePatientPayload} zocdocCreatePatientPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPatientPartnersZocdocPatientsPost(zocdocCreatePatientPayload: ZocdocCreatePatientPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZocdocCreatePatientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPatientPartnersZocdocPatientsPost(zocdocCreatePatientPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ZocdocIntegrationApi.createPatientPartnersZocdocPatientsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Appointment Cancellation Reasons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentCancellationReasonsPartnersZocdocAppointmentCancellationReasonsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentCancellationReasonsPartnersZocdocAppointmentCancellationReasonsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ZocdocIntegrationApi.getAppointmentCancellationReasonsPartnersZocdocAppointmentCancellationReasonsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Appointment Statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentStatusesPartnersZocdocAppointmentStatusesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZocdocAppointmentStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentStatusesPartnersZocdocAppointmentStatusesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ZocdocIntegrationApi.getAppointmentStatusesPartnersZocdocAppointmentStatusesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Appointments By Ids
         * @param {Array<ZocdocAppointmentIdPayload>} zocdocAppointmentIdPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentsByIdsPartnersZocdocAppointmentsByIdsPost(zocdocAppointmentIdPayload: Array<ZocdocAppointmentIdPayload>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZocdocAppointment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentsByIdsPartnersZocdocAppointmentsByIdsPost(zocdocAppointmentIdPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ZocdocIntegrationApi.getAppointmentsByIdsPartnersZocdocAppointmentsByIdsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Available Slot Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableSlotTypesPartnersZocdocAvailableSlotTypesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableSlotTypesPartnersZocdocAvailableSlotTypesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ZocdocIntegrationApi.getAvailableSlotTypesPartnersZocdocAvailableSlotTypesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Available Slots By Provider
         * @param {string} providerId 
         * @param {StartDate} startDate 
         * @param {EndDate} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableSlotsByProviderPartnersZocdocAvailableSlotsByProviderGet(providerId: string, startDate: StartDate, endDate: EndDate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZocdocAvailabilitySlot>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableSlotsByProviderPartnersZocdocAvailableSlotsByProviderGet(providerId, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ZocdocIntegrationApi.getAvailableSlotsByProviderPartnersZocdocAvailableSlotsByProviderGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Locations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationsPartnersZocdocLocationsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationsPartnersZocdocLocationsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ZocdocIntegrationApi.getLocationsPartnersZocdocLocationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Provider
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderPartnersZocdocProvidersProviderIdGet(providerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZocdocProvider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderPartnersZocdocProvidersProviderIdGet(providerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ZocdocIntegrationApi.getProviderPartnersZocdocProvidersProviderIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProvidersPartnersZocdocProvidersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZocdocProvider>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProvidersPartnersZocdocProvidersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ZocdocIntegrationApi.getProvidersPartnersZocdocProvidersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Schedulable Resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchedulableResourcesPartnersZocdocSchedulableResourcesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchedulableResourcesPartnersZocdocSchedulableResourcesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ZocdocIntegrationApi.getSchedulableResourcesPartnersZocdocSchedulableResourcesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersionPartnersZocdocVersionGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersionPartnersZocdocVersionGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ZocdocIntegrationApi.getVersionPartnersZocdocVersionGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Visit Reasons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisitReasonsPartnersZocdocVisitReasonsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZocdocVisitReason>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisitReasonsPartnersZocdocVisitReasonsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ZocdocIntegrationApi.getVisitReasonsPartnersZocdocVisitReasonsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The Zocdoc patient search is an OR-behavior search by a lot of information, but we only pay attention to email and phone number. We used to only search by email, but with phone number uniqueness [by way of has_phone_authentication_authority], we can now search by phone number as well.  Per Zocdoc\'s team, this search is an OR search, so a patient with a mismatched email but a matched phone number will still be returned, and vice versa. If a patient is found by both email and phone number, it should only appear once in the list, but ranked higher for a higher match.
         * @summary Search Patients
         * @param {ZocdocPatientSearchPayload} zocdocPatientSearchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPatientsPartnersZocdocPatientsSearchPost(zocdocPatientSearchPayload: ZocdocPatientSearchPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZocdocPatient>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPatientsPartnersZocdocPatientsSearchPost(zocdocPatientSearchPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ZocdocIntegrationApi.searchPatientsPartnersZocdocPatientsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ZocdocIntegrationApi - factory interface
 * @export
 */
export const ZocdocIntegrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ZocdocIntegrationApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel Appointment
         * @param {ZocdocAppointmentIdPayload} zocdocAppointmentIdPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAppointmentPartnersZocdocAppointmentsCancelPost(zocdocAppointmentIdPayload: ZocdocAppointmentIdPayload, options?: RawAxiosRequestConfig): AxiosPromise<ZocdocAppointmentCancelResponse> {
            return localVarFp.cancelAppointmentPartnersZocdocAppointmentsCancelPost(zocdocAppointmentIdPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Appointment
         * @param {ZocdocCreateAppointmentPayload} zocdocCreateAppointmentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppointmentPartnersZocdocAppointmentsPost(zocdocCreateAppointmentPayload: ZocdocCreateAppointmentPayload, options?: RawAxiosRequestConfig): AxiosPromise<ZocdocCreateAppointmentResponse> {
            return localVarFp.createAppointmentPartnersZocdocAppointmentsPost(zocdocCreateAppointmentPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Patient
         * @param {ZocdocCreatePatientPayload} zocdocCreatePatientPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPatientPartnersZocdocPatientsPost(zocdocCreatePatientPayload: ZocdocCreatePatientPayload, options?: RawAxiosRequestConfig): AxiosPromise<ZocdocCreatePatientResponse> {
            return localVarFp.createPatientPartnersZocdocPatientsPost(zocdocCreatePatientPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Appointment Cancellation Reasons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentCancellationReasonsPartnersZocdocAppointmentCancellationReasonsGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<any>> {
            return localVarFp.getAppointmentCancellationReasonsPartnersZocdocAppointmentCancellationReasonsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Appointment Statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentStatusesPartnersZocdocAppointmentStatusesGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<ZocdocAppointmentStatus>> {
            return localVarFp.getAppointmentStatusesPartnersZocdocAppointmentStatusesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Appointments By Ids
         * @param {Array<ZocdocAppointmentIdPayload>} zocdocAppointmentIdPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentsByIdsPartnersZocdocAppointmentsByIdsPost(zocdocAppointmentIdPayload: Array<ZocdocAppointmentIdPayload>, options?: RawAxiosRequestConfig): AxiosPromise<Array<ZocdocAppointment>> {
            return localVarFp.getAppointmentsByIdsPartnersZocdocAppointmentsByIdsPost(zocdocAppointmentIdPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Available Slot Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableSlotTypesPartnersZocdocAvailableSlotTypesGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<any>> {
            return localVarFp.getAvailableSlotTypesPartnersZocdocAvailableSlotTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Available Slots By Provider
         * @param {string} providerId 
         * @param {StartDate} startDate 
         * @param {EndDate} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableSlotsByProviderPartnersZocdocAvailableSlotsByProviderGet(providerId: string, startDate: StartDate, endDate: EndDate, options?: RawAxiosRequestConfig): AxiosPromise<Array<ZocdocAvailabilitySlot>> {
            return localVarFp.getAvailableSlotsByProviderPartnersZocdocAvailableSlotsByProviderGet(providerId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Locations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationsPartnersZocdocLocationsGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getLocationsPartnersZocdocLocationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Provider
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderPartnersZocdocProvidersProviderIdGet(providerId: string, options?: RawAxiosRequestConfig): AxiosPromise<ZocdocProvider> {
            return localVarFp.getProviderPartnersZocdocProvidersProviderIdGet(providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvidersPartnersZocdocProvidersGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<ZocdocProvider>> {
            return localVarFp.getProvidersPartnersZocdocProvidersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Schedulable Resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedulableResourcesPartnersZocdocSchedulableResourcesGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<any>> {
            return localVarFp.getSchedulableResourcesPartnersZocdocSchedulableResourcesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionPartnersZocdocVersionGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getVersionPartnersZocdocVersionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Visit Reasons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitReasonsPartnersZocdocVisitReasonsGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<ZocdocVisitReason>> {
            return localVarFp.getVisitReasonsPartnersZocdocVisitReasonsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * The Zocdoc patient search is an OR-behavior search by a lot of information, but we only pay attention to email and phone number. We used to only search by email, but with phone number uniqueness [by way of has_phone_authentication_authority], we can now search by phone number as well.  Per Zocdoc\'s team, this search is an OR search, so a patient with a mismatched email but a matched phone number will still be returned, and vice versa. If a patient is found by both email and phone number, it should only appear once in the list, but ranked higher for a higher match.
         * @summary Search Patients
         * @param {ZocdocPatientSearchPayload} zocdocPatientSearchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPatientsPartnersZocdocPatientsSearchPost(zocdocPatientSearchPayload: ZocdocPatientSearchPayload, options?: RawAxiosRequestConfig): AxiosPromise<Array<ZocdocPatient>> {
            return localVarFp.searchPatientsPartnersZocdocPatientsSearchPost(zocdocPatientSearchPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ZocdocIntegrationApi - object-oriented interface
 * @export
 * @class ZocdocIntegrationApi
 * @extends {BaseAPI}
 */
export class ZocdocIntegrationApi extends BaseAPI {
    /**
     * 
     * @summary Cancel Appointment
     * @param {ZocdocAppointmentIdPayload} zocdocAppointmentIdPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public cancelAppointmentPartnersZocdocAppointmentsCancelPost(zocdocAppointmentIdPayload: ZocdocAppointmentIdPayload, options?: RawAxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).cancelAppointmentPartnersZocdocAppointmentsCancelPost(zocdocAppointmentIdPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Appointment
     * @param {ZocdocCreateAppointmentPayload} zocdocCreateAppointmentPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public createAppointmentPartnersZocdocAppointmentsPost(zocdocCreateAppointmentPayload: ZocdocCreateAppointmentPayload, options?: RawAxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).createAppointmentPartnersZocdocAppointmentsPost(zocdocCreateAppointmentPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Patient
     * @param {ZocdocCreatePatientPayload} zocdocCreatePatientPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public createPatientPartnersZocdocPatientsPost(zocdocCreatePatientPayload: ZocdocCreatePatientPayload, options?: RawAxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).createPatientPartnersZocdocPatientsPost(zocdocCreatePatientPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Appointment Cancellation Reasons
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public getAppointmentCancellationReasonsPartnersZocdocAppointmentCancellationReasonsGet(options?: RawAxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).getAppointmentCancellationReasonsPartnersZocdocAppointmentCancellationReasonsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Appointment Statuses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public getAppointmentStatusesPartnersZocdocAppointmentStatusesGet(options?: RawAxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).getAppointmentStatusesPartnersZocdocAppointmentStatusesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Appointments By Ids
     * @param {Array<ZocdocAppointmentIdPayload>} zocdocAppointmentIdPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public getAppointmentsByIdsPartnersZocdocAppointmentsByIdsPost(zocdocAppointmentIdPayload: Array<ZocdocAppointmentIdPayload>, options?: RawAxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).getAppointmentsByIdsPartnersZocdocAppointmentsByIdsPost(zocdocAppointmentIdPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Available Slot Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public getAvailableSlotTypesPartnersZocdocAvailableSlotTypesGet(options?: RawAxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).getAvailableSlotTypesPartnersZocdocAvailableSlotTypesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Available Slots By Provider
     * @param {string} providerId 
     * @param {StartDate} startDate 
     * @param {EndDate} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public getAvailableSlotsByProviderPartnersZocdocAvailableSlotsByProviderGet(providerId: string, startDate: StartDate, endDate: EndDate, options?: RawAxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).getAvailableSlotsByProviderPartnersZocdocAvailableSlotsByProviderGet(providerId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Locations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public getLocationsPartnersZocdocLocationsGet(options?: RawAxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).getLocationsPartnersZocdocLocationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Provider
     * @param {string} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public getProviderPartnersZocdocProvidersProviderIdGet(providerId: string, options?: RawAxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).getProviderPartnersZocdocProvidersProviderIdGet(providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Providers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public getProvidersPartnersZocdocProvidersGet(options?: RawAxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).getProvidersPartnersZocdocProvidersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Schedulable Resources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public getSchedulableResourcesPartnersZocdocSchedulableResourcesGet(options?: RawAxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).getSchedulableResourcesPartnersZocdocSchedulableResourcesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public getVersionPartnersZocdocVersionGet(options?: RawAxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).getVersionPartnersZocdocVersionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Visit Reasons
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public getVisitReasonsPartnersZocdocVisitReasonsGet(options?: RawAxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).getVisitReasonsPartnersZocdocVisitReasonsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The Zocdoc patient search is an OR-behavior search by a lot of information, but we only pay attention to email and phone number. We used to only search by email, but with phone number uniqueness [by way of has_phone_authentication_authority], we can now search by phone number as well.  Per Zocdoc\'s team, this search is an OR search, so a patient with a mismatched email but a matched phone number will still be returned, and vice versa. If a patient is found by both email and phone number, it should only appear once in the list, but ranked higher for a higher match.
     * @summary Search Patients
     * @param {ZocdocPatientSearchPayload} zocdocPatientSearchPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public searchPatientsPartnersZocdocPatientsSearchPost(zocdocPatientSearchPayload: ZocdocPatientSearchPayload, options?: RawAxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).searchPatientsPartnersZocdocPatientsSearchPost(zocdocPatientSearchPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



