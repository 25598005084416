import React, { lazy, Suspense } from "react";

import { createRoot } from "react-dom/client";

import * as Sentry from "@sentry/react";

import mixpanel from "mixpanel-browser";

import isSupported from "./supportedBrowsers";
import { FAY_API_URL } from "./fay/configuration";

export * from "core-js/proposals/change-array-by-copy-stage-4";

if (process.env.NODE_ENV !== "development") {
  const tracePropagationTargets = [FAY_API_URL].map((url) => {
    return new RegExp("^" + new URL(url).origin);
  });
  Sentry.init({
    dsn: "https://5c3ac483641c4a64987b14c04d2adadd@o4505071182217216.ingest.sentry.io/4505093357371392",
    environment: process.env.NODE_ENV,
    release: process.env.REACT_APP_FAY_UI_VERSION,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracePropagationTargets,
    // Performance Monitoring
    tracesSampleRate: 0.2,
    // Session Replay
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
  });
}

mixpanel.init("41885de56a1f4debd282ca7d2d6ed2bc");

const root = createRoot(document.getElementById("root"));

const App = lazy(() => {
  if (process.env.REACT_APP_FAY_APP === "provider") {
    return import("./ProviderApp");
  } else if (process.env.REACT_APP_FAY_APP === "client") {
    return import("./ClientApp");
  }

  return Promise.reject(new Error("Invalid app type"));
});

root.render(
  <React.StrictMode>
    <Suspense>
      <App />
    </Suspense>
  </React.StrictMode>,
);

// Check if browser is supported.
// Generated using https://github.com/browserslist/browserslist-useragent-regexp
// Higher versions are allowed.
// To update the list, run
// `npx browserslist-useragent-regexp` and then
// `npm run supportedBrowsers`
if (!isSupported.test(navigator.userAgent)) {
  alert(
    "Critical features of Fay will not work properly because your web browser's version is old. Please upgrade your web browser to the latest version.",
  );

  // Polyfills for people who decide to ignore this warning.

  // eslint-disable-next-line no-extend-native
  Array.prototype.at = function (index) {
    return this[index];
  };

  // eslint-disable-next-line no-extend-native
  Array.prototype.findLast = function (predicate) {
    for (let i = this.length - 1; i >= 0; i--) {
      if (predicate(this[i], i, this)) {
        return this[i];
      }
    }
    return undefined;
  };
}
